/* 1. General CSS
   1.1. Button CSS
   1.2. Title CSS
   1.3. Brand logo CSS */
/* 2. Header CSS */
/* 3. Home slider CSS  */
/* 4. Instagram CSS  */
/* 5. Blog CSS */
/* 6. Service CSS  */
/* 7. About CSS  */
/* 8. Banner Timer CSS  */
/* 9. Category CSS */
/* 10. Product Box CSS */
/* 11. Theme tab CSS */
/* 12. Banner CSS */
/* 13. Collection Banner CSS */
/* 14. Product-box slider CSS */
/* 15. Modal popup CSS */
/* 16. Look-book CSS */
/* 17. Demo css
   17.1. look-book
   17.2. beauty demo
   17.3. electronics
   17.4. pets demo
   17.5. metro demo
   17.6. gym demo
   17.7. extra layout css */
/* 18. Flying products CSS */
/* 19. Footer CSS */
/* 20. Loader CSS*/
/* 21. Tap to top CSS */
/* 22. theme setting css */
/* 23. Inner pages CSS */
/* 26. Menu CSS */
/* 27. Responsive CSS */

/*=====================
    1.General CSS start
==========================*/
body {
	font-family: 'Noto Sans', sans-serif;
	position: relative;
	background: $white;
	font-size: 14px;
	overflow-x: hidden;
	letter-spacing: 0.8px;

	&.christmas {
		font-family: $font_2, $font_1;

		.dark-light {
			display: none;
		}

		section {
			overflow: hidden;
		}
	}
}

h1 {
	font-size: 60px;
	color: $dark-font;
	font-weight: 700;
	text-transform: uppercase;

	span {
		font-size: 107px;
		font-weight: 700;
		color: var(--theme-deafult);
	}
}

h2 {
	font-size: 36px;
	color: $dark-font;
	text-transform: uppercase;
	font-weight: 700;
	line-height: 1;
	letter-spacing: 0.02em;
}

h3 {
	font-size: 24px;
	font-weight: 400;
	color: $grey;
	letter-spacing: 0.03em;
}

h4 {
	font-size: 18px;
	text-transform: capitalize;
	font-weight: 400;
	letter-spacing: 0.03em;
	line-height: 1;
}

h5 {
	font-size: 16px;
	font-weight: 400;
	color: $dark-font;
	line-height: 24px;
	letter-spacing: 0.05em;
}

h6 {
	font-size: 14px;
	font-weight: 400;
	color: $grey;
	line-height: 24px;
}

ul {
	padding-left: 0;
	margin-bottom: 0;
}

li {
	display: inline-block;
}

p {
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	color: $grey;
	line-height: 1;
}

span {
	font-family: 'Inter', sans-serif;
}

a {
	transition: 0.5s ease;

	&:hover {
		text-decoration: none;
		transition: 0.5s ease;
	}

	&:focus {
		outline: none;
	}
}

:focus {
	outline: none;
}

button {
	&:focus {
		outline: none;
	}
}

.white {
	background-color: #fff;
	border: 1px solid #aaa;
}

.blue {
	background-color: #4695b1;
}

.pink {
	background-color: pink;
}

.yellow {
	background-color: #fdf791;
}

.burlywood {
	background-color: #deb887;
}

.coral {
	background-color: #ff7f50;
}

.bisque {
	background-color: #f6d8be;
}

.darkslategrey {
	background-color: #2f4f4f;
}

.chocolate {
	background-color: #7b3f00;
}

.saddlebrown {
	background-color: #8b4513;
}

.darkolivegreen {
	background-color: #556b2f;
}

.antiquewhite {
	background-color: #faebd7;
}

.lawngreen {
	background-color: #7cfc00;
}

.crimson {
	background-color: #dc143c;
}

.hotpink {
	background-color: #ff69b4;
}

.beige {
	background-color: #f5f5dc;
}

.lightpink {
	background-color: #ffb6c1;
}

.cadetblue {
	background-color: #5f9ea0;
}

.gainsboro {
	background-color: #dcdcdc;
}

.darksalmon {
	background-color: #e9967a;
}

.sienna {
	background-color: #a0522d;
}

.purple {
	background-color: #800080;
}

.darkturquoise {
	background-color: #00ced1;
}

.red {
	background-color: #b65d60;
}

.olive {
	background-color: #bab789;
}

.navy {
	background-color: #021840;
}

.gray {
	background-color: #bababa;
}

.green {
	background-color: #7ee768;
}

.thick-green {
	background-color: #20963d;
}

.orange {
	background-color: rgba(230, 184, 71, 0.92);
}

.black {
	background-color: #1b1b1b;
}

.skyblue {
	background-color: #57d7f7;
}

section,
.section-t-space {
	padding-top: 25px;
}

// .modal-dialog {
//   margin-top: 50px !important;
//   margin-bottom:30px !important;
// }
.section-b-space {
	padding-bottom: 25px;
}

.p-t-0 {
	padding-top: 0;
}

.lh-cls {
	line-height: 1.6;
}

hr.style1 {
	width: 75px;
	height: 3px;
	margin-top: 13px;
	background-color: var(--theme-deafult);
	text-align: center;
}

.no-arrow {
	.slick-next,
	.slick-prev {
		display: none !important;
	}
}

.icon-angle-left:before {
	content: '\E64A';
}

.form-control {
	// border-radius: 0;
}

.small-section {
	padding-top: 35px;
	padding-bottom: 35px;
}

.banner-padding {
	padding-top: 30px;
}

.border-section {
	border-top: 1px solid lighten($black, 86.5%);
	border-bottom: 1px solid lighten($black, 86.5%);
}

.border-b {
	border-bottom: 1px solid $border-color;
}

.border-bottom-grey {
	border-bottom: 1px solid $grey3;
}

.border-top-grey {
	border-top: 1px solid $grey3;
}

.darken-layout {
	background-color: $grey-darken;
}

.dark-layout {
	background-color: $grey-dark;
}

.light-layout {
	background-color: $grey-lighter;
}

.white-layout {
	background-color: $white;
}

.bg-light0 {
	background-color: $light-grey;
}

.bg-light1 {
	background-color: $pink;
}

.bg-light2 {
	background-color: $blue;
}

.bg-blog {
	background-color: $white-dark;
}

.bg-grey {
	background-color: $grey-about;
}

.bg_cls {
	background-color: #fafafa;
}

.Toastify__toast {
	min-height: 36px;
}

i.follow {
	font-size: 24px;
	color: grey;
}

i.unfollow {
	font-size: 24px;
	color: blue;
}

.np {
	padding: 0;
}
.input-range__track--active {
	background: var(--theme-deafult) !important;
}

.input-range__slider {
	background: var(--theme-deafult) !important;
	border: 1px solid var(--theme-deafult) !important;
}

.block-price-content {
	.custom-control {
		padding-right: 1.5rem;
		padding-bottom: 18px;
	}
}

del {
	font-size: 14px;
	color: $font-grey;
	font-weight: 400;
}

.sidenav .sidebar-back {
	display: flex;
	align-items: center;
}

.srv-validation-message {
	color: red;
}

.sticky {
	&.fixed {
		position: fixed;
		width: 100%;
		top: 0;
		z-index: 999;
		transition: all 300ms ease;
		background: #fff;
		box-shadow: 0px 0px 8px #ddd;
		animation: smoothScroll 1s forwards;

		.brand-logo {
			padding-top: 15px;
			padding-bottom: 15px;
		}

		.onhover-div {
			.show-div {
				top: 66px;
			}
		}

		.top-header {
			display: none;
		}

		.cart-qty-cls {
			top: 13%;
			right: -11px;
		}
	}
}

@-webkit-keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}

	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

@keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-40px);
		transform: translateY(-40px);
	}

	100% {
		-webkit-transform: translateY(0px);
		transform: translateY(0px);
	}
}

.shopping-cart {
	&::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: #ddd;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: var(--theme-deafult);
		border-radius: 10px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

[data-notify='progressbar'] {
	margin-bottom: 0;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 5px;
}

.progress-bar {
	background-color: #19a340;
}

.progress-bar-info {
	background-color: #00829a;
}

// image ratio //
.ratio_40 {
	.bg-size {
		&:before {
			padding-top: 40%;
			content: '';
			display: block;
		}
	}
}

.ratio_45 {
	.bg-size {
		&:before {
			padding-top: 45%;
			content: '';
			display: block;
		}
	}
}

.ratio2_1 {
	.bg-size {
		&:before {
			padding-top: 50%;
			content: '';
			display: block;
		}
	}
}

.ratio2_3 {
	.bg-size {
		&:before {
			padding-top: 60%;
			content: '';
			display: block;
		}
	}
}

.ratio3_2 {
	.bg-size {
		&:before {
			padding-top: 66.66%;
			content: '';
			display: block;
		}
	}
}

.ratio_landscape {
	.bg-size {
		&:before {
			padding-top: 75%;
			content: '';
			display: block;
		}
	}
}

.ratio_square {
	.bg-size {
		&:before {
			padding-top: 100%;
			content: '';
			display: block;
		}
	}
}

.ratio_asos {
	.bg-size {
		&:before {
			padding-top: 127.7777778%;
			content: '';
			display: block;
		}
	}
}

.ratio_portrait {
	.bg-size {
		&:before {
			padding-top: 150%;
			content: '';
			display: block;
		}
	}
}

.ratio1_2 {
	.bg-size {
		&:before {
			padding-top: 200%;
			content: '';
			display: block;
		}
	}
}

.b-top {
	background-position: top !important;
}

.b-bottom {
	background-position: bottom !important;
}

.b-center {
	background-position: center !important;
}

.b_size_content {
	background-size: contain !important;
	background-repeat: no-repeat;
}

/*=====================
    1.1.Button CSS start
==========================*/
button {
	cursor: pointer;
}

.btn {
	color: white;
	line-height: 20px;
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 700;
	border-radius: 5;
	transition: 0.3s ease-in-out;

	&:hover {
		transition: 0.3s ease-in-out;
	}

	&:focus {
		box-shadow: none;
	}
}

.btn-md {
	width: 60px;
	font-size: 16px;
}
.btn-solid-outline {
	background-color: #fff;
	color: #20963d;
	&:hover {
		color: #20963d;
	}
}
.btn-solid-outline.btn-sm {
	padding: 9px 16px;
}
.btn-solid {
	padding: 13px 29px;
	color: $white !important;
	letter-spacing: 0.05em;
	border: 2px solid var(--theme-deafult);
	// background-image: -webkit-linear-gradient(    30deg,    var(--theme-deafult) 50%,    transparent 50%  );
	// background-image: linear-gradient(    30deg,    var(--theme-deafult) 50%,    transparent 50%  );
	background-color: var(--theme-deafult);
	// background-size: 540px;
	background-repeat: no-repeat;
	// background-position: 0;
	-webkit-transition: background 100ms ease-in-out;
	transition: background 100ms ease-in-out;
	&:hover {
		// background-position: 100%;
		color: $black;
		background-color: $white;
	}

	&.black-btn {
		background-image: -webkit-linear-gradient(30deg, $dark-font 50%, transparent 50%);
		background-image: linear-gradient(30deg, $dark-font 50%, transparent 50%);
		border: 2px solid $dark-font;
	}

	&:focus {
		color: $white;
	}

	&.btn-gradient {
		background: var(--theme-deafult);
		background: linear-gradient(180deg, var(--gradient1) 0%, var(--gradient2) 99%);
		transition: background 300ms ease-in-out;
		background-size: 300% 100%;
		border: none;

		&:hover {
			background: linear-gradient(-180deg, var(--gradient2) 0%, var(--gradient1) 99%);
			transition: background 300ms ease-in-out;
			color: white;
		}
	}

	&.btn-green {
		background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));
		border: none;
		background-color: var(--theme-deafult);

		&:hover {
			background-color: var(--theme-deafult);
			background-image: none;
			color: white;
		}
	}

	&.btn-sm {
		padding: 9px 16px;
	}

	&.btn-xs {
		padding: 5px 8px;
		text-transform: capitalize;
	}
}

.btn-outline {
	display: inline-block;
	padding: 13px 29px;
	letter-spacing: 0.05em;
	border: 2px solid var(--theme-deafult);
	position: relative;
	color: $black;

	&:before {
		-webkit-transition: 0.5s all ease;
		transition: 0.5s all ease;
		position: absolute;
		top: 0;
		left: 50%;
		right: 50%;
		bottom: 0;
		opacity: 0;
		content: '';
		background-color: var(--theme-deafult);
		z-index: -2;
	}

	&:hover {
		color: $white;
		background-color: var(--theme-deafult);

		&:before {
			-webkit-transition: 0.5s all ease;
			transition: 0.5s all ease;
			left: 0;
			right: 0;
			opacity: 1;
		}
	}

	&:focus {
		&:before {
			transition: 0.5s all ease;
			left: 0;
			right: 0;
			opacity: 1;
		}
	}
}

button {
	&.btn {
		&.btn-solid,
		&.btn-outline {
			&:active {
				background-image: -webkit-linear-gradient(30deg, var(--theme-deafult) 50%, transparent 50%);
				background-image: linear-gradient(30deg, var(--theme-deafult) 50%, transparent 50%);
				color: $white;
				background: var(--theme-deafult);
			}
		}
	}
}

.btn-classic {
	&:hover {
		background-color: var(--theme-deafult);
	}
}

.btn-white {
	background-color: white;
	color: var(--theme-deafult);
	transition: all 0.5s ease;
	padding: 10px 29px;
	&:hover {
		color: $black;
		transition: all 0.5s ease;
	}
}

.btn-theme {
	background-color: var(--theme-deafult);
	color: $white;
}

.btn-custom {
	padding: 6px 30px !important;
	border: none;
}

/*=====================
    1.2.Title CSS start
    ==========================*/
.title1 {
	text-align: center;

	h4 {
		color: var(--theme-deafult);
		padding-bottom: 10px;
		margin-bottom: 0;
		margin-top: -3px;
	}

	.title-inner1 {
		padding-bottom: 15px;
		margin-bottom: 30px;
		position: relative;

		&:after {
			position: absolute;
			height: 5px;
			width: 70px;
			background-color: var(--theme-deafult);
			content: '';
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 0;
		}
	}

	&.title5 {
		.title-inner1 {
			padding-bottom: 0;
			margin-bottom: 0;

			&:after {
				display: none;
			}
		}

		hr[role='tournament6'] {
			border: 0px solid;
			height: 1px;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--theme-deafult), rgba(0, 0, 0, 0));
			display: block;
			width: 25%;
			margin: 15px auto 30px auto;
		}

		hr[role='tournament6']::before {
			position: absolute;
			background-color: #efefef;
			border: 1px solid;
			border-color: var(--theme-deafult);
			padding: 5px;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			left: 50%;
			margin: -6px 0px 0px 0;
			content: '';
		}
	}
}

.title2 {
	text-align: center;

	h4 {
		color: var(--theme-deafult);
		padding-bottom: 10px;
		margin-bottom: 0;
		text-transform: uppercase;
		margin-top: -3px;
	}

	.title-inner2 {
		margin-bottom: 50px;
		position: relative;
		display: inline-block;
		padding: 0 25px;

		&:after {
			position: absolute;
			height: 5px;
			width: 100%;
			background-color: var(--theme-deafult);
			opacity: 0.4;
			content: '';
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 3px;
			z-index: -1;
		}
	}
}

.title3 {
	text-align: center;

	h4 {
		color: var(--theme-deafult);
		padding-bottom: 10px;
		margin-bottom: 5px;
		margin-top: -3px;
	}

	.title-inner3 {
		margin-bottom: 20px;
		position: relative;
		margin-top: -5px;
	}

	.line {
		position: relative;
		height: 5px;
		width: 45px;
		background-color: var(--theme-deafult);
		content: '';
		left: 0;
		right: 0;
		margin: 0 auto;
		bottom: 0;
		margin-bottom: 50px;

		&:after,
		&:before {
			position: absolute;
			height: 5px;
			width: 10px;
			content: '';
			background-color: var(--theme-deafult);
		}

		&:after {
			right: 50px;
		}

		&:before {
			left: 50px;
		}
	}
}

.title4 {
	text-align: center;

	.title-inner4 {
		padding-bottom: 25px;
		margin-bottom: 0;
		position: relative;
		margin-top: -5px;
	}

	.line {
		position: relative;
		height: 1px;
		width: 180px;
		background-color: var(--theme-deafult);
		margin: 0 auto;
		margin-bottom: 55px;
		bottom: 0;
		top: 0;

		&:after,
		&:before {
			position: absolute;
			height: 10px;
			width: 10px;
			content: '';
			background-color: var(--theme-deafult);
			top: -4px;
		}

		&:after {
			left: 78px;
		}

		&:before {
			left: 64px;
		}

		span {
			&:after,
			&:before {
				position: absolute;
				height: 10px;
				width: 10px;
				content: '';
				background-color: var(--theme-deafult);
				top: -4px;
			}

			&:after {
				right: 78px;
			}

			&:before {
				right: 64px;
			}
		}
	}
}

.title-borderless {
	margin-bottom: 30px;
	text-align: center;
	margin-top: -5px;
}

/*=====================
    1.3.Brand logo CSS start
    ==========================*/
.logo-block {
	a {
		&:focus {
			outline: none;
		}
	}

	img {
		filter: grayscale(100%);
		opacity: 0.5;
		transition: 0.5s ease;
		margin: 0 auto;

		&:focus {
			outline: none;
		}

		&:hover {
			filter: grayscale(0);
			opacity: 1;
			transition: 0.5s ease;
		}
	}
}

/*=====================
    2.Header CSS start
==========================*/
header {
	background-color: $white;

	.marketplace {
		.top-header {
			background-color: rgba(var(--theme-deafult), 0.75);
		}
	}

	&.video-header {
		.top-header {
			background-color: var(--theme-deafult);

			.header-contact {
				padding: 10px 0;

				li {
					color: $white;
				}
			}

			.header-dropdown {
				li {
					padding: 10px 25px;
					color: $white;

					a {
						color: $white;

						i {
							color: $white;
						}
					}
				}
			}
		}
	}

	&.header-christmas {
		position: absolute;
		width: 100%;

		.main-navbar {
			.nav-menu {
				> li {
					> a {
						padding-top: 22px;
						padding-bottom: 22px;
						color: #ffffff;
					}
					&:hover {
						> a {
							color: #ffffff;
						}
					}
				}
			}
		}

		.main-menu {
			.menu-left {
				.navbar {
					padding: 20px 45px 20px 0;

					i {
						color: white;
					}
				}
			}

			.brand-logo {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}

		.pixelstrap {
			> li {
				> a {
					padding-top: 20px;
					padding-bottom: 20px;
					color: $white;

					&:hover,
					&:active,
					&:focus,
					&.highlighted {
						padding-top: 20px;
						padding-bottom: 20px;
						color: $white;
					}
				}
			}

			li {
				.lable-nav {
					top: 0;
				}
			}
		}

		.onhover-div {
			padding-top: 20px;
			padding-bottom: 20px;

			> div {
				img {
					filter: brightness(54);
				}
			}
		}

		.top-header {
			z-index: 9;
			position: relative;
			background: transparent;
			border-bottom: 1px solid #d13b3c;

			.header-contact {
				li {
					color: #fff;
					font-size: 16px;

					i {
						color: #fff;
					}
				}
			}

			.header-dropdown {
				li {
					color: $white;

					a {
						color: $white;

						i {
							color: $white;
						}
					}
				}
			}

			&.blue-layout {
				border-bottom: 1px solid #223b7d;
			}
		}

		.toggle-nav {
			padding-top: 24px;
			padding-bottom: 24px;

			i {
				color: white !important;
			}
		}

		.cart-qty-cls {
			background: #ffffff;
			color: var(--theme-deafult);
		}
	}

	&.marketplace {
		&.header-tools {
			position: relative;
			width: 100%;
			top: 0;
		}

		.top-header {
			background-color: var(--theme-deafult);

			.header-contact {
				li {
					color: white;
				}
			}

			.header-dropdown {
				li {
					color: white;

					a {
						color: white;

						i {
							color: white;
						}
					}
				}
			}
		}
	}
}

.color-dark {
	.top-header {
		background-color: $grey-dark;
	}
}

.onhover-dropdown {
	position: relative;
	font-size: 14px;

	&:before {
		content: '\f107';
		font-family: $font-awesome;
		position: absolute;
		right: 3px;
	}

	.onhover-show-div {
		top: 50px;
		position: absolute;
		z-index: 10;
		background-color: $white;
		transition: all linear 0.3s;
		min-width: 160px;
		text-align: left;
		-webkit-box-shadow: 0 0 1px 0 #ebebeb;
		-moz-box-shadow: 0 0 1px 0 #ebebeb;
		box-shadow: 0 0 1px 0 #ebebeb;
		transform: translateY(30px);
		opacity: 0;
		visibility: hidden;
		right: 0;
		padding: 15px 20px;
		border-radius: 0 !important;

		&.product-page-full {
			z-index: 99999;
		}
	}

	&:hover {
		.onhover-show-div {
			opacity: 1;
			transform: translateY(0px);
			visibility: visible;
		}
	}
}

.onhover-div {
	position: relative;
	padding: 10px 15px 15px 0;
	font-family: $font_0, $font_1;

	img,
	i {
		cursor: pointer;
	}

	.show-div {
		top: 103px;
		position: absolute;
		z-index: 9;
		background-color: $white;
		transition: all linear 0.3s;
		min-width: 236px;
		text-align: left;
		-webkit-box-shadow: 0 0 1px 0 #ebebeb;
		-moz-box-shadow: 0 0 1px 0 #ebebeb;
		box-shadow: 0 0 1px 0 #ebebeb;
		transform: translateY(30px);
		opacity: 0;
		visibility: hidden;
		right: 0;
		padding: 0;
		border-radius: 0 !important;
	}

	&:hover {
		.show-div {
			opacity: 1;
			transform: translateY(0px);
			visibility: visible;
		}
	}
}

.search-modal #btnSearch {
	left: 10% !important;
	border-radius: 5px;
}

#btnSearch {
	width: 300px;
	position: absolute;
	right: 242px;
	top: 0;
	left: 40%;
	padding-left: 10px;
}

.search-overlay {
	height: 100%;
	width: 100%;
	display: none;
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	background-color: #777;
	opacity: 0.93;

	> div {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.overlay-content {
			width: 60%;
			position: fixed;
			top: 30px;
			.form-control {
				&:focus {
					box-shadow: none;
				}
			}

			input {
				/*background-color: transparent;*/
				border: 1px solid lighten($black, 46.5%);
				border-radius: 5px;
				padding: 20px 0;

				&::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					color: lighten($black, 46.5%);
					font-size: 18px;
				}

				&::-moz-placeholder {
					/* Firefox 19+ */
					color: lighten($black, 46.5%);
					font-size: 18px;
				}

				&:-ms-input-placeholder {
					/* IE 10+ */
					color: lighten($black, 46.5%);
					font-size: 18px;
				}

				&:-moz-placeholder {
					/* Firefox 18- */
					color: lighten($black, 46.5%);
					font-size: 18px;
				}
			}

			button {
				position: absolute;
				top: 0;
				background-color: transparent;
				border: 0;
				font-size: 24px;
				color: lighten($white, 46.5%);
				left: 32%;
			}
		}

		.closebtn {
			position: fixed;
			top: 10px;
			left: 20px;
			color: lighten($white, 46.5%);
			font-size: 40px;
			cursor: pointer;
		}
	}
}

.top-header {
	.header-contact {
		padding: 15px 0;

		li {
			color: $header-font;
			font-size: 14px;
			padding-right: 25px;

			i {
				color: var(--theme-deafult);
				padding-right: 10px;
			}
		}
	}

	.header-dropdown {
		li {
			cursor: pointer;
			color: $header-font;
			padding: 15px 25px 0;
			font-size: 14px;

			&:first-child {
				padding-right: 0;
			}

			&:nth-child(2) {
				padding-right: 0;
			}

			a {
				text-transform: capitalize;
				color: $header-font;
			}

			i {
				padding-right: 5px;
				transition: all 0.5s ease;
			}

			ul {
				li {
					padding-top: 0;
					padding-bottom: 0;
				}
			}

			a {
				i {
					color: $header-font;
					transition: all 0.5s ease;
				}

				&:hover {
					i {
						color: var(--theme-deafult);
						transition: all 0.5s ease;
					}
				}
			}

			&:hover {
				i {
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}
			}
		}

		.onhover-dropdown {
			.onhover-show-div {
				li {
					padding-left: 0;
					display: flex;
					font-size: 15px;
					padding-bottom: 5px;

					&:last-child {
						padding-bottom: 0;
					}

					a {
						color: $font-color;
					}
				}
			}
		}
	}

	&.top-header-dark {
		background-color: $dark-font;

		.header-contact {
			li {
				color: $grey;

				i {
					color: $grey;
				}
			}
		}

		.header-dropdown {
			li {
				color: $grey;

				a {
					color: $grey;

					i {
						color: $grey;
					}

					&:hover {
						i {
							color: var(--theme-deafult);
						}
					}
				}
			}
		}
	}

	&.top-header-dark2 {
		background-color: $grey-dark;

		.header-contact {
			li {
				color: $grey-shade;

				i {
					color: $grey-shade;
				}
			}
		}

		.header-dropdown {
			li {
				color: $grey-shade;

				a {
					color: $grey-shade;

					i {
						color: $grey-shade;
					}

					&:hover {
						i {
							color: var(--theme-deafult);
						}
					}
				}
			}
		}
	}

	&.top-header-dark3 {
		background-color: $grey-darken;

		.header-contact {
			li {
				color: $grey-link;

				i {
					color: $grey-link;
				}
			}
		}

		.header-dropdown {
			li {
				color: $grey-link;

				a {
					color: $grey-link;

					i {
						color: $grey-link;
					}

					&:hover {
						i {
							color: var(--theme-deafult);
						}
					}
				}
			}
		}
	}
}

.layout3-menu {
	.main-menu {
		.menu-left {
			&.around-border {
				.navbar {
					> a {
						padding: 0;
						display: block;
					}

					i {
						font-size: 22px;
					}
				}
			}
		}
	}
}
.footer-contant {
	text-align: center !important;
}
.main-menu {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 9;

	&.product-full-page {
		z-index: 9999;
	}

	.brand-logo {
		display: inline-block;
		padding-top: 35px;
		padding-bottom: 35px;
	}

	.menu-left {
		display: flex;
		align-items: center;

		.navbar {
			display: inline-block;
			// padding: 10px 45px 15px 40px;
			padding: 0px 45px 6px 40px;

			i {
				font-size: 28px;
				color: $dark-font;
				cursor: pointer;
			}

			&.white-navbar {
				i {
					color: $white;
				}
			}
		}

		&.around-border {
			.navbar {
				> a {
					// border: 2px solid lighten(var(--theme-deafult), 35%);
					padding: 11px 10px 6px;
				}
			}
		}

		&.category-nav-right {
			.navbar {
				padding-right: 0;
				padding-left: 45px;

				> a {
					padding: 0;
				}

				i {
					color: $sidebar-color;
				}
			}
		}
	}

	.menu-right {
		display: flex;
		float: right;

		.icon-nav {
			display: inline-block;

			li {
				padding-left: 20px;
			}

			.onhover-div {
				.show-div {
					&.shopping-cart {
						padding: 15px 15px;
						min-width: 270px;
						left: unset;
						right: 0;
						max-height: 250px;
						overflow-y: auto;

						li {
							position: relative;
							padding-bottom: 10px;
							width: 100%;

							&:last-child {
								padding-bottom: 0;
							}

							.media {
								img {
									height: 90px;
								}

								.media-body {
									align-self: center;

									h4 {
										color: $font-color;

										span {
											color: $grey;
											font-size: 16px;
										}
									}
								}
							}

							.close-circle {
								position: absolute;
								top: 0;
								right: 0;

								i {
									color: $grey-light;
									transition: all 0.3s ease;

									&:hover {
										color: $black;
										transition: all 0.3s ease;
									}
								}
							}

							.total {
								border-top: 1px solid $border-grey;
								border-bottom: 1px solid $border-grey;
								padding-top: 10px;
								padding-bottom: 10px;

								h5 {
									text-transform: capitalize;
									margin-bottom: 0;
									color: $header-font;

									span {
										float: right;
									}
								}
							}

							.buttons {
								a {
									font-size: 16px;
									color: $font-color;
									text-transform: capitalize;
									font-weight: 700;

									&:hover {
										color: var(--theme-deafult);
									}
								}

								.checkout {
									float: right;
								}
							}
						}
					}

					&.setting {
						padding: 15px 20px 20px;
						min-width: 175px;
						right: 0;
						left: unset;

						h6 {
							font-size: 16px;
							text-transform: capitalize;
							color: $font-color;
							font-weight: 700;
							margin-bottom: 0;
							margin-top: 5px;

							&:first-child {
								margin-top: 0;
							}
						}

						ul {
							li {
								display: flex;
								padding-bottom: 0;
								padding-left: 17px;
								padding-top: 5px;
								font-size: 14px;
								cursor: pointer;

								a {
									color: $font-color;
									font-weight: 400;
									font-size: 16px;
									text-transform: capitalize;
								}
							}
						}
					}

					li {
						padding: 0;

						.form-control {
							border-radius: 0;

							&:focus {
								border: 1px solid #ced4da;
								box-shadow: none;
							}
						}

						.search-btn {
							padding: 8px 12px;
							background-color: var(--theme-deafult);
							color: $white;
						}
					}

					ul {
						li {
							a {
								cursor: pointer;
							}
						}
					}
				}
			}

			&.white-icon {
				.onhover-div {
					img {
						filter: brightness(5);
					}

					.show-div {
						img {
							filter: none;
						}
					}
				}
			}
		}
	}
}

.nav-cat {
	li {
		position: relative;
		display: flex;
		padding-left: 25px;
		padding-right: 25px;

		.round-cat {
			height: 50px;
			width: 50px;
			border: 1px solid #dddddd;
			padding: 12px;
			border-radius: 100%;
			margin-right: 10px;
		}

		a {
			color: $dark-font;
			font-size: 16px;
			text-transform: uppercase;
		}
	}
}

.Toastify__toast--info {
	background: #007bff;
}

.Toastify__toast--success {
	background: #22af47;
}

.Toastify__toast--warning {
	background: #ff850d;
}

.Toastify__toast--error {
	background: #ff2046;
}

@keyframes smoothScroll {
	0% {
		transform: translateY(-40px);
	}

	100% {
		transform: translateY(0px);
	}
}

/*=====================
    3.Home slider CSS start
==========================*/
.home-slider,
.center-slider {
	.slick-prev,
	.slick-next {
		opacity: 0;
		transform: scale(1);
		transition: all 0.5s ease;
		margin-top: -10px;

		&:before {
			font: normal normal normal 14px/1 $font-awesome;
			opacity: 1;
			color: black;
			background-color: rgba($white, 0.5);
			border-radius: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.slick-next {
		right: 1px;

		&:before {
			content: '\f105';
		}
	}

	.slick-prev {
		left: 1px;
		z-index: 1;

		&:before {
			content: '\f104';
		}
	}

	.home {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 45vh;

		@for $i from 1 through 56 {
			&.home#{$i} {
				background-image: url('#{$assetspath}/home-banner/#{$i}.jpg');
			}
		}
	}

	.slider-details {
		position: absolute;
		background-color: rgba($black, 0.1);
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 26%;
		right: 18%;
		text-align: center;
		padding: 55px;
		width: 510px;
		height: 510px;

		&.blue-slide {
			background-color: #223b7d;
		}

		.btn-white {
			margin-top: 15px;
		}

		h1 {
			color: #ffffff;
			font-weight: 700;
		}

		h3 {
			color: $white;
			position: relative;
			text-transform: uppercase;
			display: inline-block;

			&:after,
			&:before {
				content: '';
				position: absolute;
				width: 12px;
				height: 12px;
				background-color: white;
				border-radius: 100%;
				top: 8px;
			}

			&:after {
				left: -22px;
				top: 8px;
			}

			&:before {
				top: 8px;
				right: -22px;
			}
		}

		h2 {
			font-weight: 400;
			margin-bottom: 0;
			font-size: 45px;
		}

		h4 {
			font-size: 24px;
		}

		h2,
		h3,
		h4 {
			color: white;
		}
	}

	.slider-contain {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;

		h1 {
			margin-bottom: 0;
			margin-top: 10px;
			color: $dark-font;
		}

		h4 {
			font-weight: 700;
			letter-spacing: 0.2em;
			color: $grey-lighter;
			margin-bottom: 0;
			font-size: 20px;
		}

		.btn-solid,
		.btn-outline {
			margin-top: 20px;
		}
	}

	.p-left {
		.slider-contain {
			justify-content: flex-start;
		}
	}

	.p-right {
		.slider-contain {
			justify-content: flex-end;
		}
	}

	.p-center {
		.slider-contain {
			justify-content: center;
		}
	}

	&:hover {
		.slick-prev,
		.slick-next {
			opacity: 1;
			transform: scale(2.5);
			transition: all 0.5s ease;

			&:before {
				opacity: 1;
			}
		}

		.slick-next {
			right: 100px;
		}

		.slick-prev {
			left: 100px;
		}
	}
}

.home-slider-container {
	.slider-contain {
		padding: 0 calc(10px + (100 - 10) * ((100vw - 320px) / (1920 - 320)));
	}
}

.center-slider {
	border-left: 1px solid $border-grey;
	border-right: 1px solid $border-grey;
}

.home-slider {
	&.text-white {
		.slider-contain {
			h1,
			h4 {
				color: $white;
			}
		}
	}
}

.gym-slider {
	.home-slider {
		.home {
			height: 85vh;

			.slider-contain {
				height: 85vh;

				h4,
				h1 {
					color: $white;
				}
			}
		}
	}
}

.metro-slider {
	.home-slider {
		.home {
			background-position-y: 70% !important;

			.slider-contain {
				margin-top: 75px;

				h4,
				h1 {
					color: $black !important;
				}
			}
		}
	}
}

.small-slider {
	.home-slider {
		.home {
			height: 55vh;

			.slider-contain {
				height: 55vh;

				h4,
				h1 {
					color: $black;
				}
			}
		}
	}
}

.height-100 {
	.home-slider {
		.home {
			height: 100vh !important;
		}

		.slider-contain {
			height: calc(99vh + 80px);
		}
	}
}

.height-85 {
	.home-slider {
		.home {
			height: 85vh;
		}

		.slider-contain {
			height: 85vh;
		}
	}

	&.content_top {
		.slider-contain {
			padding-top: 150px;
			align-items: flex-start;

			h1 {
				font-size: 40px;
				letter-spacing: 0.05em;
			}

			.btn-solid {
				padding: 9px 18px;
				margin-top: 10px;
			}

			h4 {
				letter-spacing: 0.08em;
			}
		}
	}
}

.layout-7 {
	.home {
		height: 60vh;

		.slider-contain {
			height: 60vh;
		}
	}
}

.banner-slider {
	.height-banner {
		height: 100%;
	}

	.home {
		height: 81vh;

		.slider-contain {
			height: 81vh;
		}
	}

	.home-banner {
		> div {
			img {
				width: 100%;
			}

			&:last-child {
				img {
					margin-top: 30px;
				}
			}
		}
	}
}

.absolute-banner {
	margin-top: -105px;

	.absolute-bg {
		background-color: white;
		position: relative;
		padding: 25px;
		box-shadow: 0 0 8px 0 #ddd;
	}
}

/*=====================
    4.Instagram CSS start
==========================*/
.instagram {
	margin-bottom: -6px;
	overflow: hidden;
}

.instagram-box {
	position: relative;
	background-color: var(--theme-deafult);
	overflow: hidden;

	img {
		width: 100%;
	}

	.overlay {
		transition: 0.5s ease;
		opacity: 0;
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: var(--theme-deafult);
		top: 0;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: scale(3) rotate(90deg);

		i {
			font-size: 60px;
			color: $white;
		}
	}

	&:hover {
		.overlay {
			opacity: 0.5;
			transition: 0.5s ease;
			transform: scale(1) rotate(0deg);
		}
	}
}

/*=====================
    5.Blog CSS start
==========================*/
.blog {
	.slick-slide {
		line-height: 1;
	}
}

.blog-bg {
	background-color: $border-grey;
}

.classic-effect {
	position: relative;
	overflow: hidden;

	&:before,
	&:after {
		background: var(--theme-deafult);
		width: 25%;
		position: absolute;
		content: '';
		opacity: 0;
		-webkit-transition: all 0.3s steps(4);
		transition: all 0.3s steps(4);
		z-index: 1;
		bottom: 100%;
		top: 0;
	}

	&:before {
		left: 0;
		transition-delay: 0s;
	}

	&:after {
		left: 25%;
		transition-delay: 0.025s;
	}

	span {
		&:before,
		&:after {
			background: var(--theme-deafult);
			width: 25%;
			position: absolute;
			content: '';
			opacity: 0;
			-webkit-transition: all 0.3s steps(4);
			transition: all 0.3s steps(4);
			z-index: 1;
			bottom: 100%;
			top: 0;
		}

		&:before {
			left: 50%;
			transition-delay: 0.05s;
		}

		&:after {
			left: 75%;
			transition-delay: 0.15s;
		}
	}

	&:hover {
		&:before,
		&:after {
			bottom: 0;
			opacity: 0.5;
		}

		span {
			&:before,
			&:after {
				bottom: 0;
				opacity: 0.5;
			}
		}
	}
}

.blog-details {
	text-align: center;
	padding-left: 15px;
	padding-right: 15px;

	h4 {
		color: var(--theme-deafult);
		margin-top: 25px;
		line-height: 1;
		margin-bottom: 0;
	}

	p {
		color: $dark-font;
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 700;
		line-height: 1.5;
		letter-spacing: 0.03em;
		margin-top: 10px;
		margin-bottom: -5px;
		transition: all 0.5s ease;

		&:hover {
			color: var(--theme-deafult);
			transition: all 0.5s ease;
		}
	}

	h6 {
		line-height: 1;
		letter-spacing: 0.1em;
		margin-bottom: 0;
	}
}

.blog-2 {
	.blog-details {
		h6 {
			padding: 5px 0;
		}
	}
}

.error-message {
	color: red;
	font-weight: 500;
}
.error_border {
	border: 2px solid red !important;
}

/*=====================
    6.Service CSS start
==========================*/
.service-block {
	display: flex;
	align-items: center;
	justify-content: center;

	.media {
		padding-top: 23px;
		padding-bottom: 23px;
		align-items: center;
	}

	svg {
		margin-right: 12px;
		width: 60px;
		height: 60px;

		path {
			fill: var(--theme-deafult);
		}
	}

	h4 {
		font-weight: 700;
		text-transform: uppercase;
		letter-spacing: 0.03em;
	}

	p {
		text-transform: capitalize;
		margin-bottom: 0;
	}

	+ .service-block {
		border-left: 1px solid lighten($black, 86.5%);
	}

	&:hover {
		svg {
			animation: pulse 1000ms ease-in-out;
			transition: all 0.3s ease;
		}

		h4 {
			color: var(--theme-deafult);
			transition: all 0.3s ease;
		}
	}
}

.service-block1 {
	text-align: center;

	img {
		margin: 0 auto 12px;
	}

	svg {
		margin-bottom: 20px;
		width: 60px;
		height: 60px;

		path {
			fill: var(--theme-deafult);
		}
	}

	h4 {
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 15px;
	}

	p {
		letter-spacing: 0.03em;
		margin-bottom: -3px;
		line-height: 1.5;
	}

	&:hover {
		svg {
			animation: pulse 1000ms ease-in-out;
			transition: all 0.3s ease;
		}

		h4 {
			color: var(--theme-deafult);
			transition: all 0.3s ease;
		}
	}
}

.media-mb-0 {
	.service-block1 {
		svg {
			margin-bottom: 0;
		}
	}
	h4 {
		margin-bottom: 4px;
		margin-top: 15px;
	}
	.media {
		display: block;
	}
	.row {
		margin-bottom: -30px;
		> div {
			margin-bottom: 30px;
		}
	}
}

/*=====================
    7.About CSS start
==========================*/
.about-text {
	p {
		line-height: 28px;
		letter-spacing: 0.06em;
		text-align: center;
		margin-bottom: 50px;
	}
}

/*=====================
    8.Banner Timer CSS start
==========================*/
.banner-timer {
	background-image: url('#{$assetspath}/offer-banner.jpg');
	background-size: cover;
	margin-left: 20px;
	margin-right: 20px;

	.banner-text {
		padding-top: 45px;
		padding-bottom: 45px;

		h2 {
			margin-bottom: 0;
			text-align: center;

			span {
				color: var(--theme-deafult);
			}
		}
	}

	.timer-box {
		justify-content: center;
		display: flex;
	}
}

.timer {
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 40px;
	margin-top: 30px;
	background-color: $grey-dark;
	display: inline-block;

	.timer-p {
		font-size: 18px;
		color: $white;
		margin-bottom: 0;
		line-height: 1;
	}

	span {
		width: 70px;
		display: inline-block;

		.timer-cal {
			font-size: 12px;
			color: $grey;
		}

		.padding-l {
			padding-left: 22px;
			display: inline;
		}
	}
}

/*=====================
    9.Category CSS start
==========================*/
.category-block {
	.category-image {
		margin: 0 auto;
		text-align: center;
		border: 1px solid $round-border;
		border-radius: 100%;
		width: 80px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.5s ease;

		&.svg-image {
			svg {
				width: 50px;
				fill: var(--theme-deafult);
			}
		}
	}

	.category-details {
		margin-top: 15px;
		text-align: center;

		h5 {
			font-weight: 700;
			margin: 0 auto;
			transition: 0.5s ease;
			text-transform: uppercase;
		}
	}

	&:hover {
		.category-image {
			background-color: var(--theme-deafult);
			transition: all 0.5s ease;

			&.svg-image {
				svg {
					fill: #ffffff;
				}
			}

			img {
				filter: brightness(0) invert(1);
				transition: all 0.5s ease;
			}
		}

		.category-details {
			h5 {
				color: var(--theme-deafult);
				transition: all 0.5s ease;
			}
		}
	}
}

.category-border {
	background-color: $border-grey;
	padding: 20px 10px;

	.border-padding {
		padding: 0 10px;
	}

	div {
		.category-banner {
			padding: 0;
			position: relative;
			overflow: hidden;

			img {
				transform: scale(1);
				width: 100%;
				transition: 0.5s ease;
			}

			&:hover {
				img {
					transform: scale(1.1) translateX(14px);
					transition: 0.5s ease;
				}

				h2 {
					color: var(--theme-deafult);
					transition: 0.5s ease;
				}
			}

			.category-box {
				z-index: 1;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				h2 {
					background-color: $white;
					display: inline-block;
					padding: 20px 35px;
					margin-bottom: 0;
					box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
					transition: 0.5s ease;
				}
			}
		}
	}
}

.category-bg {
	padding: 40px 50px;
	position: relative;
	z-index: 1;

	.image-block {
		img {
			width: 100%;
		}

		&.even {
			&:after {
				bottom: 94%;
			}
		}

		&:after {
			content: '';
			position: absolute;
			background-color: var(--theme-deafult);
			height: 0;
			width: 40px;
			z-index: 1;
			right: 0;
			left: 0;
			margin: 0 auto;
			opacity: 0;
			transition: 0.5s ease;
			margin-top: -40px;
		}

		&:hover {
			&:after {
				opacity: 0.3;
				transition: 0.5s ease;
				height: 80px;
			}
		}
	}

	.contain-block {
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		height: 100%;
		background-color: $white;

		&.even {
			&:after {
				top: 94%;
			}
		}

		&:after {
			content: '';
			position: absolute;
			background-color: var(--theme-deafult);
			height: 0;
			width: 40px;
			z-index: 1;
			right: 0;
			left: 0;
			margin: 0 auto;
			opacity: 0;
			transition: 0.5s ease;
			bottom: 94%;
		}

		&:hover {
			h2 {
				color: var(--theme-deafult);
				transition: all 0.5s ease;
			}

			h6 {
				span {
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}
			}

			.category-btn {
				background-image: linear-gradient(30deg, $dark-font 50%, transparent 50%);
				border: 2px solid $dark-font;
				transition: all 0.5s ease;
			}

			&:after {
				opacity: 0.3;
				transition: all 0.5s ease;
				height: 80px;
			}
		}

		h2 {
			margin-bottom: 0;
			margin-top: 15px;
			transition: 0.5s ease;
			color: $dark-font;
		}

		h6 {
			color: var(--theme-deafult);
			text-transform: uppercase;
			letter-spacing: 0.3em;
			line-height: 1;
			margin-bottom: 0;

			span {
				color: $grey-shade;
				letter-spacing: 0.03em;
				font-weight: 700;
				transition: all 0.5s ease;
			}
		}

		.category-btn {
			letter-spacing: 0.07em;
			margin-bottom: 25px;
			margin-top: 25px;
			transition: all 0.5s ease;
		}
	}

	&:after {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		background-color: var(--theme-deafult);
		opacity: 0.3;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
}

.category-m {
	.slick-slide {
		> div {
			margin: 0 10px;
		}
	}

	.category-wrapper {
		border: 1px solid $border;
		margin: 0 auto;
		text-align: center;
		justify-content: center;
		padding-top: 40px;
		padding-bottom: 40px;
		position: relative;

		> div {
			&:after,
			&:before {
				content: '';
				width: 1px;
				height: 0;
				position: absolute;
				transition: all 0.2s linear;
				background: var(--theme-deafult);
				transition-delay: 0s;
			}

			&:before {
				left: 0;
				top: 0;
			}

			&:after {
				right: 0;
				bottom: 0;
			}
		}

		&:after,
		&:before {
			content: '';
			width: 0;
			height: 1px;
			position: absolute;
			transition: all 0.2s linear;
			background: var(--theme-deafult);
		}

		&:before {
			right: 0;
			top: 0;
		}

		&:after {
			left: 0;
			bottom: 0;
		}

		&:hover {
			transition: all 0.5s ease;

			&:after,
			&:before {
				transition-delay: 0s;
				width: 100%;
			}

			> div {
				&:after,
				&:before {
					transition-delay: 0.2s;
					height: 100%;
				}
			}
		}

		img {
			display: inline;
		}

		h4 {
			text-transform: uppercase;
			color: $grey-dark;
			font-weight: 700;
			margin-bottom: 0;
			padding-bottom: 25px;
			padding-top: 25px;
		}

		.btn {
			margin-top: 20px;
		}

		.category-link {
			li {
				display: block;
				text-transform: capitalize;
				margin-top: 5px;

				&:first-child {
					margin-top: 0;
				}

				a {
					color: $grey-link;

					&:hover {
						color: var(--theme-deafult);
					}
				}
			}
		}
	}
}

.background {
	background-color: $border-grey;
	padding: 20px 10px;
	margin: 0;

	.contain-bg {
		width: 100%;
		background-color: $white;
		padding-top: 45px;
		padding-bottom: 45px;
		text-align: center;
		transition: all 0.5s ease;

		h4 {
			color: $dark-font;
			text-transform: uppercase;
			font-weight: 700;
			margin-bottom: 0;
			transition: all 0.5s ease;
		}

		&:hover {
			background-color: var(--theme-deafult);

			h4 {
				color: white;
				transition: all 0.5s ease;
			}
		}
	}
}

/*=====================
    10.Product Box CSS start
==========================*/
.sticky-top-cls {
	position: sticky;
	top: 140px;
	z-index: 1;
}
.product-m {
	.slick-list {
		margin-left: -15px;
		margin-right: -15px;
	}

	.slick-slide {
		> div {
			margin: 0 15px;
		}
	}
}

.margin-default {
	margin-bottom: -30px;
	> div {
		margin-bottom: 30px;
	}
}

.absolute-product {
	.theme-tab {
		.tab-title {
			.current {
				a {
					font-weight: 700;
				}
			}
		}
	}

	.product-box {
		width: 100%;
		display: inline-block;
		padding-bottom: 10px;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
		border-radius: 5px;

		.img-wrapper {
			border-radius: 5px 5px 0 0;
		}

		.product-detail {
			text-align: center;
			margin-top: 0;
			padding: 0 15px;

			.color-variant {
				padding-top: 5px;

				li {
					height: 16px;
					width: 16px;
				}
			}

			.cart-bottom {
				border-top: 1px solid #ddd;
				padding-top: 10px;
				margin-top: 10px;

				button {
					border: none;
					background-color: transparent;
				}

				i {
					color: #828282;
					font-size: 18px;
					padding-right: 7px;
					padding-left: 7px;
					transition: all 0.5s ease;

					&:hover {
						color: var(--theme-deafult);
						transition: all 0.5s ease;
					}
				}
			}

			.rating {
				margin-top: 10px;
			}
		}
	}

	.slick-slider {
		.product-box {
			margin-bottom: 3px;
		}
	}

	&.digital-product {
		.product-box {
			padding-bottom: 15px;

			.img-wrapper {
				.digital-wrapper {
					display: flex;
					width: 100%;

					.live-demo,
					.details_product {
						width: 50%;
						background-color: rgba(var(--theme-deafult), 0.6);
						color: white;
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 6px 0;
						position: absolute;
						bottom: 0;

						h4 {
							margin-bottom: 0;
							line-height: 1.2;
						}
					}

					.live-demo {
						left: -50%;
						transition: all 0.5s ease;
					}

					.details_product {
						right: -50%;
						transition: all 0.5s ease;
						//border-left: 1px solid white;
					}
				}
			}

			.product-detail {
				h6 {
					padding-top: 15px;
					color: $black;
					font-weight: 700;
				}

				h4 {
					color: $black;
					font-weight: 100;
				}
			}

			&:hover {
				.img-wrapper {
					.digital-wrapper {
						.live-demo {
							left: 0;
							transition: all 0.5s ease;
						}

						.details_product {
							right: 0;
							transition: all 0.5s ease;
						}
					}
				}
			}
		}
	}
}

.color-variant {
	li {
		display: inline-block;
		height: 20px;
		width: 20px;
		border-radius: 100%;
		margin-right: 5px;
		transition: all 0.1s ease;
		vertical-align: middle;
	}
}

.no-slider {
	.product-box {
		width: 100%;
		flex: 0 0 25%;
		max-width: calc(25% - 30px);
		margin: 0 15px 30px;

		&:nth-last-child(-n + 4) {
			margin: 0 15px 0;
		}
	}

	&.five-product {
		.product-box {
			width: 100%;
			flex: 0 0 20%;
			max-width: calc(20% - 30px);
			margin: 0 15px 30px;

			&:nth-last-child(-n + 5) {
				margin: 0 15px 0;
			}
		}
	}
}

.product-para {
	p {
		margin-bottom: 0;
		padding-bottom: 25px;
		line-height: 24px;
		letter-spacing: 0.05em;
		margin-top: -7px;
	}
}

.portfolio-section {
	.article-title {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		color: white;
		transition: all 0.5s ease;
		text-align: center;
		float: left;
		font-size: 18px;
		line-height: 24px;

		a {
			color: white;
		}
	}

	.isotopeSelector {
		float: left;
		width: 100%;
		position: relative;

		.overlay {
			position: relative;
			border: 1px solid $round-border;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.isotopeSelector {
		.product-box {
			margin-bottom: 30px;

			img {
				transition: all 0.4s ease;
			}

			.overlay-background {
				transform: scale(0);
				transition: all 0.4s ease;

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					background-color: white;
					padding: 10px;
					border-radius: 100%;
					transform: translate(-50%, -50%);
					color: var(--theme-deafult);
					font-size: 22px;
					display: none;
					transition: all 0.4s ease;
					width: 40px;
					height: 40px;
					align-items: center;
					justify-content: center;
				}
			}

			&:hover {
				img {
					transform: scale(1.2) rotate(2deg);
					transition: all 0.4s ease;
				}

				.overlay-background {
					transform: scale(1);
					border: 3px solid white;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background-color: rgba(var(--theme-deafult), 0.2);
					transition: all 0.4s ease;

					i {
						display: flex;
						transition: all 0.4s ease;
					}
				}
			}
		}
	}

	.border-portfolio {
		margin: 3px;
		overflow: hidden;
	}

	#form1 {
		padding-bottom: 20px;
	}

	.filter-button {
		padding: 0 24px;
		font-size: 20px;
		line-height: 35px;
		border: none;
		background-color: white;
		cursor: pointer;

		&.active {
			background-color: var(--theme-deafult);
			color: $white;
		}
	}

	&.fullwidth-portfolio {
		.isotopeSelector {
			padding-left: 7px;
			padding-right: 7px;
			margin-bottom: 14px;
		}
	}

	&.metro-section {
		.isotopeSelector {
			&:hover {
				img {
					//transform: scale(1) rotate(0deg);
					//transition: all 0.5s ease;
				}
			}
		}

		.product-box {
			.product-detail {
				opacity: 0;
				position: absolute;
				background-color: $white;
				padding: 10px;
				transition: all 0.5s ease;
				width: 65%;
				left: 0;
				right: 0;
				margin: 0 auto;
				bottom: -10px;
				text-align: center;

				h6 {
					color: #525252;
					padding-top: 0;
					margin-top: -2px;
				}

				h4 {
					font-weight: 400;
					color: $black;
				}
			}

			.cart-wrap {
				top: 7px;
				right: 7px;
				opacity: 1;

				button {
					border: 1px solid $white;
					border-radius: 100%;
					background-color: rgba($white, 0.8);
					opacity: 1;

					i {
						padding: 7px;
						color: $font-color;
					}
				}

				a {
					i {
						border-radius: 100%;
						border: 1px solid $white;
						margin: 5px 0;
						background-color: rgba($white, 0.8);
						padding: 7px;
						color: $font-color;
						opacity: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			&:hover {
				.product-detail {
					opacity: 1;
					transition: all 0.5s ease;
					bottom: 15px;
				}

				.cart-wrap {
					button {
						animation: none;
					}

					a {
						i {
							opacity: 1;
						}

						&:nth-child(2) {
							i {
								animation: fadeInDown 500ms ease-in-out;
							}
						}

						&:nth-child(3) {
							i {
								animation: fadeInDown 700ms ease-in-out;
							}
						}

						&:nth-child(4) {
							i {
								animation: fadeInDown 1000ms ease-in-out;
							}
						}
					}
				}
			}
		}
	}
}

.product-box,
.product-wrap {
	position: relative;
	transition: all 0.5s ease;

	&.product-wrap {
		//padding-bottom: 15px;
	}

	.img-block {
		background-color: $grey-lighter;
		position: relative;
		overflow: hidden;

		.front {
			opacity: 1;
			top: 0;
			left: 0;
			transition: all 0.5s ease;
		}

		.back {
			opacity: 0;
			position: absolute;
			backface-visibility: hidden;
			top: 0;
			left: 0;
			transition: all 0.5s ease;
			transform: translateX(-100px);
		}

		.lable-wrapper {
			margin: 0 auto;
			top: 40px;
			position: absolute;
			left: 0;
			right: 0;
			text-align: center;
			transition: all 0.5s ease;
			z-index: 2;

			.lable1,
			.lable2 {
				font-size: 14px;
				padding: 10px 14px 10px 20px;
				display: inline-block;
				text-transform: uppercase;
				text-align: center;
			}

			.lable1 {
				background-color: var(--theme-deafult);
				color: $white;
				border-bottom-left-radius: 25px;
				border-top-left-radius: 25px;
			}

			.lable2 {
				background-color: $white;
				color: $black;
				border-bottom-right-radius: 25px;
				border-top-right-radius: 25px;
			}
		}
	}

	.img-wrapper {
		position: relative;
		overflow: hidden;

		.front {
			opacity: 1;
			top: 0;
			left: 0;
			transition: all 0.5s ease;
		}

		.back {
			opacity: 0;
			position: absolute;
			backface-visibility: hidden;
			top: 0;
			left: 0;
			transition: all 0.5s ease;
			transform: translateX(-100px);
		}

		.cart-box {
			position: absolute;
			margin: 0 auto;
			display: inline-block;
			right: 0;
			left: 0;
			border-radius: 50px;
			width: max-content;
			padding: 12px 15px;
			box-shadow: 0 0 12px 0 $round-border;
			bottom: 30px;
			background-color: $white;
			opacity: 0;
			transition: all 0.2s ease;

			button {
				background: none;
				box-shadow: none;
				border: none;
				padding: 0;
			}

			i {
				color: #bbbbbb;
				font-size: 18px;
				padding-left: 8px;
				padding-right: 8px;
				transition: all 0.2s ease;
				display: inline-block;

				&:hover {
					color: var(--theme-deafult);
					transition: all 0.2s ease;
				}
			}
		}

		.lable-block {
			.lable3 {
				border-radius: 100%;
				background-color: var(--theme-deafult);
				text-align: center;
				font-size: 14px;
				font-weight: 700;
				position: absolute;
				padding: 12px 6px;
				text-transform: uppercase;
				color: $white;
				top: 7px;
				left: 7px;
			}

			.lable4 {
				position: absolute;
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
				writing-mode: vertical-rl;
				transform: rotate(-180deg);
				top: 7px;
				right: 7px;
				letter-spacing: 0.1em;
			}
		}

		.product-thumb-list {
			position: absolute;
			bottom: 0;
			left: 0;

			li {
				display: block;
				opacity: 0.3;

				&.active {
					opacity: 1;
				}

				img {
					height: 50px;
					padding: 0;
				}
			}
		}
	}

	.cart-info,
	.cart-wrap {
		position: absolute;
		bottom: 40px;
		text-align: center;
		margin: 0 auto;
		display: inline-block;
		right: 0;
		left: 0;
		justify-content: center;
		opacity: 0;
		transition: all 0.5s ease;

		a {
			[class^='icon-'],
			[class*=' icon-'] {
				display: inline-block;
			}
		}

		&.cart-wrap {
			bottom: 0;
			text-align: right;
			left: unset;

			i {
				display: block;
				padding-bottom: 10px;
				padding-top: 10px;
			}

			&.cart-effect-left {
				left: 0;
				right: unset;
			}
		}

		button {
			background: none;
			box-shadow: none;
			border: none;
			padding: 0;
		}

		i {
			color: #bbbbbb;
			font-size: 18px;
			padding-right: 10px;
			padding-left: 10px;

			&:hover {
				color: var(--theme-deafult);
			}
		}
	}

	.cart-detail {
		position: absolute;
		top: 15px;
		right: 20px;
		opacity: 0;

		i {
			color: $icon;
			font-size: 18px;
			display: flex;
			padding-top: 8px;
			padding-bottom: 8px;
			cursor: pointer;
		}

		button {
			background: none;
			box-shadow: none;
			border: none;
			padding: 0;
		}
	}

	.product-detail,
	.product-info {
		padding-left: 5px;

		.rating {
			margin-top: 15px;

			i {
				padding-right: 5px;

				&:nth-child(-n + 4) {
					color: $star-yellow;
				}

				&:last-child {
					color: $round-border;
				}
			}
		}

		h6 {
			line-height: 1;
			margin-bottom: 0;
			padding-top: 2px;
			padding-bottom: 5px;
			transition: all 0.5s ease;
			font-size: 16px;
		}

		h4 {
			font-size: 18px;
			color: $dark-font;
			font-weight: 700;
			margin-bottom: 0;
			transition: all 0.5s ease;
		}

		.color-variant {
			padding-top: 15px;

			li {
				display: inline-block;
				height: 20px;
				width: 20px;
				border-radius: 100%;
				margin-right: 5px;
				transition: all 0.1s ease;
				cursor: pointer;
			}
		}
	}

	.product-info {
		padding: 0;
		text-align: center;
		position: relative;

		.add-btn {
			position: absolute;
			bottom: 110px;
			margin: 0 auto;
			left: 0;
			right: 0;
			opacity: 0;
			transition: all 0.2s ease;
			z-index: 1;

			i {
				color: var(--theme-deafult);
			}

			.btn-outline {
				transition: all 0.2s ease;
				color: var(--theme-deafult);

				&:hover {
					color: $white;

					i {
						color: $white;
					}
				}
			}
		}
	}

	&.effect-center {
		.front {
			img {
				transition: all 0.5s ease;
			}
		}

		.img-wrapper {
			.cart-box {
				bottom: 20%;
				transition: all 0.5s ease;
			}
		}
	}

	&:hover {
		&.effect-center {
			.front {
				img {
					opacity: 0.3;
					transition: all 0.5s ease;
				}
			}

			.img-wrapper {
				.cart-box {
					bottom: 35%;
					transition: all 0.5s ease;
				}
			}
		}

		.img-block,
		.img-wrapper {
			.first {
				opacity: 0;
				transition: all 0.5s ease;
			}

			.back {
				opacity: 1;
				transition: all 0.5s ease;
				transform: translateX(0);
			}
		}

		.cart-info {
			opacity: 1;
			transition: all 0.5s ease;

			button {
				animation: fadeInUp 300ms ease-in-out;
			}

			a {
				&:nth-child(2) i {
					animation: fadeInUp 500ms ease-in-out;
				}

				&:nth-child(3) i {
					animation: fadeInUp 700ms ease-in-out;
				}

				&:nth-child(4) i {
					animation: fadeInUp 1000ms ease-in-out;
				}
			}
		}

		.cart-wrap {
			button {
				animation: fadeInRight 300ms ease-in-out;
			}

			a {
				&:nth-child(2) i {
					animation: fadeInRight 500ms ease-in-out;
				}

				&:nth-child(3) i {
					animation: fadeInRight 700ms ease-in-out;
				}

				&:nth-child(4) i {
					animation: fadeInRight 1000ms ease-in-out;
				}
			}

			&.cart-effect-left {
				button {
					animation: fadeInLeft 300ms ease-in-out;
				}

				a {
					&:nth-child(2) i {
						animation: fadeInLeft 500ms ease-in-out;
					}

					&:nth-child(3) i {
						animation: fadeInLeft 700ms ease-in-out;
					}

					&:nth-child(4) i {
						animation: fadeInLeft 1000ms ease-in-out;
					}
				}
			}
		}

		.cart-detail {
			opacity: 1;
			transition: all 0.5s ease;

			button {
				animation: fadeInRight 300ms ease-in-out;
			}

			a {
				&:nth-child(2) i {
					animation: fadeInRight 500ms ease-in-out;
				}

				&:nth-child(3) i {
					animation: fadeInRight 700ms ease-in-out;
				}

				&:nth-child(4) i {
					animation: fadeInRight 1000ms ease-in-out;
				}
			}
		}

		.product-info {
			.add-btn {
				opacity: 1;
				transition: all 0.2s ease;
				animation: fadeInUp 500ms ease-in-out;
			}
		}

		.img-wrapper {
			.cart-box {
				opacity: 1;
				transition: all 0.2s ease;
				animation: fadeInUp 400ms ease-in-out;
			}
		}
	}
}

.addtocart_count {
	position: relative;

	.product-box {
		.product-detail {
			text-align: center;
		}

		.cart-info {
			bottom: 40px;
			right: 10px;

			a {
				i {
					background-color: #e2e2e2;
					border-radius: 100%;
					margin: 10px 0;
					padding: 8px;
					font-size: 16px;
					color: #313131;
				}
			}
		}

		.add-button {
			background-color: #efefef;
			color: black;
			text-align: center;
			font-size: 18px;
			text-transform: capitalize;
			width: 100%;
			padding: 5px 0;
			transition: all 0.5s ease;
			border: none;
			cursor: pointer;
			line-height: 1.5;
		}

		&:hover {
			.cart-info {
				a {
					&:nth-child(1) {
						i {
							animation: fadeInRight 300ms ease-in-out;
						}
					}
				}
			}

			.add-button {
				bottom: 0;
				transition: all 0.5s ease;
			}
		}
	}

	.addtocart_btn {
		position: relative;

		.cart_qty {
			width: 100%;

			&.qty-box {
				position: absolute;
				bottom: 0;
				display: none;

				.input-group {
					.form-control {
						width: 100%;

						&:focus {
							border-color: #efefef;
							box-shadow: none;
						}
					}

					button {
						background: $grey3 !important;
						position: absolute;
						height: 100%;
						z-index: 9;

						&.quantity-left-minus {
							left: 0;
						}

						&.quantity-right-plus {
							right: 0;
						}
					}

					button {
						i {
							color: $black;
						}
					}
				}
			}

			&.open {
				display: block;
			}
		}
	}
}

.game-product {
	.theme-tab {
		.tab-title,
		.tab-title2 {
			.current {
				a {
					border-bottom: 2px solid var(--theme-deafult);
					padding-bottom: 5px;
				}
			}
		}
	}

	.product-box {
		.product-detail {
			text-align: center;
		}

		.cart-info {
			bottom: 40px;
			right: 10px;

			a {
				i {
					background-color: white;
					border-radius: 100%;
					margin: 10px 0;
					padding: 8px;
					font-size: 16px;
				}
			}
		}

		.add-button {
			background-color: var(--theme-deafult);
			color: white;
			text-align: center;
			font-size: 18px;
			text-transform: capitalize;
			position: absolute;
			width: 100%;
			bottom: -40px;
			padding: 5px 0;
			transition: all 0.5s ease;
			cursor: pointer;
		}

		&:hover {
			.cart-info {
				a {
					&:nth-child(1) {
						i {
							animation: fadeInRight 300ms ease-in-out;
						}
					}
				}
			}

			.add-button {
				bottom: 0;
				transition: all 0.5s ease;
			}
		}
	}

	.img-wrapper {
		img {
			width: 100%;
		}
	}
}

.grid-products {
	margin-bottom: -30px;

	.product-box {
		margin-bottom: 30px;
	}
}

.game-parallax {
	.full-banner {
		&.parallax {
			background-blend-mode: overlay;
			background-color: rgba($black, 0.6);
		}

		.banner-contain {
			h3,
			h4 {
				color: $white;
			}
		}
	}
}

/*=====================
    11.Theme tab CSS start
==========================*/
.theme-tab {
	.react-tabs__tab--selected {
		background: unset !important;
		color: var(--theme-deafult);
		border-radius: 0 !important;

		a {
			color: #dafe69 !important;
		}

		.nav-link {
			color: var(--theme-deafult) !important;
		}
	}
}

.tab-content {
	&.nav-material {
		.react-tabs__tab--selected {
			border-bottom: 2px solid var(--theme-deafult) !important;
		}
	}
}

.react-tabs__tab {
	&:focus {
		border-color: transparent !important;
		box-shadow: none !important;
	}
}

.theme-tab {
	position: relative;

	.tab-content {
		display: block;

		&[style='display: block;'] {
			.product-box,
			.tab-box {
				animation: zoomIn 300ms ease-in-out;
			}
		}

		.product-tab {
			.tab-box {
				background-color: $white;
				width: 100%;
				flex: 0 0 25%;
				max-width: calc(25% - 10px);
				margin: 0 5px 10px;

				&:nth-last-child(-n + 4) {
					margin: 0 5px 0;
				}

				.product-box2 {
					img {
						height: 250px;
						padding: 15px;
						transition: all 0.5s ease;
					}

					.media {
						.media-body {
							.rating {
								margin-top: 0;

								i {
									padding-right: 0;

									&:nth-child(-n + 4) {
										color: $star-yellow;
									}

									&:last-child {
										color: $round-border;
									}
								}
							}

							a {
								h6 {
									margin-right: 35px;
									line-height: 17px;
									margin-top: 5px;
									margin-bottom: 0;
								}
							}

							h4 {
								font-size: 20px;
								margin-top: 5px;
								font-weight: 700;
								color: $dark-font;
								margin-bottom: 0;
							}

							.color-variant {
								li {
									display: inline-block;
									height: 20px;
									width: 20px;
									border-radius: 100%;
									margin-right: 2px;
									margin-top: 20px;
									transition: all 0.1s ease;
									margin-bottom: 0;
									cursor: pointer;
								}
							}
						}
					}
				}

				&:hover {
					.product-box2 {
						img {
							transform: scale(1.03);
							transition: all 0.5s ease;
						}
					}
				}
			}
		}
	}

	.tab-title,
	.tab-title2 {
		text-align: center;
		font-size: 18px;
		margin-bottom: 25px;
		margin-top: -9px;

		.current {
			a {
				color: var(--theme-deafult);
			}
		}

		li {
			padding-left: 25px;
			padding-right: 25px;
			padding-top: 0;
			line-height: 1;
			cursor: pointer;
			text-transform: capitalize;
		}

		a {
			color: $grey-dark;
			text-transform: uppercase;
		}
	}

	.tab-title2 {
		font-size: 24px;
		font-weight: 700;
		text-transform: uppercase;
		margin-top: -8px;

		&:after {
			content: '';
			position: absolute;
			border: 1px solid $border-grey;
			width: 100%;
			left: 0;
			top: 17px;
			z-index: -1;
		}

		.current {
			background-color: $white;

			a {
				background-color: $white;
			}
		}
	}
}

.tab-bg {
	position: relative;
	z-index: 1;
	padding: 70px 50px 60px 50px;

	&.tab-grey-bg {
		background-color: $grey-lighter;
	}

	&:after {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: var(--theme-deafult);
		opacity: 0.09;
		z-index: -1;
	}

	.theme-tab {
		.tab-title {
			margin-bottom: 30px;
		}
	}
}

/*=====================
    12.Banner CSS start
==========================*/
.full-banner {
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	padding-top: 190px;
	padding-bottom: 190px;
	background-position: center;

	// &.jewellery-bg0{
	//   background-image: url('/assets/images/parallax/18.jpg');
	// }

	@for $i from 1 through 28 {
		&.parallax-banner#{$i} {
			background-image: url('#{$assetspath}/parallax/#{$i}.jpg');
		}
	}

	&.parallax-christmas {
		background-image: url('/assets/images/christmas/parallax/1.jpg');
	}

	&.parallax-layout {
		padding-top: 350px;
		padding-bottom: 350px;

		.banner-contain {
			h4 {
				color: $grey-shade;
			}
		}
	}

	&.banner-layout-3 {
		.banner-contain {
			h4 {
				color: $white;
			}

			h3 {
				color: $white;
				margin-top: 10px;
			}

			.color {
				color: var(--theme-deafult);
				line-height: 1;
			}
		}
	}

	&.parallax {
		background-attachment: fixed;
	}

	&.p-left {
		.banner-contain {
			float: left;
		}
	}

	&.p-right {
		.banner-contain {
			float: right;
		}
	}

	&.p-center {
		.banner-contain {
			left: 0;
			right: 0;
			margin: 0 auto;
		}
	}

	&.feature-banner {
		padding: 50px 0;

		h2 {
			text-align: center;
			color: $white;
			margin-bottom: 180px;
			margin-top: -7px;
		}

		.feature-object,
		.feature-object-right {
			text-align: right;
			margin-bottom: -5px;

			li {
				margin-bottom: 55px;
				margin-right: 50px;

				.media {
					img {
						border-radius: 100%;
						border: 1px solid white;
						margin-left: 15px;
						transition: all 0.5s ease;
					}

					.media-body {
						text-align: right;
						margin-top: 18px;

						h4,
						p {
							color: white;
						}

						h4 {
							font-size: 20px;
						}

						p {
							margin-bottom: 0;
						}
					}
				}

				&:hover {
					.media {
						img {
							background-color: white;
							transition: all 0.5s ease;
							transform: scale(1.05);
						}
					}
				}

				&:nth-child(2) {
					padding-right: 30px;
				}

				&:nth-child(3) {
					padding-right: 60px;
					margin-bottom: 0;
				}
			}
		}

		.feature-object-right {
			text-align: left;
			margin-left: 50px;

			li {
				.media {
					img {
						margin-left: 0;
						margin-right: 15px;
					}

					.media-body {
						text-align: left;
					}
				}

				&:nth-child(2) {
					padding-right: 0;
					padding-left: 30px;
				}

				&:nth-child(3) {
					padding-right: 0;
					padding-left: 60px;
				}
			}
		}

		.center-img {
			position: absolute;
			bottom: -112px;
			left: 50%;
			margin: 0 auto;
			transform: translateX(-50%);
		}

		.banner-decor {
			.left-img {
				left: 50px;
				position: absolute;
				top: 0;
				animation: movebounce 4.9s linear infinite;
			}

			.right-img {
				right: 50px;
				position: absolute;
				top: 0;
				animation: movebounce 4.9s linear infinite;
			}
		}

		@keyframes movebounce {
			0% {
				transform: translateY(0px);
			}

			50% {
				transform: translateY(20px);
			}

			100% {
				transform: translateY(0px);
			}
		}
	}

	.banner-contain {
		.btn-solid,
		.btn-outline {
			margin-top: 20px;
		}

		h2 {
			font-size: 100px;
			font-weight: 700;
			color: var(--theme-deafult);
			text-transform: uppercase;
			margin-top: -15px;
		}

		h3 {
			font-size: 60px;
			color: $font-color;
			text-transform: uppercase;
			font-weight: 700;
		}

		h4 {
			font-size: 24px;
			color: $grey;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: 0.5em;
			padding-top: 10px;
			margin-bottom: -5px;
			line-height: 1.3;
		}

		&.christmas-contain {
			.btn-solid,
			.btn-outline {
				margin-top: calc(20px + (65 - 20) * ((100vw - 320px) / (1920 - 320)));
			}

			h2 {
				font-size: calc(24px + (70 - 24) * ((100vw - 320px) / (1920 - 320)));
				font-weight: 700;
				color: $white;
				text-transform: capitalize;
				margin-bottom: 0;
				margin-top: 0;
			}

			h3 {
				font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
				color: $white;
				text-transform: capitalize;
				font-weight: 700;
				margin-bottom: 15px;
				margin-top: -6px;
			}

			h4 {
				font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
				color: $white;
				font-weight: 600;
				text-transform: capitalize;
				letter-spacing: 0.07em;
				padding-top: 10px;
				margin-bottom: -5px;
				line-height: 1.3;

				span {
					color: var(--theme-deafult);
				}
			}
		}
	}

	.santa-img {
		position: absolute;
		bottom: -62px;
		left: 28px;

		img {
			display: inline-block;
			animation-name: dance;
			animation-duration: 2s;
			animation-iteration-count: infinite;
			-webkit-transition-timing-function: cubic-bezier(0.6, 0, 0.735, 0.045);
			-webkit-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
			-moz-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
			-o-transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
			transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
		}
	}

	.decor {
		position: absolute;
		top: -150px;
		left: 0;
	}

	@keyframes dance {
		0% {
			-webkit-transform: rotate(10deg);
			-ms-transform: rotate(10deg);
			transform: rotate(10deg);
		}

		16.66%,
		49.98% {
			-webkit-transform: rotate(-10deg);
			-ms-transform: rotate(-10deg);
			transform: rotate(-10deg);
		}

		32.32% {
			-webkit-transform: rotate(-5deg);
			-ms-transform: rotate(-5deg);
			transform: rotate(-5deg);
		}

		66.64%,
		100% {
			-webkit-transform: rotate(10deg);
			-ms-transform: rotate(10deg);
			transform: rotate(10deg);
		}

		83.8% {
			-webkit-transform: rotate(5deg);
			-ms-transform: rotate(5deg);
			transform: rotate(5deg);
		}
	}
}

.parallax-cls {
	section {
		&:nth-child(odd) {
			.full-banner {
				background-position: right;
			}
		}

		&:nth-child(even) {
			.full-banner {
				background-position: left;
			}
		}

		.banner-contain {
			margin-top: -12px;
		}
	}
}

/*=====================
    13.Collection Banner CSS start
==========================*/
.banner-text-white {
	.collection-banner {
		.contain-banner {
			h2 {
				color: $white;
			}
		}
	}
}

.collection-banner {
	position: relative;
	overflow: hidden;

	img {
		transition: all 0.5s ease;
		width: 100%;
	}

	&.p-left {
		.contain-banner {
			justify-content: flex-start;
		}
	}

	&.p-right {
		.contain-banner {
			justify-content: flex-end;
		}
	}

	&.p-center {
		.contain-banner {
			justify-content: center;
		}
	}

	.contain-banner {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding-left: 70px;
		padding-right: 70px;

		&.banner-3 {
			padding-left: 50px;
			padding-right: 50px;

			h2 {
				font-size: 36px;
				letter-spacing: 0.05em;
				color: white;
				margin-top: 5px;
				margin-bottom: -6px;
			}

			h4 {
				color: $white;
			}
		}

		&.banner-4 {
			padding-left: 50px;
			padding-right: 50px;

			h2 {
				font-size: 28px;
				letter-spacing: 0.03em;
				color: white;
				margin-bottom: -6px;
			}
		}

		&.banner-5 {
			padding-left: 20px;
			padding-right: 20px;

			h4 {
				font-size: 20px;
				letter-spacing: 0.03em;
				color: white;
				// margin-bottom: -20px;
				text-shadow: 2px 2px 2px black;
			}
		}

		h4 {
			color: var(--theme-deafult);
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			margin-bottom: 0;
		}

		h2 {
			font-size: 60px;
			font-weight: 700;
			color: $dark-font;
			letter-spacing: 0.1em;
			margin-bottom: -6px;
		}
	}

	&.christmas-banner {
		.contain-banner {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			padding-left: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
			padding-right: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));

			h4 {
				font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
				color: $white;
				font-weight: 700;
				text-transform: uppercase;
				letter-spacing: 0.1em;
				margin-bottom: 0;
			}

			h2 {
				font-size: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));
				font-weight: 700;
				color: $white;
				letter-spacing: 0.1em;
				margin-bottom: -6px;
			}
		}
	}

	&:hover {
		img {
			transform: scale(1.05);
			transition: all 0.5s ease;
		}
	}
}

.banner-furniture {
	padding-left: 15px;
	padding-right: 15px;

	.collection-banner {
		.contain-banner {
			&.banner-3 {
				h2 {
					color: $dark-font;
					margin-bottom: 0;
				}

				h4 {
					color: var(--theme-deafult);
				}
			}
		}
	}
}

.banner-goggles {
	.collection-banner {
		.contain-banner {
			&.banner-3 {
				h2 {
					color: $dark-font;
				}

				h4 {
					color: var(--theme-deafult);
				}
			}
		}
	}
}

.banner-top-cls {
	margin-top: 30px;
}

.banner-6 {
	.collection-banner {
		.contain-banner {
			&.banner-3 {
				h2 {
					color: #222222;
				}
			}
		}
	}
}

.absolute_banner {
	margin-bottom: 22px;

	.collection-banner {
		overflow: unset;

		.absolute-contain {
			position: absolute;
			background-color: #ffffff;
			bottom: -22px;
			left: 50%;
			transform: translateX(-50%);
			padding: 20px;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
			min-width: 85%;
			text-align: center;
			transition: all 0.5s ease;

			h3 {
				color: var(--theme-deafult);
				text-transform: capitalize;
				margin-top: -5px;
				font-weight: 700;
			}

			h4 {
				color: $black;
				margin-bottom: 0;
			}
		}

		&:hover {
			img {
				transform: none;
			}
		}
	}
}

/*=====================
    14.Product-box slider CSS start
==========================*/
.layout7-product {
	.product-box {
		padding: 2px;

		img {
			width: 100%;
		}

		.cart-info {
			right: 7px;
			bottom: 7px;
		}

		.details-product {
			position: absolute;
			transition: all 0.5s ease;
			opacity: 0;
			bottom: 15px;
			left: 15px;

			h6,
			h4 {
				margin-bottom: 0;
			}

			h6 {
				color: $black;
			}
		}

		&:hover {
			.details-product {
				opacity: 1;
				animation: fadeIn 500ms ease-in-out;
				transition: all 0.5s ease;
			}
		}
	}
}

.center-image-width {
	.product-box {
		.img-wrapper {
			.front,
			.back {
				img {
					width: 70%;
					margin: 0 auto;
				}
			}
		}
	}
}

.theme-card {
	position: relative;

	&.center-align {
		display: flex;
		height: 100%;
		align-items: center;

		.offer-slider {
			.product-box2 {
				.media {
					.media-body {
						margin-top: 0;
						padding-left: 10px;
					}
				}
			}
		}
	}

	&.card-border {
		border: 1px solid $round-border;

		h5 {
			padding-top: 20px;
			padding-bottom: 20px;
			padding-left: 50px;
		}

		.slick-prev,
		.slick-next {
			top: -45px;
		}

		.slick-next {
			right: 30px;
		}

		.slick-prev {
			right: 50px;
		}

		.offer-slider {
			padding-top: 0;
			padding-bottom: 20px;

			img {
				padding: 15px 15px 15px 30px;
			}

			.media {
				.media-body {
					a {
						h6 {
							margin-right: 25px;
						}
					}
				}
			}
		}
	}

	h5 {
		font-weight: 700;
		text-transform: uppercase;
		padding-bottom: 10px;
		margin-top: -5px;

		&.title-border {
			border-bottom: 1px solid $round-border;
		}
	}

	.slick-prev,
	.slick-next {
		top: -35px;

		&:before {
			font: normal normal normal 30px/1 $font-awesome;
			color: $dark-font;
			opacity: 0.6;
		}
	}

	.slick-next {
		right: 0;

		&:before {
			content: '\f105';
		}
	}

	.slick-prev {
		left: unset;
		right: 25px;

		&:before {
			content: '\f104';
		}
	}

	.offer-slider {
		img {
			height: 160px;
			padding: 15px 15px 15px 0;
		}

		> div {
			.media {
				&:last-child {
					.media-body {
						margin-top: 15px;
					}

					img {
						margin-bottom: -15px;
					}
				}
			}
		}

		.media {
			.media-body {
				.rating {
					margin-top: 0;

					i {
						padding-right: 5px;

						&:nth-child(-n + 4) {
							color: $star-yellow;
						}

						&:last-child {
							color: $round-border;
						}
					}
				}

				a {
					h6 {
						margin-right: 61px;
						line-height: 17px;
						margin-top: 5px;
						margin-bottom: 0;
					}
				}

				h4 {
					margin-top: 10px;
					font-weight: 700;
					color: $dark-font;
					margin-bottom: 0;
				}
			}
		}
	}
}

.offer-slider {
	.slick-slide {
		line-height: 1;
	}
}

.center-slider {
	.product-box {
		box-shadow: none;

		.img-wrapper {
			img {
				padding: 15px;
				margin: 0 auto;
			}
		}
	}

	.slick-next,
	.slick-prev {
		&:before {
			box-shadow: 0 0 8px 0 $round-border;
		}
	}

	.title2 {
		.title-inner2 {
			margin-bottom: 0;
		}
	}

	&:hover {
		.product-box {
			box-shadow: none;
		}

		.slick-next {
			right: 20px;
			transform: scale(1.8);
		}

		.slick-prev {
			left: 20px;
			transform: scale(1.8);
		}
	}
}

.full-box {
	.theme-card {
		.offer-slider {
			.product-box2 {
				.media {
					img {
						height: 300px;
						transform: scale(1);
						transition: all 0.5s ease;
					}

					.media-body {
						a {
							h6 {
								margin-right: 20px;
							}
						}

						h4 {
							font-size: 20px;
							margin-top: 5px;
						}

						.color-variant {
							li {
								display: inline-block;
								height: 20px;
								width: 20px;
								border-radius: 100%;
								margin-right: 5px;
								margin-top: 15px;
								transition: all 0.1s ease;
								margin-bottom: 0;
								cursor: pointer;
							}
						}
					}
				}

				+ .product-box2 {
					border-top: 1px solid $border-grey;
				}

				&:hover {
					.media {
						img {
							transform: scale(1.03);
							transition: all 0.5s ease;
						}
					}
				}
			}
		}
	}

	.product-box {
		//border-left: 1px solid $border-grey;
		//border-right: 1px solid $border-grey;
	}
}

/*=====================
    15.Modal popup CSS start
==========================*/
.ribbon-1 {
	width: 24px;
	height: 45px;
	background: var(--theme-deafult);
	top: -6px;
	left: 25px;
	position: absolute;
	box-shadow: 4px 5px 15px 2px rgba(90, 90, 90, 0.35);

	&:before {
		height: 0;
		width: 0;
		// border-bottom: 6px solid darken(var(--theme-deafult), 20%);
		border-right: 6px solid transparent;
		right: -6px;
		content: '';
		position: absolute;
	}

	&:after {
		height: 0;
		width: 0;
		border-left: 12px solid var(--theme-deafult);
		border-right: 12px solid var(--theme-deafult);
		border-bottom: 12px solid transparent;
		bottom: -11px;
		left: 0;
		content: '';
		position: absolute;
	}

	span {
		display: block;
		color: #fff;
		height: 12px;
		text-transform: lowercase;
	}
}

.modal-open {
	padding-right: 0 !important;
}

#sizemodal {
	padding-right: 0 !important;
}

.modal-dialog {
	margin: 0;

	.modal-content {
		border: none;

		.modal-body {
			padding: 0;
		}
	}
}

.theme-modal {
	&.modal-dialog {
		.modal-content {
			.modal-body {
				background-image: linear-gradient(
					135deg,
					var(--theme-deafult) 5.77%,
					$white 5.77%,
					$white 25%,
					$grey01 25%,
					$grey01 30.77%,
					$white 30.77%,
					$white 50%,
					var(--theme-deafult) 50%,
					var(--theme-deafult) 55.77%,
					$white 55.77%,
					$white 75%,
					$grey01 75%,
					$grey01 80.77%,
					$white 80.77%,
					$white 100%
				);
				background-size: 36.77px 36.77px;
				background-color: white;

				.modal-bg {
					background-color: $white;
					margin: 15px;

					.close {
						padding-right: 0;
						padding-top: 0;
						position: absolute;
						right: 36px;
						top: 20px;

						&:hover {
							background-color: transparent;
						}

						span {
							color: $black;
							font-size: 26px;
						}
					}

					.age-content {
						h2 {
							border-bottom: 1px dashed $grey;
							text-align: center;
							margin-bottom: 30px;
							padding-bottom: 30px;
							font-weight: 700;
						}

						h4 {
							margin-bottom: 30px;
							text-align: center;
							color: #272727;
							font-size: 18px;
							font-weight: 400;
							text-transform: capitalize;
						}

						form {
							margin: 0 auto;
							text-align: center;

							> div {
								display: flex;
								margin-bottom: 10px;
							}

							.form-control {
								padding-top: 15px;
								padding-bottom: 15px;
								text-align: center;
								font-size: 12px;
								height: 50px;
								border-radius: 0;
								width: 100%;
								letter-spacing: 0.05em;
								height: auto !important;
							}
						}
					}

					.offer-content {
						img {
							margin-bottom: 40px;
						}

						h2 {
							margin-bottom: 30px;
							text-align: center;
							color: $dark-font;
						}

						form {
							.form-group {
								margin: 0 auto;
								text-align: center;

								.form-control {
									padding-top: 15px;
									padding-bottom: 15px;
									text-align: center;
									font-size: 12px;
									max-width: 640px;
									left: 0;
									right: 0;
									margin: 0 auto;
									letter-spacing: 0.05em;
									height: auto !important;
								}

								.btn-solid,
								.btn-outline {
									margin-top: 10px;
									text-align: center;
								}
							}
						}
					}
				}
			}
		}

		.quick-view-modal,
		.demo_modal {
			button {
				&.close {
					position: absolute;
					z-index: 1;
					right: 10px;
					top: 5px;
				}
			}

			.view-detail {
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}

	&.demo-modal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					height: 75vh;
					overflow-y: scroll;
					padding: 25px;
					background-image: none !important;

					&::-webkit-scrollbar {
						width: 5px;
					}

					/* Track */
					&::-webkit-scrollbar-track {
						background: #f1f1f1;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
						background: var(--theme-deafult);
					}

					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
						background: #555;
					}
				}
			}
		}
	}

	&.cart-modal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					.modal-bg {
						padding: 25px;

						&.addtocart {
							.media {
								a {
									img {
										max-width: 150px;
									}
								}

								.media-body {
									padding: 0 15px;

									a {
										h6 {
											font-size: 16px;

											i {
												margin-right: 8px;
												color: #fff;
												background: #19a340;
												font-size: 14px;
												width: 18px;
												height: 18px;
												line-height: 18px;
												border-radius: 50%;
											}
										}
									}

									.buttons {
										a {
											display: inline-block;
											font-size: 16px;
											padding: 6px 10px;
											margin: 0 6px;
										}
									}
								}
							}

							#upsell_product {
								.product-box {
									padding-left: 10px;
									padding-right: 10px;

									.product-detail {
										text-align: center;

										h6 {
											font-weight: 700;
											text-transform: capitalize;

											a {
												color: var(--theme-deafult);
											}
										}
									}
								}
							}
						}
					}

					.upsell_payment {
						padding-top: 15px;
					}

					.product-upsell {
						margin: 20px 0 10px;

						h4 {
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	&.exit-modal {
		.media {
			.media-body {
				h4 {
					font-size: 20px;
					line-height: 1.2;
					color: var(--theme-deafult);
					font-weight: 500;
				}

				h5 {
					font-size: 18px;
					color: $grey;
					margin-bottom: 0;
				}
			}
		}
	}
}

.modal-dialog {
	.modal-content {
		.modal-body {
			.modal-bg {
				padding: 25px;

				&.addtocart {
					.media {
						a {
							img {
								max-width: 150px;
							}
						}

						.media-body {
							padding: 0 15px;

							a {
								h6 {
									font-size: 16px;

									i {
										margin-right: 8px;
										color: #fff;
										background: #19a340;
										font-size: 14px;
										width: 18px;
										height: 18px;
										line-height: 18px;
										border-radius: 50%;
									}
								}
							}

							.buttons {
								a {
									display: inline-block;
									font-size: 16px;
									padding: 6px 10px;
									margin: 0 6px;
								}
							}
						}
					}

					#upsell_product {
						.product-box {
							padding-left: 10px;
							padding-right: 10px;

							.product-detail {
								text-align: center;

								h6 {
									font-weight: 700;
									text-transform: capitalize;

									a {
										color: var(--theme-deafult);
									}
								}
							}
						}
					}
				}
			}

			.upsell_payment {
				padding-top: 15px;
			}

			.product-upsell {
				margin: 20px 0 10px;

				h4 {
					font-weight: 600;
				}
			}
		}
	}
}

.quickview-modal {
	&.modal-dialog {
		.modal-content {
			.modal-body {
				padding: 1rem;
			}
		}
	}
}

/*=====================
    16.look-book demo CSS start
==========================*/
.lookbook-section {
	padding-left: 25px;
	padding-right: 25px;
	padding-bottom: 25px;

	.lookbook-img {
		> div {
			+ div {
				img {
					margin-top: 25px;
				}
			}
		}
	}
}

/*=====================
    17.1.lookbook CSS start
==========================*/
.lookbook {
	.lookbook-block {
		position: relative;

		.lookbook-dot {
			cursor: pointer;
			position: absolute;
			z-index: 2;
			width: 29px;
			height: 29px;
			line-height: 29px;
			border-radius: 50%;
			-webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
			-webkit-transition: all 0.3s ease;
			transition: all 0.3s ease;
			top: 50%;
			left: 12%;
			background-color: $white;

			&.dot2 {
				top: 70%;
				left: 27%;
			}

			&.dot3 {
				top: 28%;
				left: 30%;
			}

			&.dot4 {
				top: 67%;
				left: 8%;
			}

			&.dot5 {
				top: 40%;
				left: 35%;
			}

			&.dot6 {
				top: 80%;
				left: 58%;
			}

			&.dot7 {
				top: 67%;
				left: 45%;
			}

			&.dot8 {
				top: 43%;
				left: 39%;
			}

			&.dot9 {
				top: 32%;
				left: 50%;
			}

			&.dot10 {
				top: 60%;
				left: 50%;
			}

			&.dot11 {
				top: 50%;
				left: 45%;
			}

			&.dot12 {
				top: 75%;
				left: 45%;
			}

			&.dot13 {
				top: 40%;
				left: 45%;
			}

			&.dot14 {
				top: 76%;
				left: 45%;
			}

			&.dot15 {
				top: 50%;
				left: 15%;
			}

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				bottom: 0;
				right: 0;
				content: '';
				background-color: #fff;
				background-color: rgba(255, 255, 255, 0.5);
				zoom: 1;
				background-color: transparent\9;
				z-index: -1;
				border-radius: 50%;
				-webkit-transition: all 0.3s ease;
				transition: all 0.3s ease;
			}

			span {
				text-align: center;
				justify-content: center;
				display: flex;
			}

			.dot-showbox {
				visibility: hidden;
				top: -98px;
				left: 150%;
				position: absolute;
				width: 130px;
				background-color: $white;
				-webkit-box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
				box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);

				img {
					margin-top: 0;
				}

				.dot-info {
					padding: 5px;
					text-align: center;

					h5 {
						margin-bottom: 0;
						text-transform: capitalize;
						line-height: 20px;

						&.title {
							font-weight: 700;
						}
					}

					h6 {
						text-transform: uppercase;
						color: var(--theme-deafult);
						margin-bottom: 0;
					}
				}

				&:before {
					position: absolute;
					content: '';
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-right: 8px solid #ffffff;
					border-bottom: 8px solid transparent;
					top: 41%;
					left: -8px;
				}
			}

			&:hover {
				background-color: var(--theme-deafult);
				-webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
				box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
				z-index: 10;

				.dot-showbox {
					animation: fadeInUp 400ms ease-in-out;
					visibility: visible;
					transition: all 0.5s ease;
				}

				span {
					color: $white;
				}

				&:before {
					-webkit-transform: scale(1.3);
				}
			}
		}
	}
}

/*=====================
    17.2. Beauty Demo css
==========================*/
.beauty-about {
	.about-text {
		p {
			text-align: left;
		}
	}
}

.about-section {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;

	.small-section {
		padding-top: 20px;
	}

	.service {
		.service-block1 {
			img {
				margin-bottom: 10px;
			}

			h5 {
				text-transform: capitalize;
				font-weight: 700;
				margin-bottom: 0;
			}
		}
	}
}

.video-modal {
	.modal-body {
		padding: 0;
	}

	iframe {
		height: 500px;
		width: 100%;
		vertical-align: middle;
		border: none;
	}
}

.video-section {
	.video-img {
		position: relative;
		overflow: hidden;

		img {
			transition: all 0.5s ease;
			transform: scale(1);
		}

		.play-btn {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				height: 60px;
				width: 60px;
				border-radius: 100%;
				background-color: var(--theme-deafult);
				text-align: center;
				justify-content: center;
				align-items: center;
				display: flex;
			}

			i {
				font-size: 35px;
				padding-left: 5px;
				color: $white;
			}
		}

		&:hover {
			img {
				transform: scale(1.03);
				transition: all 0.5s ease;
			}
		}
	}
}

/*=====================
    17.3. Electronics Demo CSS
==========================*/
.layout-8 {
	padding: 0 80px;

	.layout-8-bg {
		background-image: url('#{$assetspath}/electronics/bg.jpg');
		background-size: cover;
		background-attachment: fixed;
		height: 100%;
		width: 100%;
		padding: 0 15px;
		animation: 10s linear 0s normal none infinite animate;

		.layout7-product {
			.product-box {
				.details-product {
					opacity: 1;
				}

				&:hover {
					.details-product {
						animation: none;
					}
				}
			}
		}
	}
}

.white-bg {
	background-color: $white;
}

@keyframes animate {
	from {
		background-position: 0 0;
	}

	to {
		background-position: 0 500px;
	}
}

/*=====================
      17.4.pets demo CSS start
==========================*/
.pets-box {
	.product-box {
		.img-wrapper {
			.cart-info {
				position: relative;
				padding: 10px;
				border: none;
				transform: perspective(500px) rotateX(90deg);
				bottom: 0;
				z-index: 1;

				&:after {
					position: absolute;
					content: '';
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: var(--theme-deafult);
					opacity: 0.3;
					z-index: -1;
				}

				i {
					padding: 5px;
					margin: 0 3px;
					background-color: white;
				}

				a {
					border-left: none;
				}
			}
		}

		.product-detail {
			text-align: center;
			background-color: #fafafa;

			.rating {
				margin-top: 0;
				padding-top: 15px;
			}
		}

		&:hover {
			box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);

			.cart-info {
				transform: perspective(500px) rotateX(0deg);
			}
		}
	}
}

.small-slider {
	.home-slider {
		.home {
			height: 55vh;

			.slider-contain {
				height: 55vh;

				h4,
				h1 {
					color: $black;
				}
			}
		}
	}
}

.banner-6 {
	.collection-banner {
		.contain-banner {
			&.banner-3 {
				h2 {
					color: #222222;
				}
			}
		}
	}
}

.banner-top-cls {
	margin-top: 30px;
}

.j-box {
	.product-box {
		width: 100%;
		display: inline-block;
		border: 1px solid #ddd;
		border-radius: 12px;
		overflow: hidden;

		.cart-info {
			display: flex;
			left: 0;
			background-color: rgba(250, 250, 250, 0.84);
			border-top: 1px solid $round-border;
			border-bottom: 1px solid $round-border;
			bottom: -30px;
			transition: all 0.5s ease;

			a {
				border-left: 1px solid $round-border;

				i {
					padding: 12px;
				}
			}
		}

		.product-detail {
			padding-left: 15px;
			padding-bottom: 15px;
			background-color: #fafafa;

			.rating {
				margin-top: 0;
				padding-top: 15px;
			}
		}

		&:hover {
			.img-wrapper {
				.cart-info {
					opacity: 1;
					transition: all 0.5s ease;
					bottom: 0;

					button {
						animation: none;
					}

					a {
						&:nth-child(2) i {
							animation: none;
						}

						&:nth-child(3) i {
							animation: none;
						}

						&:nth-child(4) i {
							animation: none;
						}
					}
				}
			}
		}
	}
}

.pet-parallax {
	position: relative;

	.pet-decor {
		position: absolute;
		left: 110px;
		bottom: -164px;
	}

	.banner-contain {
		h4,
		h3,
		p {
			color: #212121;
		}

		p {
			max-width: 75%;
			margin: 0 auto;
			line-height: 22px;
			font-size: 16px;
			letter-spacing: 0.04em;
		}

		h4 {
			letter-spacing: 0.05em;
			padding-top: 0 !important;
			margin-bottom: 0;
			line-height: 1.3;
			margin-top: -7px;
		}

		h3 {
			margin: 15px 0;
			font-size: 48px;
		}
	}

	.full-banner {
		padding-top: 130px;
		padding-bottom: 130px;
	}
}

footer {
	&.pet-layout-footer {
		.white-layout {
			background-color: #f5e1d0;
		}

		.footer-brand-logo {
			margin-bottom: 20px;

			.media {
				margin: 0 auto;
			}
		}

		.sub-footer {
			&.black-subfooter {
				background-color: var(--theme-deafult);
			}

			p {
				padding: 15px 0;
				color: $white;
			}
		}

		.social-white {
			display: block;
			margin-top: 40px;
			margin-bottom: 30px;
		}
	}

	&.footer-classic {
		.upper-footer {
			background-color: var(--theme-deafult);

			.small-section {
				padding: 35px 0;
			}
		}

		&.footer-light {
			.subscribe {
				border-right: none;

				h4 {
					color: $white;
				}

				p {
					color: $white;
				}
			}
		}

		.subscribe-form {
		}

		.sub-footer {
			background-color: #494949;

			p {
				color: $white;
				padding: 10px 0;
			}
		}
	}

	&.footer_digital {
		.white-bg {
			background-color: white;
		}
	}

	&.footer-christmas {
		background-image: url('/assets/images/christmas/footer-bg.jpg');
		background-size: cover;
		background-position: center;
		display: block;

		.footer-title {
			h4 {
				color: $white;
			}
		}

		.light-layout {
			background: transparent;
		}

		.sub-footer {
			background-color: transparent;
		}

		p {
			color: white;
		}

		.subscribe {
			text-align: left;

			h4 {
				color: $white;
			}
		}

		.footer-theme {
			.sub-title {
				h4 {
					color: $white;
				}

				li {
					color: $white;

					a {
						color: $white;
					}

					&:hover {
						a {
							&:before {
								width: 100%;
								height: 2px;
								transition: 0.5s ease;
							}
						}
					}
				}

				.contact-list {
					li {
						a {
							&:hover {
								color: var(--theme-deafult);
								transition: all 0.5s ease;
							}
						}

						&:hover {
							color: lighten($black, 60%);
							transition: all 0.3s ease;
						}
					}
				}
			}
		}

		.footer-social,
		.social-white {
			i {
				color: $white;
				transition: 0.5s ease;

				&:hover {
					color: var(--theme-deafult) !important;
					transition: 0.5s ease;
				}
			}
		}
	}
}

.light-layout-imp {
	&.footer-white {
		background-color: #f9f9f9;

		.light-layout {
			background-color: transparent;
		}

		.sub-footer {
			.row {
				border-top: none;
			}
		}
	}
}

.form_search {
	height: 45px;
	box-shadow: 0 0 0 1px #e2e2e2;
	overflow: hidden;
	position: relative;
	width: 525px;
	background-color: #f5f5f5;
	border-radius: 25px;

	input {
		width: 100%;
		height: 45px;
		font-size: 16px;
		color: darkgrey;
		border: none;
		outline: none;
		background: none;
		padding: 0 10px;
	}

	button {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 40px;
		height: 35px;
		padding: 0;
		border: none;
		outline: none;
		cursor: pointer;
		background-color: transparent;
		border-radius: 5px;

		i {
			font-size: 18px;
			color: var(--theme-deafult);
		}
	}
}

.blog {
	&.blog_box {
		.blog-details {
			text-align: left;
			padding: 0;

			p {
				font-size: 14px;
				text-transform: none;
				color: $font-color;
				font-weight: 400;
				margin-bottom: 8px;
				margin-top: 3px;
			}

			a {
				p {
					color: $grey7;
					text-transform: capitalize;
					font-weight: 700;
					margin-bottom: 0;
					margin-top: 10px;
				}
			}

			.read-cls {
				text-transform: capitalize;
				font-weight: 700;
				color: $black;
			}
		}
	}
}

/*=====================
   17.5. Metro layout  CSS start
==========================*/

.header-metro {
	position: absolute;
	width: 100%;

	.top-header {
		background-color: transparent;
		z-index: 9;
		position: relative;
	}

	.metro {
		background-color: white;
		z-index: 9;
		position: relative;

		.absolute-logo {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}
	}
}

.absolute-logo {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.metro-section {
	.isotopeSelector {
		&:hover {
			img {
				//transform: scale(1) rotate(0deg);
				//transition: all 0.5s ease;
			}
		}
	}

	.product-box {
		.product-detail {
			opacity: 0;
			position: absolute;
			background-color: $white;
			padding: 10px;
			transition: all 0.5s ease;
			width: 65%;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: -10px;
			text-align: center;

			h6 {
				color: #525252;
				padding-top: 0;
				margin-top: -2px;
			}

			h4 {
				font-weight: 400;
				color: $black;
			}

			.rating {
				margin-top: 0;
			}
		}

		.cart-wrap {
			top: 7px;
			right: 7px;
			opacity: 1;

			button {
				border: 1px solid $white;
				border-radius: 100%;
				background-color: rgba($white, 0.8);
				opacity: 1;

				i {
					padding: 7px;
					color: $font-color;
				}
			}

			a {
				i {
					border-radius: 100%;
					border: 1px solid $white;
					margin: 5px 0;
					background-color: rgba($white, 0.8);
					padding: 7px;
					color: $font-color;
					opacity: 0;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		&:hover {
			.product-detail {
				opacity: 1;
				transition: all 0.5s ease;
				bottom: 15px;
			}

			.cart-wrap {
				button {
					animation: none;
				}

				a {
					i {
						opacity: 1;
					}

					&:nth-child(2) {
						i {
							animation: fadeInDown 500ms ease-in-out;
						}
					}

					&:nth-child(3) {
						i {
							animation: fadeInDown 700ms ease-in-out;
						}
					}

					&:nth-child(4) {
						i {
							animation: fadeInDown 1000ms ease-in-out;
						}
					}
				}
			}
		}
	}
}

/*=====================
   17.6. GYM layout  CSS start
==========================*/
.gym-slider {
	.home-slider {
		.home {
			height: 85vh;

			.slider-contain {
				height: 85vh;

				h4,
				h1 {
					color: $white;
				}
			}
		}
	}
}

.gym-parallax {
	.title-borderless {
		color: white;
	}

	.title1 {
		h2 {
			color: white;
		}
	}

	.product-para {
		p {
			color: white;
		}
	}

	.instagram-box {
		.overlay {
			background: linear-gradient(-180deg, var(--gradient1) 0%, var(--gradient2) 99%);
		}
	}
}

.gym-product {
	text-align: center;

	.product-box {
		background-color: white;
		padding: 15px;
		box-shadow: 0 0 8px 0 #ddd;
		margin: 5px 0;
		transition: all 0.5s ease;

		.img-wrapper {
			position: unset;

			.cart-info {
				z-index: 1;
				display: flex;
				left: 0;
				height: 100%;
				align-items: center;
				bottom: 0;
				top: 0;

				i {
					background: linear-gradient(180deg, var(--gradient1) 0%, var(--gradient2) 99%);
					color: rgba($white, 0.6);
					border-radius: 100%;
					margin: 0 5px;
					transition: all 0.5s ease;
				}

				button,
				a {
					&:hover {
						i {
							color: white;
						}
					}
				}
			}
		}

		.br {
			&.br-default {
				display: flex;
				justify-content: center;
			}
		}

		&:hover {
			transform: scale(1.02);
			transition: all 0.5s ease;

			.img-wrapper {
				.cart-info {
					opacity: 1;
					transition: all 0.5s ease;

					button {
						animation: fadeInUp 300ms ease-in-out;
					}

					a {
						&:nth-child(2) i {
							animation: fadeInUp 500ms ease-in-out;
						}

						&:nth-child(3) i {
							animation: fadeInUp 700ms ease-in-out;
						}

						&:nth-child(4) i {
							animation: fadeInUp 1000ms ease-in-out;
						}
					}
				}
			}
		}
	}

	&.partition-cls {
		.row {
			> div {
				&:nth-child(-n + 4) {
					margin-bottom: 30px;
				}
			}
		}
	}
}

.gym-blog {
	.blog-details {
		text-align: left;
		padding-left: 0;

		p {
			margin-bottom: 3px;
		}
	}

	hr.style1 {
		display: none;
	}
}

.gym-banner {
	.collection-banner {
		.contain-banner {
			h4,
			h2 {
				color: $white;
			}
		}
	}
}

.bg-img-gym {
	background-image: url('#{$assetspath}/gym/bg.jpg');

	&.overlay-cls {
		background-blend-mode: overlay;
		background-color: rgba($black, 0.85);
	}

	.dark-layout {
		background-color: transparent;
	}

	.footer-theme2 {
		p {
			color: $white;
		}

		.contact-details {
			li {
				color: $white;

				a {
					color: $white;
				}
			}
		}

		.footer-link,
		.footer-link-b {
			a {
				color: #ebebeb;
			}
		}
	}

	.sub-footer {
		&.darker-subfooter {
			background-color: black;

			p {
				color: $white;
			}
		}
	}
}

.tap-top {
	&.gradient-top {
		background: linear-gradient(180deg, var(--gradient1) 0%, var(--gradient2) 99%);
	}
}

.banner-furniture {
	padding-left: 15px;
	padding-right: 15px;

	.collection-banner {
		.contain-banner {
			&.banner-3 {
				h2 {
					color: $dark-font;
					margin-bottom: 0;
				}

				h4 {
					color: var(--theme-deafult);
				}
			}
		}
	}
}

.gym-slider {
	.home-slider {
		.home {
			height: 85vh;

			.slider-contain {
				height: 85vh;

				h4,
				h1 {
					color: $white;
				}
			}
		}
	}
}

.main-nav-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.header-gym {
	position: absolute;
	width: 100%;

	.main-menu {
		background-color: rgba($black, 0.8);
		padding: 0 15px;
		z-index: 1;

		.menu-left {
			.navbar {
				padding: 20px 45px 20px 0;

				i {
					color: white;
				}
			}
		}

		.brand-logo {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		.onhover-div {
			.show-div {
				top: 70px;
			}
		}
	}

	.sidebar-back {
		i {
			color: black;
		}
	}

	.main-navbar {
		.nav-menu {
			> li {
				> a {
					padding-top: 20px;
					padding-bottom: 20px;
					color: $white;

					&:hover,
					&:active,
					&:focus,
					&.highlighted {
						padding-top: 20px;
						padding-bottom: 20px;
						color: $white;
					}
				}
			}
		}

		li {
			//.lable-nav {
			//  top:0;
			//  background: #373B44;  /* fallback for old browsers */
			//  background: -webkit-linear-gradient(to right, #4286f4, #373B44);  /* Chrome 10-25, Safari 5.1-6 */
			//  background: linear-gradient(to right, #4286f4, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
			//  &:before {
			//    border-top: 5px solid #4286f4;
			//  }
			//}
		}
	}

	.onhover-div {
		padding-top: 20px;
		padding-bottom: 20px;

		> div {
			img {
				filter: brightness(54);
			}
		}
	}

	.top-header {
		z-index: 2;
		position: relative;
		background: transparent;

		&.zindex-class {
			z-index: 1;
		}

		.header-contact {
			li {
				color: #fff;

				i {
					color: #fff;
				}
			}
		}

		.header-dropdown {
			li {
				color: $white;

				a {
					color: $white;

					i {
						color: $white;
						padding-right: 8px;
					}
				}
			}
		}

		&.blue-layout {
			border-bottom: 1px solid #223b7d;
		}
	}
}

.btn-gradient {
	background: var(--theme-deafult);
	background: linear-gradient(180deg, var(--gradient1) 0%, var(--gradient2) 99%);
	transition: background 300ms ease-in-out;
	background-size: 300% 100%;
	border: none;
	&:hover {
		background: linear-gradient(-180deg, var(--gradient2) 0%, var(--gradient1) 99%);
		transition: background 300ms ease-in-out;
		color: white;
	}
}

/*=====================
  17.7  extra layout CSS start
==========================*/
.left-header {
	.top-header {
		padding-left: 300px;
		background-color: $white;

		.header-dropdown {
			.mobile-wishlist,
			.mobile-account {
				img {
					display: block;
				}

				i {
					display: none;
				}
			}
		}
	}

	.onhover-div {
		padding-top: 15px;
		padding-bottom: 15px;

		> div {
			img {
				width: 20px;
				height: auto;
			}
		}

		.show-div {
			top: 56px;
		}
	}

	.sidenav {
		left: 0;
		box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);

		.sidebar-back {
			display: none;
		}

		nav {
			background-color: $white;
		}

		.pixelstrap {
			> li {
				> a {
					padding: 10px 0 20px 0;

					&:hover,
					&:focus {
						padding: 10px 0 20px 0;
						background-color: $white;
					}
				}

				.lable-nav {
					top: -12px;
					background-color: $white-dark;

					&:before {
						border-top: 5px solid $color-red;
					}

					&.grey-lable {
						background-color: #4d6171;

						&:before {
							border-top: 5px solid #4d6171;
						}
					}
				}
			}
		}

		.left-sidebar_search {
			width: 100%;
			background-color: $white;

			input {
				height: 55px;
				width: 95%;
				border: none;
				padding-left: 15px;
				background-color: transparent;
			}

			.btn-search {
				position: absolute;
				right: 10px;
				top: 20px;
				border: none;
				background-color: transparent;
			}
		}

		.left-sidebar_center {
			padding: 40px 0 30px 30px;
			background-color: $white;
		}

		.bottom-section {
			padding: 70px 25px;

			.icon_settings {
				li {
					display: inline-block;
				}

				.shopping-cart {
					position: relative;

					.shopping-count {
						width: 18px;
						height: 18px;
						background-color: $color-red;
						color: white;
						position: absolute;
						border-radius: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
						top: -7px;
						right: 7px;
					}
				}
			}

			ul {
				li {
					display: inline-block;
					padding: 8px 15px 8px 0;

					a {
						font-size: 16px;
						text-transform: capitalize;
						color: $dark-font;

						i {
							font-size: 18px;
							padding-right: 5px;
							color: $dark-font;
						}
					}
				}
			}

			.leftside_setting {
				.language,
				.currency {
					display: flex;

					h5 {
						margin-bottom: 0;
						text-transform: capitalize;
						color: #999;
						margin-top: 0;
					}

					select {
						margin-left: 10px;
						border: none;
						outline: none;
						text-transform: capitalize;
						color: $dark-font;
					}
				}

				h5 {
					margin-top: 10px;
				}
			}
		}

		.leftside_social {
			position: relative;
			width: 100%;
			padding: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px dashed $round-border;
			background-color: white;

			.footer-social {
				margin-top: 0;

				i {
					font-size: 18px;
				}

				li {
					padding: 10px;
				}
			}

			.call_us {
				h5 {
					text-align: center;

					span {
						display: block;
					}
				}
			}
		}
	}

	.main-menu {
		justify-content: flex-end;

		.brand-logo {
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 1px dashed $round-border;
		}

		.menu-right {
			.header-dropdown {
				> li {
					img {
						width: 20px;
						height: auto;
					}
				}
			}

			.icon-nav {
				.onhover-div {
					> img {
						width: 20px;
						height: auto;
					}
				}
			}
		}

		.menu-left {
			.navbar {
				display: none;
			}

			.mobile-logo {
				display: none;
			}
		}
	}

	.search-overlay {
		padding-left: 300px;
	}
}

.left-sidebar_space {
	padding-left: 300px;
}

.header-6 {
	.top-header {
		background-color: var(--theme-deafult);

		.header-contact {
			li {
				color: $white;

				i {
					color: $white;
				}
			}
		}

		.header-dropdown {
			li {
				color: $white;

				a {
					color: $white;

					i {
						color: white;
					}
				}
			}
		}
	}

	.mobile-search {
		display: none;
	}
}

.form_search {
	height: 45px;
	box-shadow: 0 0 0 1px #e2e2e2;
	overflow: hidden;
	position: relative;
	width: 525px;
	background-color: #f5f5f5;
	border-radius: 25px;

	input {
		width: 100%;
		height: 45px;
		font-size: 16px;
		color: darkgrey;
		border: none;
		outline: none;
		background: none;
		padding: 0 10px;
	}

	button {
		position: absolute;
		top: 5px;
		right: 5px;
		width: 40px;
		height: 35px;
		padding: 0;
		border: none;
		outline: none;
		cursor: pointer;
		background-color: transparent;
		border-radius: 5px;

		i {
			font-size: 18px;
			color: var(--theme-deafult);
		}
	}
}

.header-tools {
	position: absolute;
	width: 100%;
	top: 35px;
	z-index: 1;
	background-color: transparent;

	.main-navbar {
		.nav-menu {
			> li {
				> a {
					padding-top: 25px;
					padding-bottom: 25px;
				}
			}
		}
	}

	.container {
		padding-left: 30px;
		padding-right: 30px;
	}

	.top-header {
		background-color: transparent;

		.container {
			background-color: var(--theme-deafult);
		}

		.header-contact {
			padding: 10px 0;

			li {
				color: $white;
			}
		}

		.header-dropdown {
			li {
				color: $white;
				padding: 10px 25px;
				padding-right: 0;

				a {
					color: $white;

					i {
						color: $white;
					}
				}

				&:hover {
					i {
						color: $white;
					}
				}
			}
		}
	}

	.logo-menu-part {
		> .container {
			background-color: #fff;
			border-bottom: 2px solid var(--theme-deafult);
		}
	}

	.main-menu {
		.menu-left {
			.navbar {
				padding: 25px 45px 25px 0;
			}
		}

		.brand-logo {
			padding-top: 25px;
			padding-bottom: 25px;
		}

		.icon-nav {
			ul {
				li {
					padding-left: 20px;
				}
			}
		}
	}

	.sm-horizontal {
		> li {
			> a {
				padding-top: 25px;
				padding-bottom: 25px;

				&:hover,
				&:active,
				&:focus,
				&.highlighted {
					padding-top: 25px;
					padding-bottom: 25px;
				}
			}
		}

		li {
			.lable-nav {
				top: 5px;
			}
		}
	}

	.onhover-div {
		padding-top: 25px;
		padding-bottom: 25px;
	}

	.cart_qty_cls {
		top: 16%;
		right: -14px;
	}
}

.tools-bg {
	background-color: #f5f5f5;

	section {
		background-color: #f5f5f5;
	}
}

.tools-parallax {
	&.small-slider {
		.home-slider {
			.home {
				.slider-contain {
					h4,
					h1 {
						color: $white;
					}
				}
			}
		}
	}
}

.tools-parallax-product {
	&.full-banner {
		background-image: url('/assets/images/parallax/21.jpg');
		background-size: cover;
		background-position: center center;
		display: block;
		padding-top: 110px;
		padding-bottom: 110px;
	}

	.tools-description {
		h3 {
			color: $white;
			text-transform: uppercase;
			margin-top: -5px;
		}

		.tools-form {
			.search-box {
				width: 75%;
				margin: 20px 0;

				select {
					text-transform: capitalize;
				}
			}

			.btn-find {
				width: 75%;
				padding: 7px 29px;
				background-size: 1000px;
			}
		}
	}

	.tools-grey {
		.slick-prev,
		.slick-next {
			transform: scale(1.5);
		}
	}
}

.tools_slider {
	.home-slider {
		.home {
			.container {
				position: relative;
			}

			.slider-contain {
				margin-top: 70px;
				margin-left: 150px;
			}

			.tools-parts {
				position: absolute;
				top: 0;
				right: 10%;
				display: flex;
				align-items: center;
				height: 75vh;
				margin-top: 50px;
			}

			.tools-parts1 {
				position: absolute;
				top: 0;
				right: 20%;
				display: flex;
				align-items: center;
				height: 75vh;
				margin-top: 50px;
			}
		}

		.slider-contain {
			margin-top: 70px;
			margin-left: 150px;

			h4,
			h1 {
				color: $white;
			}
		}
	}
}

.tools-grey {
	.product-m {
		.slick-slide {
			.img-wrapper {
				margin: 0;
			}
		}
	}

	.product-box {
		width: 100%;
		display: inline-block;
		overflow: hidden;
		background-color: white;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
		margin: 0;

		.img-wrapper {
			.lable-block {
				transition: all 0.5s ease;
			}
		}

		.cart-info {
			display: flex;
			left: 0;
			bottom: -30px;
			transition: all 0.5s ease;

			.mobile-quick-view {
				display: none;
			}

			a {
				background-color: #eef0f1;
				border-radius: 5px;

				i {
					color: $grey2;
				}

				&:hover {
					background-color: var(--theme-deafult);
					transition: all 0.5s ease;

					i {
						color: $white;
						transition: all 0.5s ease;
					}
				}
			}

			button {
				width: 55%;
				background-color: #eef0f1;
				color: $grey2;
				border-radius: 5px;
				margin: 0 5px;
				transition: all 0.5s ease;

				i {
					display: none;
				}

				&:hover {
					background-color: var(--theme-deafult);
					color: $white;
					transition: all 0.5s ease;
				}
			}
		}

		.quick-view-part {
			position: absolute;
			top: 50%;
			left: 50%;
			margin-top: -20px;
			background-color: var(--theme-deafult);
			border-radius: 100%;
			width: 40px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			transform: translate(-50%, -50%);
			opacity: 0;
			transition: all 0.5s ease;

			i {
				font-size: 18px;
				color: $white;
			}
		}

		.product-info {
			padding-left: 15px;
			padding-bottom: 15px;
			background-color: $white;
			border-top: 1px solid rgba($round-border, 0.2);

			.rating {
				margin-top: 0;
				padding-top: 15px;
			}
		}

		.br {
			&.br-default {
				display: flex;
				justify-content: center;
			}
		}

		&:hover {
			.img-wrapper {
				.front {
					img {
						opacity: 0.05;
						transition: all 0.5s ease;
					}
				}

				.cart-info {
					opacity: 1;
					transition: all 0.5s ease;
					bottom: 10px;

					button {
						animation: none;
					}

					a {
						&:nth-child(2) i {
							animation: none;
						}

						&:nth-child(3) i {
							animation: none;
						}

						&:nth-child(4) i {
							animation: none;
						}
					}
				}

				.lable-block {
					display: none;
					transition: all 0.5s ease;
				}
			}

			.quick-view-part {
				opacity: 1;
				transition: all 0.5s ease;
			}

			.ribbon {
				display: none;
				transition: all 0.5s ease;
			}
		}
	}

	.ribbon {
		position: absolute;
		left: -5px;
		top: -5px;
		z-index: 1;
		overflow: hidden;
		width: 75px;
		height: 75px;
		text-align: right;
		transition: all 0.5s ease;

		span {
			font-size: 10px;
			font-weight: bold;
			color: #fff;
			text-transform: uppercase;
			text-align: center;
			line-height: 20px;
			transform: rotate(-45deg);
			width: 100px;
			display: block;
			background: var(--theme-deafult);
			box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
			position: absolute;
			top: 19px;
			left: -21px;

			&:before {
				content: '';
				position: absolute;
				left: 0px;
				top: 100%;
				z-index: -1;
				border-left: 3px solid var(--theme-deafult);
				border-right: 3px solid transparent;
				border-bottom: 3px solid transparent;
				border-top: 3px solid var(--theme-deafult);
			}

			&:after {
				content: '';
				position: absolute;
				right: 0px;
				top: 100%;
				z-index: -1;
				border-left: 3px solid transparent;
				border-right: 3px solid var(--theme-deafult);
				border-bottom: 3px solid transparent;
				border-top: 3px solid var(--theme-deafult);
			}
		}
	}
}

.category-tools {
	.category-m {
		.category-wrapper {
			background-color: white;
			border: none;
			box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
		}
	}
}

.banner-tools {
	margin-top: 30px;
	border: 1px solid $round-border;
	overflow: hidden;

	img {
		transition: all 0.5s ease;
	}

	&:hover {
		img {
			transform: scale(1.05);
			transition: all 0.5s ease;
		}
	}
}

.tools-brand {
	.row {
		background-color: white;
		padding: 25px 0;
		box-shadow: 0 0 8px 0 #ddd;
		margin: 0;
	}
}

.sticky-footer {
	background-image: url('#{$assetspath}/tools/footer.jpg');

	.darken-layout {
		background-color: transparent !important;

		p {
			color: $grey8;
		}

		.sub-title {
			li {
				a {
					color: $grey8;
				}
			}

			.contact-list {
				li,
				i {
					color: $grey8;
				}
			}
		}

		.footer-social {
			i {
				color: $grey8;
			}
		}
	}

	.dark-subfooter {
		background-color: #141111 !important;

		p {
			color: $grey8 !important;
		}
	}
}

.green-gradient {
	.top-header {
		background: var(--theme-deafult);
		background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));

		.header-contact {
			padding: 10px 0;

			li {
				color: $white;

				i {
					color: $white;
				}
			}
		}

		.header-dropdown {
			li {
				color: $white;
				padding: 10px 25px;

				a {
					color: $white;

					i {
						color: $white;
					}
				}
			}
		}
	}

	.pixelstrap {
		li {
			.lable-nav {
				background: var(--theme-deafult);
				background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));
			}
		}
	}
}

.service_slide {
	position: relative;

	.home-slider {
		.home {
			background-blend-mode: overlay;
			background-color: rgba($black, 0.4);
		}

		.slider-contain {
			height: 60vh;
		}
	}

	.service-home {
		position: absolute;
		width: 100%;
		bottom: 0;

		.service-block1 {
			background-color: rgba($white, 0.9);
			padding: 25px 0;

			h4 {
				margin-bottom: 0;
			}

			p {
				text-transform: capitalize;
			}

			svg {
				margin-bottom: 5px;
				width: 50px;
				height: 50px;
			}

			&:nth-child(even) {
				background-color: rgba($white, 0.85);
			}
		}
	}
}

.tab-left {
	.theme-tab {
		display: flex;

		.left-side {
			width: 30%;

			.left-tab-title {
				margin-bottom: 38px;

				h4 {
					color: #dafe69;
					margin-top: -3px;
				}

				h3 {
					color: $white;
				}
			}

			.tab-title {
				margin-right: 100px;
				text-align: left;

				a {
					text-transform: capitalize;
				}
			}

			li {
				width: 100%;
				padding: 7px 0;
				border-bottom: 1px solid #606060;

				a {
					color: $white;
				}

				&:first-child {
					border-top: 1px solid #606060;
				}

				&.current {
					a {
						color: #dafe69;
					}
				}
			}
		}

		.tab-content-cls {
			width: 70%;
		}
	}
}

.product_section {
	background-image: url('#{$assetspath}/marijuana/leaf-bg.jpg');
}

.border-box {
	&.tools-grey {
		.ribbon {
			span {
				background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));
			}
		}
		.product-box {
			border-radius: 10px;
			box-shadow: none;
			border: 1px solid $round-border;
			.cart-info {
				a {
					&:hover {
						background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));
					}
				}
				button {
					&:hover {
						background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));
					}
				}
			}
			.quick-view-part {
				background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));
			}
		}
	}
}

.detail-cannabis {
	.detail_section {
		text-align: center;

		> div {
			box-shadow: -1px 2px 5px 1px #ececec;
			padding: 45px 25px;
			background-color: white;
		}

		p {
			margin-bottom: 0;
			line-height: 24px;
			letter-spacing: 0.05em;
		}

		h4 {
			font-weight: 700;
		}

		svg {
			width: 50px;
			margin-bottom: 15px;
			fill: var(--theme-deafult);
		}
	}
}

footer {
	&.footer-5 {
		background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));

		.dark-layout {
			background-color: transparent;
		}

		.sub-footer {
			background-color: transparent;

			> .container {
				border-top: 1px solid $white;
			}

			p {
				color: $white;
			}
		}

		.footer-theme2 {
			p {
				color: $white;
			}

			.contact-details {
				li {
					color: $white;

					a {
						color: $white;
					}
				}
			}

			.subscribe-block {
				border-left: 1px solid $white;
				border-right: 1px solid $white;
			}
		}
	}
}

.tap-top {
	&.green-top {
		background-image: linear-gradient(to right, var(--gradient1), var(--gradient2));
		border: 1px solid rgba($white, 0.5);
	}
}
.quickviewm {
	.modal-dialog {
		.modal-content {
			.modal-body {
				background-image: none;
			}
		}
	}
}

#toast-container > div {
	opacity: 1;
}

.toast {
	font-size: initial !important;
	border: initial !important;
	backdrop-filter: blur(0) !important;
}

.toast-success {
	background-color: #51a351 !important;
}

.toast-error {
	background-color: #bd362f !important;
}

.toast-info {
	background-color: #2f96b4 !important;
}

.toast-warning {
	background-color: #f89406 !important;
}

/*=====================
    18.Flying Cart CSS start
==========================*/
.addcart_btm_popup {
	position: fixed;
	right: 0;
	width: 42px;
	height: 41px;
	color: #000000;
	cursor: pointer;
	transition: all ease-in 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	text-decoration: none;
	background: #ffffff;
	top: 33%;
	border: 1px solid white;
	-webkit-box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
	box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);

	.fixed_cart {
		position: relative;
		z-index: 99;
		display: block;
		float: right;
		font-size: 18px;
		text-decoration: none;
		padding-left: 8px;
		white-space: nowrap;

		.cart_qty_cls {
			font-weight: 400;
		}
	}
}

/*=====================
    19.footer CSS start
==========================*/
footer {
	position: relative;

	&.footer-black {
		.light-layout {
			background-color: #23292c;
		}

		.upside {
			background-color: transparent;

			.small-section {
				background-color: rgba($white, 0.92);
				padding: 20px 30px;
				margin-bottom: -45px;
				z-index: 1;
				position: relative;
				background-image: url('/assets/images/metro.jpg');
				background-blend-mode: overlay;

				.center-thing {
					display: flex;
					justify-content: center;
					height: 100%;

					.subscribe {
						h4 {
							margin-bottom: 0;
							color: black;
						}
					}

					.black-btn {
						background-image: linear-gradient(30deg, #23292c 50%, transparent 50%);
						border: 2px solid #23292c;
					}
				}
			}
		}

		.below-section {
			padding-top: 115px;
		}

		.sub-footer {
			background-color: #191e21;
		}

		.footer-theme {
			.sub-title {
				h4 {
					color: $white;
				}
			}
		}

		.subscribe {
			h4,
			p {
				color: $black;
			}
		}

		.footer-social {
			i {
				color: $grey;
			}
		}

		&.footer-light {
			.subscribe {
				border-right: 1px solid rgba($black, 0.8);
			}
		}
	}

	.footer-lable {
		position: absolute;
		width: 50px;
		height: 50px;
		background-color: var(--theme-deafult);
		top: -10px;
		display: flex;
		align-items: center;
		justify-content: center;

		&:after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			// border-bottom: 10px solid darken(var(--theme-deafult), 30%);
			border-right: 10px solid transparent;
			top: 0;
			right: -10px;
		}

		i {
			color: white;
			font-size: 25px;
		}
	}

	.footer-theme {
		.footer-mobile-title {
			display: none;
		}
	}

	.darken-layout {
		p {
			color: $grey-font;
		}

		.sub-title {
			.footer-title {
				&.active {
					.according-menu {
						font: normal normal normal 14px/1 $font-awesome;

						&.before {
							content: '\f068';
						}
					}
				}
			}

			h4 {
				color: $white;
			}

			li {
				a {
					color: $grey-font;
				}
			}

			.contact-list {
				i {
					color: $grey-font;
				}

				li {
					color: $grey-font;
				}
			}
		}

		.footer-social {
			i {
				color: $grey-font;
			}
		}
	}

	p {
		line-height: 32px;
		letter-spacing: 0.03em;
		margin-bottom: 0;
	}
}

.footer-white {
	.light-layout {
		background-color: white;
	}

	.sub-footer {
		.row {
			border-top: 1px solid $round-border;
		}
	}
}

.footer-light {
	.subscribe {
		display: flex;
		height: 100%;
		align-items: center;
		border-right: 1px solid $round-border;
		text-align: left;

		p {
			line-height: 1;
		}

		h4 {
			margin-bottom: 10px;
		}
	}

	.subscribe-form {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: flex-end;
	}
}

.subscribe {
	text-align: center;

	h4 {
		color: $dark-font;
		text-transform: uppercase;
		font-weight: 700;
		display: block;
		margin-bottom: 15px;

		i {
			color: var(--theme-deafult);
			padding-right: 5px;
			font-size: 23px;
		}
	}
}

.subscribe-form {
	display: inline-flex;

	.form-group {
		margin-bottom: 0;
	}

	.form-control {
		padding: 13px;
		width: 345px;
		border-radius: 0;
		border: 1px solid $white-dark;
		height: auto;

		&.name-cls {
			width: 265px;
		}
	}

	&.classic-form {
		.form-control {
			border-radius: 0;
			border: none;
			background-color: $form-bg;
			margin-left: 15px;
		}

		.btn-solid {
			margin-bottom: 0 !important;
		}
	}
}

.footer-social,
.social-white {
	margin-top: 40px;
	display: flex;
	align-items: center;

	li {
		display: inline-block;
		padding-right: 45px;
	}

	i {
		font-size: 20px;
		color: $white;
		transition: 0.5s ease;

		&:hover {
			color: var(--theme-deafult) !important;
			transition: 0.5s ease;
		}
	}
}

.social-white {
	li {
		padding-left: 22px;
		padding-right: 22px;
	}
}

.footer-logo {
	&.text-center {
		img {
			margin: 0 auto;
		}
	}
}

.footer-theme {
	.footer-logo {
		margin-bottom: 18px;
	}

	.sub-title {
		h4 {
			color: $dark-font;
			margin-bottom: 25px;
			text-transform: uppercase;
			font-weight: 700;
		}

		li {
			position: relative;
			display: inherit;
			padding-top: 13px;
			text-transform: capitalize;
			font-size: 14px;
			color: $grey;

			a {
				position: relative;
				color: $grey;

				&:before {
					position: absolute;
					top: 20px;
					content: '';
					height: 2px;
					width: 0;
					background-color: var(--theme-deafult);
					transition: 0.5s ease;
				}
			}

			&:hover {
				a {
					&:before {
						width: 100%;
						height: 2px;
						transition: 0.5s ease;
					}
				}
			}
		}

		.contact-list {
			i {
				position: absolute;
				left: 0;
				top: 21px;
			}

			li {
				position: relative;
				padding-left: 25px;
				line-height: 28px;

				a {
					&:hover {
						color: var(--theme-deafult);
						transition: all 0.5s ease;
					}
				}

				&:hover {
					color: lighten($black, 60%);
					transition: all 0.3s ease;

					a {
						&:before {
							display: none;
						}
					}
				}

				&:last-child {
					margin-bottom: -5px;
				}
			}
		}
	}
}

.sub-footer {
	background-color: $white;

	&.dark-subfooter {
		background-color: $dark-footer;

		p {
			color: $grey-font;
		}
	}

	&.darker-subfooter {
		background-color: $darker-footer;

		p {
			color: $grey-shade;
		}
	}

	&.black-subfooter {
		background-color: $dark-font;

		p {
			color: $grey;
		}
	}

	p {
		color: $grey;
		margin-bottom: 0;
		padding: 20px 0;
	}

	.payment-card-bottom {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		li {
			padding-left: 7px;
			padding-right: 7px;
			display: inline-block;
		}
	}
}

.footer-theme2 {
	text-align: center;

	.footer-mobile-title {
		display: none !important;
	}

	.contact-details {
		li {
			a {
				color: $grey-shade;

				&:hover {
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}
			}
		}
	}

	&.footer-border {
		border: 20px solid $border-grey;
	}

	.footer-logo {
		margin-bottom: 18px;
	}

	p {
		line-height: 32px;
		letter-spacing: 0.03em;
		margin-bottom: 0;
		color: $grey-shade;
	}

	h4 {
		color: $white;
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 0;
		padding-bottom: 25px;
	}

	.contact-details {
		li {
			display: block;
			color: $grey-shade;
			padding: 0 50px;
			line-height: 2.2;

			&:last-child {
				margin-bottom: -8px;
			}
		}
	}

	.subscribe-block {
		border-left: 1px solid $border-color;
		border-right: 1px solid $border-color;
		padding: 10px 110px;

		h2 {
			color: $white;
			margin-bottom: 30px;
		}

		.btn-solid {
			margin-top: 10px;
		}

		.form-group {
			margin-bottom: 0;
		}

		.form-control {
			text-align: center;
			letter-spacing: 5px;
			font-size: 12px;
			padding: 15px 0;
			border-radius: 0;
			height: auto;
		}
	}

	.footer-link,
	.footer-link-b {
		&.link-white {
			h4 {
				color: $dark-font;
			}

			a {
				color: $grey;
				transition: 0.5s ease;

				&:hover {
					color: $grey-dark;
					transition: 0.5s ease;
				}
			}
		}

		h4 {
			padding-bottom: 20px;
		}

		li {
			padding-right: 15px;
			padding-left: 15px;
			text-transform: capitalize;
		}

		a {
			color: $grey-shade;
			transition: 0.5s ease;

			&:hover {
				color: $form-bg;
				transition: 0.5s ease;
			}
		}
	}

	.footer-link-b {
		h4 {
			margin-top: 50px;
		}
	}

	&.section-light {
		h2,
		h4 {
			color: $dark-font;
		}

		.footer-block {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			height: 100%;

			h4 {
				padding-bottom: 25px;
			}

			.contact-details {
				li {
					a {
						color: $grey-shade;

						&:hover {
							color: var(--theme-deafult);
							transition: all 0.5s ease;
						}
					}
				}
			}

			.subscribe-white {
				position: relative;
				z-index: 1;
				width: 100%;
				padding: 85px 65px;
				border-left: 20px solid $border-grey;
				border-right: 20px solid $border-grey;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: var(--theme-deafult);
					opacity: 0.4;
					z-index: -1;
				}

				.form-group {
					margin-bottom: 0;
				}

				.form-control {
					border: none;
					border-radius: 0;
					height: auto;
				}

				h2 {
					margin-bottom: 30px;
					margin-top: -5px;
				}

				.btn-solid {
					margin-top: 10px;
				}

				.form-control {
					text-align: center;
					letter-spacing: 5px;
					font-size: 12px;
					padding: 15px 0;
				}
			}
		}
	}
}

.cart-qty-cls {
	position: absolute;
	background: var(--theme-deafult);
	width: 20px;
	height: 20px;
	color: #fff;
	border-radius: 20px;
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	font-weight: 600;
	top: 20%;
	right: -8px;
	padding: 3px;
}

/*=====================
    20.Loader CSS start
==========================*/
.loader-wrapper {
	height: 100vh;
	width: 100vw;
	background-color: #fff;
	display: block;
	align-items: center;
	justify-content: center;
	position: fixed;
	z-index: 9999;
	top: 0;

	.loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50px;
		height: 50px;
		background-color: var(--theme-deafult);
		border-radius: 50%;

		&:after {
			content: '';
			position: absolute;
			border-radius: 50%;
			top: 50%;
			left: 50%;
			border: 0 solid white;
			transform: translate(-50%, -50%);
			animation: loading 1000ms ease-out forwards infinite;
			transition: all 0.3s ease;
		}
	}
}

.skeleton-svg {
	width: 100%;
	height: calc(280px + (428 - 280) * ((100vw - 320px) / (1920 - 320)));
}

.skeleton-img {
	width: 100%;
	height: calc(200px + (350 - 200) * ((100vw - 320px) / (1920 - 320)));
}

.skeleton-c1 {
	width: calc(140px + (200 - 140) * ((100vw - 320px) / (1920 - 320)));
	height: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
	border-radius: 15px;
	y: calc(260px + (415 - 260) * ((100vw - 320px) / (1920 - 320)));
}

.skeleton-c2 {
	width: calc(170px + (230 - 170) * ((100vw - 320px) / (1920 - 320)));
	height: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
	border-radius: 15px;
	y: calc(242px + (390 - 242) * ((100vw - 320px) / (1920 - 320)));
}

.skeleton-c3 {
	width: calc(100px + (150 - 100) * ((100vw - 320px) / (1920 - 320)));
	height: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
	border-radius: 15px;
	y: calc(220px + (365 - 220) * ((100vw - 320px) / (1920 - 320)));
}

@keyframes loading {
	0% {
		border: 0 solid white;
		transition: all 0.3s ease;
	}

	20% {
		border: 8px solid white;
		width: 0;
		height: 0;
		transition: all 0.3s ease;
	}

	100% {
		border: 8px solid white;
		width: 100%;
		height: 100%;
		transition: all 0.3s ease;
	}
}

/*=====================
    21.Tab to top CSS start
==========================*/
.tap-top {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	position: fixed;
	bottom: 50px;
	right: 30px;
	z-index: 8;
	display: none;
	color: #fff;
	text-align: center;
	background: var(--theme-deafult);
	border: none;
	font-size: 22px;
	padding: 7px;
	cursor: pointer;

	&.top-cls {
		bottom: 130px;
	}
}

/*=====================
    22.theme setting box CSS start
==========================*/
.color1 {
	background: #ff4c3b;
}

.color2 {
	background: #3fdda7;
}

.color3 {
	background: #f0b54d;
}

.color4 {
	background: #e4604a;
}

.color5 {
	background: #d4b196;
}

.color6 {
	background: #866e6c;
}

.color7 {
	background: #cc2121;
}

.color8 {
	background: #dc457e;
}

.color9 {
	background: #6d7e87;
}

.color10 {
	background: #fa869b;
}

.color11 {
	background: #81ba00;
}

.color12 {
	background: #fe816d;
}

.color13 {
	background: #01effc;
}

.color14 {
	background: #5d7227;
}

.color15 {
	background: #ff9944;
}

.color16 {
	background: #5fcbc4;
}

.color17 {
	background: #e38888;
}

.color18 {
	background: #000000;
}

.color-picker {
	position: fixed;
	right: -190px;
	top: calc(38vh + 220px);
	width: 190px;
	z-index: 1;
	transition: all 0.3s ease;

	.settings-header {
		background: #f3f3f3;
		padding: 5px 15px;
		box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);

		h3 {
			color: black;
			margin: 0;
			font-size: 20px;
			padding: 4px 9px;
		}
	}

	.section {
		background: white;
		padding: 10px;
		border: 1px solid #f3f3f3;
		overflow: auto;

		i {
			font-size: 16px;
			margin-right: 10px;
		}
	}

	a {
		&.handle {
			position: absolute;
			right: 190px;
			width: 45px;
			height: 41px;
			transition: all ease-in 0.3s;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
			z-index: -1;
			text-decoration: none;
			background-color: rgb(249, 249, 249);
			box-shadow: -5.985px -0.419px 30px 0 rgba(0, 0, 0, 0.08), inset -2px 0 10px 0 rgba(0, 0, 0, 0.04);
			top: -80px;
			color: $black;

			i {
				margin-left: 5px;
			}
		}
	}

	.colors {
		a {
			width: 32px;
			height: 32px;
			margin: 5px;
			float: left;
			transition: all ease-in 0.3s;
		}
	}

	.skin {
		a {
			display: block;
			width: 70px;
			height: 30px;
			margin-left: 0;
			margin-top: 0;
			margin-right: 5px;
			float: left;
			text-align: center;
			line-height: 30px;
			border: 2px transparent solid;

			&.actt {
				border: 2px white solid;
			}
		}
	}
}

.dark-light {
	position: fixed;
	right: 0;
	width: 40px;
	height: 38px;
	display: flex;
	z-index: 1;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	text-decoration: none;
	border-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	background-color: #f7f7f7;
	box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
	top: calc(50% + 34px);
	cursor: pointer;
}

.setting-sidebar {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	background-color: #f7f7f7;
	width: 40px;
	height: 40px;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
	transition: all 0.5s ease;
	z-index: 1;

	i {
		animation: ring-rotate 3s infinite linear;
		font-size: 20px;
		color: #3c3c3c;
		margin-left: 10px;
	}

	&.open-icon {
		right: 320px;
		transition: all 0.5s ease;
	}
}

.setting_box_body {
	position: relative;
	background-color: white;
	z-index: 9;
	height: 100vh;
}

.setting-box {
	position: fixed;
	height: 100vh;
	overflow-y: auto;
	width: 320px;
	right: -360px;
	background-color: white;
	top: 0;
	z-index: 999;
	box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
	transition: all 0.5s ease;

	::-webkit-scrollbar {
		width: 3px;
		height: 7px;
	}

	::-webkit-scrollbar-track {
		background: $round-border;
	}

	::-webkit-scrollbar-thumb {
		background: $grey5;
	}

	::-webkit-scrollbar-thumb:hover {
		background: $grey2;
	}

	&.open-setting {
		right: 0;
		transition: all 0.5s ease;

		.overlay {
			visibility: visible;
			transition: all 0.5s ease;
		}
	}

	.sidebar-back {
		padding: 20px;
		font-size: 18px;
		color: #222222;
		font-weight: 700;
		text-transform: uppercase;
		cursor: pointer;
		box-shadow: inset 0px 0px 6px rgba(115, 115, 115, 0.09);
		background-color: #f3f3f3;
	}

	.setting-title {
		padding: 20px;
		border-bottom: 1px solid #efefef;
		margin-bottom: 0;

		h4 {
			position: relative;
			margin-bottom: 0;
			cursor: pointer;
		}

		&.active {
			.according-menu {
				font: normal normal normal 14px/1 FontAwesome;

				&:before {
					content: '\f068';
					position: absolute;
					right: 2px;
					top: 2px;
				}
			}
		}

		.according-menu {
			font: normal normal normal 14px/1 FontAwesome;

			&:before {
				content: '\f067';
				position: absolute;
				right: 2px;
				top: 2px;
			}
		}
	}

	.setting-contant {
		padding: 20px;
		border-bottom: 1px solid #efefef;

		.setting_buttons {
			.setting_btn {
				background-color: var(--theme-deafult);
				color: white;
				width: 100%;
			}

			li {
				&:first-child {
					width: 49%;
				}

				&.active {
					box-shadow: 0px 0px 5px 0px rgb(146, 146, 146);
				}

				&:last-child {
					margin-left: 10px;
					width: 46%;
				}
			}
		}

		.color-box {
			li {
				margin: 3px;
				width: 32px;
				height: 32px;
				display: inline-grid;
			}

			&.active {
				position: relative;

				li {
					box-shadow: 0px 0px 5px 0px rgb(146, 146, 146);
				}

				&:after {
					content: '';
					background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
					top: 12px;
					height: 15px;
					width: 15px;
					background-size: 80%;
					background-repeat: no-repeat;
					position: absolute;
					left: 13px;
					filter: invert(100);
				}
			}
		}
	}

	.buy_btn {
		padding: 20px;

		a {
			background-color: #f3f3f3;
			color: #3c3c3c;
			padding: 8px;
			transition: all 0.5s ease;
			display: flex;
			align-items: center;

			i,
			img {
				margin-right: 5px;
				font-size: 18px;
			}

			img {
				filter: grayscale(100);
			}
		}

		.btn_multi {
		}
	}

	.overlay {
		visibility: hidden;
		opacity: 0;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: all 0.5s ease;
	}
}

.demo-section {
	.title-text {
		h3 {
			color: var(--theme-deafult);
			margin-bottom: 24px;
			display: inline-block;
			padding-bottom: 5px;
			font-size: 40px;
			position: relative;

			i {
				font-size: 22px;
				position: absolute;
				top: 0;
				right: -20px;
			}
		}
	}

	.demo-effects {
		margin-bottom: 30px;

		h4 {
			text-transform: capitalize;
		}

		.set-position {
			display: block;
		}

		> div {
			text-decoration: none;
			color: black;

			.layout-container {
				height: 110px;
				width: 100%;
				display: inline-flex;
				background-size: cover;
				transition: all 2s ease-out 0s;
				box-shadow: -1px 2px 5px 1px #ececec;

				@for $i from 1 through 70 {
					&.demo#{$i} {
						background-image: url('#{$assetspath}/landing-page/demo/#{$i}.jpg');
					}
				}
			}

			.demo-text {
				h4 {
					font-size: 16px;
					margin-top: 10px;
					margin-bottom: 0;
					color: #000000;

					span {
						font-weight: 500;
					}
				}

				.demo-btn {
					margin-top: 10px;

					.btn {
						border: 1px solid #ddd;
						background-color: white;
						width: 90px;
						color: var(--theme-deafult);
						padding: 5px 10px;
						border-radius: 30px;

						&:hover {
							background-color: var(--theme-deafult);
							color: white;
						}
					}
				}
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.effect-2 {
			.layout-container {
				background-size: cover;
				background-repeat: no-repeat;
			}

			&:hover {
				.layout-container {
					background-position: top !important;
				}
			}
		}

		&.effect-3 {
			.layout-container {
				background-size: cover;
				background-repeat: no-repeat;
			}

			&:hover {
				.layout-container {
					background-position: top !important;
				}
			}
		}

		&:hover {
			.layout-container {
				background-position: center 100% !important;
			}
		}
	}
}

@keyframes ring-rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.sec-position {
	position: relative;
	top: -80px;
}

.color-picker {
	position: fixed;
	right: -190px;
	top: 35%;
	width: 190px;
	z-index: 1;
	transition: all 0.3s ease;

	.settings-header {
		background: #f3f3f3;
		padding: 5px 15px;
		box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);

		h3 {
			color: black;
			margin: 0;
			font-size: 20px;
			padding: 4px 9px;
		}
	}

	.section {
		background: white;
		padding: 10px;
		border: 1px solid #f3f3f3;
		overflow: auto;

		i {
			font-size: 16px;
			margin-right: 10px;
		}
	}

	a {
		&.handle {
			position: absolute;
			right: 190px;
			width: 42px;
			height: 41px;
			color: $black;
			transition: all ease-in 0.3s;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
			z-index: -1;
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
			text-decoration: none;
			background: #ffffff;
			top: -80px;
			border: 1px solid white;
			box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);

			i {
				margin-left: 5px;
			}

			&:hover {
				color: var(--theme-deafult);
			}
		}
	}

	.colors {
		a {
			width: 32px;
			height: 32px;
			margin: 5px;
			float: left;
			transition: all ease-in 0.3s;
		}
	}

	.skin {
		a {
			display: block;
			width: 70px;
			height: 30px;
			margin-left: 0;
			margin-top: 0;
			margin-right: 5px;
			float: left;
			text-align: center;
			line-height: 30px;
			border: 2px transparent solid;

			&.actt {
				border: 2px white solid;
			}
		}
	}
}

.handle {
	i {
		animation: ring-rotate 3s infinite linear;
	}

	&:hover {
		background-color: white;
	}
}

/*=====================
  23.Inner pages CSS start
==========================*/
// error section //
.access-denied-section {
	padding: 130px 0 140px;
	text-align: center;

	h1 {
		text-transform: unset;
		font-weight: 900;
		margin: 0;
		line-height: 0.8;
		color: $font-grey;
		margin-top: -11px;
	}

	h2 {
		text-transform: unset;
		color: $font-grey;
		font-weight: 500;
		letter-spacing: 3px;
		margin: 40px 0;
	}
}
.error-section {
	padding: 130px 0 140px;
	text-align: center;

	h1 {
		font-size: 200px;
		font-weight: 900;
		margin: 0;
		line-height: 0.8;
		color: $font-grey;
		margin-top: -11px;
	}

	h2 {
		color: $font-grey;
		font-weight: 500;
		letter-spacing: 3px;
		margin: 40px 0;
	}
}

// breadcrumb section //
.breadcrumb-section {
	background-color: $top-header;
	padding: 30px 0;

	.page-title {
		display: flex;
		height: 100%;
		align-items: center;

		h2 {
			font-size: 16px;
			margin-bottom: 0;
		}
	}

	.breadcrumb {
		background-color: transparent;
		justify-content: flex-end;
		text-transform: uppercase;
		font-size: 14px;
		color: $grey2;
		font-weight: 600;
		margin: 0;

		a {
			color: $grey2;
			font-weight: 600;
		}
	}
}

// about page section //
.about-head {
	margin-bottom: 20px;
	align-items: center;
	display: flex;
	height: 100%;

	h2 {
		color: var(--theme-deafult);
		margin-bottom: 0;
	}
}

.about-page {
	p {
		margin-bottom: -7px;
	}

	.banner-section {
		border-radius: 5px;
		overflow: hidden;
	}

	h4 {
		margin-bottom: 10px;
		margin-top: 30px;
		text-transform: capitalize;
		font-weight: 600;
		line-height: 1.3;
	}

	p {
		line-height: 2;
		letter-spacing: 0.03em;
	}
}

.testimonial {
	background-color: $grey-about;

	.testimonial-slider {
		.media {
			img {
				border-radius: 100%;
				border: 1px solid $round-border;
				padding: 5px;
				height: 130px;
				width: 130px;
			}

			h5 {
				font-weight: 700;
				color: var(--theme-deafult);
				margin-bottom: 0;
				margin-top: 7px;
			}

			h6 {
				margin-bottom: -4px;
			}

			.media-body {
				padding: 30px 75px 30px 30px;

				p {
					line-height: 2;
					letter-spacing: 0.03em;
				}
			}
		}

		.slick-track {
			.slick-slide {
				&:nth-child(even) {
					.media {
						border-left: 1px solid #dddddd;
						padding-left: 50px;

						.media {
							padding-left: 5px;
						}
					}
				}
			}
		}
	}
}

.team {
	position: relative;

	img {
		margin: 0 auto;
	}

	.slick-prev,
	.slick-next {
		position: absolute;
		top: -45px;

		&:before {
			font: normal normal normal 30px/1 $font-awesome;
			color: $dark-font;
			opacity: 0.6;
		}
	}

	.slick-prev {
		left: unset;
		right: 20px;

		&:before {
			content: '\f104';
		}
	}

	.slick-next {
		right: 0;

		&:before {
			content: '\f105';
		}
	}

	h2 {
		color: $dark-font;
		padding-bottom: 15px;
		border-bottom: 1px solid $border;
		margin-bottom: 10px;
	}

	h4,
	h6 {
		text-align: center;
	}

	h4 {
		color: var(--theme-deafult);
		font-weight: 700;
		margin-top: 15px;
		margin-bottom: 5px;
	}

	h6 {
		margin-bottom: 0;
	}
}

// category page //
.infinite-scroll-component {
	overflow: hidden !important;
}

.collection-filter-block {
	margin-bottom: 30px;
	padding: 0 30px;
	border: 1px solid lighten($black, 86.5%);
	background-color: $white;

	.product-service {
		padding: 30px 0;

		.media {
			padding-bottom: 20px;
			margin-bottom: 20px;
			border-bottom: 1px solid $round-border;

			svg {
				height: 40px;
				width: 40px;

				path {
					fill: var(--theme-deafult);
				}
			}

			.media-body {
				padding-left: 10px;

				h4 {
					margin-bottom: 5px;
				}

				p {
					margin-bottom: 0;
					text-transform: capitalize;
				}
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
}

.collection-collapse-block {
	&.open {
		margin-bottom: 12px;
	}

	&:last-child {
		padding-bottom: 24px;
	}

	.collapse-block-title {
		position: relative;
		cursor: pointer;
		text-transform: capitalize;
		font-size: 16px;
		color: lighten($black, 26.5%);
		font-weight: 600;
		margin: 30px 0 30px 10px;

		&:after {
			position: absolute;
			display: block;
			top: 1px;
			left: -25px;
			content: '\f0c9';
			font-family: $font-awesome;
			color: lighten($black, 26.5%);
		}
	}

	.collection-collapse-block-content {
		.custom-control {
			margin-bottom: 12px;
		}

		::-webkit-scrollbar-track {
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			border-radius: 10px;
			background-color: #f5f5f5;
		}

		::-webkit-scrollbar {
			width: 6px;
			background-color: #f5f5f5;
		}

		::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
			background-color: var(--theme-deafult);
		}

		.color-selector {
			margin-top: 30px;
		}

		.collection-brand-filter {
			max-height: 500px;
			overflow-y: auto;
			overflow-x: hidden;
			margin-top: 30px;

			.category-list {
				li {
					display: flex;
					font-size: 16px;
					text-transform: uppercase;
					line-height: 18px;

					a {
						color: $grey;
						cursor: pointer;
						padding-bottom: 10px;
						text-transform: capitalize;
					}

					&:last-child {
						a {
							padding-bottom: 0;
						}
					}
				}
			}

			.collection-filter-checkbox {
				margin-bottom: 12px;

				&:last-child {
					margin-bottom: 0;
				}

				label {
					text-transform: uppercase;
					cursor: pointer;
					font-size: 12px;
					color: lighten($black, 46.5%);
					font-weight: 600;
					letter-spacing: 0.5px;

					&:before {
						top: 1px;
						height: 15px;
						width: 15px;
						background: $white;
						border: 1px solid lighten($black, 46.5%);
					}

					&:after {
						top: 1px;
						height: 15px;
						width: 15px;
						background-size: 65%;
					}
				}

				.custom-control-input {
					&:checked {
						~ .custom-control-label {
							&:after {
								background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
							}
						}
					}
				}
			}
		}
	}
}

.color-selector {
	ul {
		li {
			height: 30px;
			width: 30px;
			border-radius: 50%;
			margin-right: 5px;
			margin-bottom: 10px;
			cursor: pointer;
			border: 1px solid $grey4;
		}

		.color-1 {
			background-color: white;
		}

		.color-2 {
			background-color: grey;
		}

		.color-3 {
			background-color: red;
		}

		.color-4 {
			background-color: purple;
		}

		.color-5 {
			background-color: blue;
		}

		.color-6 {
			background-color: yellow;
		}

		.color-7 {
			background-color: green;
		}

		li.active {
			border: 1px solid black;
			position: relative;

			&:after {
				content: '';
				background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
				top: 10px;
				right: 4px;
				height: 15px;
				width: 15px;
				background-size: 70%;
				background-repeat: no-repeat;
				position: absolute;
			}
		}
	}
}

.col-lg-2 .product-box .img-wrapper .product-thumb-list li img {
	height: 35px;
}

.collection-sidebar-banner {
	margin-top: 30px;

	img {
		width: 100%;
	}
}

.top-banner-wrapper {
	.top-banner-content {
		h4 {
			font-weight: 600;
			color: lighten($black, 26.5%);
			margin-bottom: 15px;
		}

		h5 {
			font-size: 16px;
			font-weight: 600;
			color: lighten($black, 26.5%);
			letter-spacing: 1px;
			line-height: 1.2;
			margin-bottom: 10px;
		}

		p {
			line-height: 1.5;
			color: lighten($black, 46.5%);
			margin-bottom: 0;
		}
	}
}

.product-wrapper-grid.list-view {
	.product-wrap {
		.product-info {
			text-align: left;
			align-self: center;
			padding-left: 15px;
		}
	}

	.product-box {
		display: flex;
		flex-wrap: wrap;
		padding-bottom: 0;

		.img-wrapper,
		.img-block {
			width: 20%;
			flex: 0 0 20%;
			max-width: 20%;
		}

		.product-detail {
			padding-left: 15px;
			align-self: center;
			text-align: left !important;
			flex: 0 0 80%;
			max-width: 80%;

			.rating {
				margin-top: 0;
			}

			p {
				display: block !important;
				margin-bottom: 5px;
				line-height: 22px;
			}

			.color-variant {
				padding-top: 10px;
			}

			h6 {
				font-weight: 700;
			}
		}
	}
}

.collection-product-wrapper {
	.product-top-filter {
		border-bottom: 1px solid lighten($black, 86.5%);
		border-top: 1px solid lighten($black, 86.5%);

		.product-filter-content,
		.popup-filter {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.search-count,
			.sidebar-popup {
				border-right: 1px solid lighten($black, 86.5%);
				padding: 20px;
				text-transform: capitalize;
				width: 35%;

				h5 {
					margin-bottom: 0;
				}
			}

			.collection-view {
				padding: 20px;
				width: 10%;

				ul {
					li {
						height: 100%;
						vertical-align: middle;
						cursor: pointer;

						&:first-child {
							margin-right: 14px;
						}
					}
				}
			}

			.collection-grid-view {
				padding: 20px;
				width: 20%;
				border-right: 1px solid lighten($black, 86.5%);

				ul {
					display: flex;
					justify-content: flex-end;

					li {
						margin-left: 10px;

						img {
							height: 18px;
							cursor: pointer;
						}
					}
				}
			}

			.product-page-per-view,
			.product-page-filter {
				position: relative;
				width: 20%;

				select {
					border: 0;
					padding: 20px;
					border-right: 1px solid lighten($black, 86.5%);
					width: 100%;
					-webkit-appearance: none;
					-moz-appearance: none;
					cursor: pointer;
					background: url('#{$assetspath}/dropdown.png') no-repeat 95%;

					&:focus {
						outline: unset;
					}

					option {
						padding: 20px 0;
					}
				}
			}

			.product-page-filter {
				width: 15%;

				&:before {
					right: 10px;
				}

				select {
					border-right: none;
				}
			}
		}

		.popup-filter {
			justify-content: unset;

			::-webkit-scrollbar {
				width: 3px;
			}

			::-webkit-scrollbar-track {
				background: $round-border;
			}

			::-webkit-scrollbar-thumb {
				background: $grey5;
			}

			::-webkit-scrollbar-thumb:hover {
				background: $grey2;
			}

			.sidebar-popup {
				position: relative;
				cursor: pointer;
				width: 20%;
				background: url('#{$assetspath}/dropdown.png') no-repeat 95%;

				a {
					font-size: 16px;
					color: $dark-font;
				}

				.collection-filter {
					background-color: $top-header;
					width: 270px;
					height: 600px;
					overflow-y: scroll;

					.collection-filter-block {
						border: none;
					}

					.theme-card {
						padding: 0 30px;
					}

					.collection-sidebar-banner {
						padding: 0 30px;

						img {
							width: 100%;
						}
					}
				}
			}

			.open-popup {
				display: none;
				position: absolute;
				z-index: 9;
				top: 75px;
				left: 0;
				width: 330px;

				&.open {
					display: block;
				}

				.collection-filter-block {
					margin-bottom: 0;
				}
			}

			.product-page-per-view,
			.product-page-filter {
				width: 20%;

				select {
					padding-right: 45px;
					font-size: 16px;
				}

				&:before {
					right: 35px;
				}
			}

			.collection-grid-view {
				padding: 20px;
			}

			.collection-view {
				width: 20%;
			}

			.product-page-filter {
				&:before {
					right: 25px !important;
				}
			}
		}
	}

	.product-wrapper-grid {
		.product-box {
			margin-top: 50px;

			.product-detail {
				p {
					display: none;
				}
			}
		}

		.product-five {
			flex: 0 0 20%;
			max-width: 20%;
			position: relative;
			width: 100%;
			min-height: 1px;
			padding-right: 15px;
			padding-left: 15px;
		}

		.col-lg-2 {
			.product-box {
				.product-detail {
					h6 {
						font-size: 13px;
					}

					h4 {
						font-size: 18px;
					}

					.rating {
						i {
							padding-right: 0;
						}
					}
				}

				.img-wrapper {
					.lable-block {
						.lable3 {
							padding: 8px 4px;
							font-size: 12px;
						}
					}
				}
			}
		}
	}
}

.filter-main-btn {
	display: none;
	margin-bottom: 20px;
	cursor: pointer;

	span {
		width: 40%;
		border-radius: 0;
		font-size: 20px;
	}
}

.collection-mobile-back {
	padding: 15px 0;
	border-bottom: 1px solid lighten($black, 86.5%);
	display: none;

	span {
		text-transform: uppercase;
		font-size: 14px;
		color: #000;
		cursor: pointer;

		i {
			font-size: 20px;
			font-weight: bold;
			margin-right: 5px;
		}
	}
}

.product-pagination {
	border-bottom: 1px solid lighten($black, 86.5%);
	border-top: 1px solid lighten($black, 86.5%);
	margin: 50px 0;

	.pagination {
		border-radius: 0;

		.page-item {
			border-radius: 0;

			a {
				padding: 18px;
				border-bottom: 0;
				border-top: 0;
				color: lighten($black, 46.5%);
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}

			&:last-child {
				.page-link {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
		}

		.page-item.active {
			a {
				color: var(--theme-deafult);
				background-color: $white;
				border-color: lighten($black, 86.5%);
			}
		}
	}

	.product-search-count-bottom {
		justify-content: flex-end;
		display: flex;
		align-items: center;
		height: 100%;
		border-left: 1px solid lighten($black, 86.5%);
		border-right: 1px solid lighten($black, 86.5%);
		padding-right: 15px;

		h5 {
			color: lighten($black, 46.5%);
			font-size: 14px;
			margin-bottom: 0;
			padding: 5px 0;
		}
	}
}

// collection page //
.collection {
	.collection-block {
		text-align: center;

		img {
			margin-bottom: 20px;
		}

		.collection-content {
			text-align: center;

			h3 {
				margin-bottom: 10px;
				color: $dark-font;
				text-transform: uppercase;
				font-weight: 900;
				letter-spacing: 0.05em;
			}

			h4 {
				color: $dark-font;
				margin-bottom: 10px;
				font-size: 16px;
			}

			p {
				line-height: 1.5;
			}

			.btn-solid,
			.btn-outline {
				padding: 10px 25px;
			}
		}
	}
}

.product-filter-tags {
	li {
		display: inline-flex;
		margin-right: 10px;
		margin-bottom: 20px;

		a {
			color: #777;
			border: 1px solid #d6d6d6;
			padding: 4px 10px 4px 12px;
			text-transform: capitalize;
			border-radius: 15px;
			font-size: 13px;

			i {
				margin-left: 5px;
			}
		}
	}
}

//faq //
.faq-section {
	.accordion.theme-accordion {
		.card {
			margin-top: 10px;
			border-radius: 0;
			overflow: hidden;
			border-bottom: 1px solid $round-border;

			:not(:first-of-type):not(:last-of-type),
			.theme-accordion .card:first-of-type {
				border-bottom: 1px solid $round-border;
			}

			&:first-child {
				margin-top: 0;
			}

			.card-header {
				padding: 0;
				border-bottom: 0;
				transition: all 0.3s ease;
				background-color: $grey-lighter;

				button {
					color: $font-color;
					text-align: left;
					font-size: 17px;
					padding: 18px;
					font-weight: 600;

					&:before {
						content: '';
						position: absolute;
						width: 0;
						height: 0;
						border-left: 7px solid transparent;
						border-right: 7px solid transparent;
						border-top: 6px solid #000000;
						right: 20px;
						top: 25px;
						transition: all 0.3s ease;
					}

					&[aria-expanded='true'] {
						&:before {
							border-top: 0;
							border-bottom: 6px solid $black;
							transition: all 0.3s ease;
						}
					}

					&:hover {
						text-decoration: none;
						color: var(--theme-deafult);
						transition: all 0.3s ease;
					}
				}
			}

			.card-body {
				padding: 15px 18px;

				p {
					margin-bottom: 0;
					font-size: 14px;
					color: $grey2;
					line-height: 1.8;
					letter-spacing: 0.06em;
				}
			}
		}
	}
}

//forget_pwd//
.pwd-page {
	text-align: center;

	h2 {
		margin-bottom: 25px;
		color: $dark-font;
		margin-top: -5px;
	}

	.theme-form {
		input {
			border-color: $round-border;
			font-size: 15px;
			padding: 15px 25px;
			margin-bottom: 15px;
			height: inherit;
			text-align: center;
		}

		.btn-solid {
			margin: 0 auto;
		}
	}
}

//login//
.login-page {
	h3 {
		margin-bottom: 20px;
		text-transform: uppercase;
		color: $dark-font;
		font-weight: 700;
		margin-top: -5px;
	}

	.theme-card {
		padding: 30px;
		border: 1px solid $round-border;
		line-height: 1;

		.theme-form {
			label {
				text-transform: capitalize;
				color: $font-color;
				font-size: 14px;
				font-weight: 600;
			}

			input {
				border-color: $round-border;
				font-size: 12px;
				padding: 17px 25px;
				margin-bottom: 30px;
				height: inherit;
			}
		}
	}

	.authentication-right {
		height: calc(100% - 48px);

		h6 {
			text-transform: uppercase;
			color: $font-color;
			font-weight: 600;
			margin-bottom: 20px;
		}

		p {
			font-size: 14px;
			color: $grey2;
			line-height: 2;
			margin-bottom: 1rem;
		}
	}
}

// register page //
.register-page {
	h3 {
		margin-bottom: 20px;
		text-transform: uppercase;
		color: $dark-font;
		font-weight: 700;
		margin-top: -5px;
	}

	.theme-card {
		padding: 30px;
		border: 1px solid $round-border;
		line-height: 1;

		.theme-form {
			label {
				text-transform: capitalize;
				color: $font-color;
				font-size: 14px;
				font-weight: 600;
			}

			input {
				border-color: $round-border;
				font-size: 12px;
				padding: 17px 25px;
				margin-bottom: 30px;
				height: inherit;
			}

			select {
				border-color: $round-border;
				font-size: 12px;
				padding: 17px 25px;
				margin-bottom: 30px;
				height: inherit;
			}
		}
	}
}

// search //
.search-block {
	padding: 0;

	.form-header {
		.input-group {
			input {
				border-radius: 0 5px 5px 0;
				padding: 15px 20px;
				height: auto;
			}

			i {
				padding-right: 10px;
			}

			.btn {
				z-index: 0;
			}
		}
	}
}

// contacts //
.contact-page {
	.map {
		iframe {
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	.contact-right {
		padding-top: 30px;
		padding-bottom: 30px;

		ul {
			li {
				position: relative;
				padding-left: 150px;
				margin-top: 45px;
				display: block;

				&:first-child {
					margin-top: 0;
				}

				.contact-icon {
					position: absolute;
					left: 0;
					text-align: center;
					width: 130px;
					border-right: 1px solid #dddddd;

					i {
						font-size: 20px;
						color: #ff4c3b;
					}

					h6 {
						text-transform: uppercase;
						padding-top: 5px;
						font-weight: 600;
						color: #ff4c3b;
					}
				}

				p {
					font-weight: 600;
					margin-bottom: 0;
					line-height: 30px;
				}
			}
		}
	}

	.theme-form {
		margin-top: -6px;

		label {
			text-transform: capitalize;
			color: $font-color;
			font-size: 14px;
			font-weight: 600;
		}

		input {
			border-color: $round-border;
			font-size: 12px;
			padding: 17px 25px;
			margin-bottom: 30px;
			height: inherit;
		}

		textarea {
			border-color: $round-border;
			font-size: 12px;
			padding: 17px 25px;
			margin-bottom: 30px;
			height: inherit;
		}
	}

	.form-control {
		border-radius: 0;
	}
}

// shopping products //
.qty-box {
	.input-group {
		justify-content: center;

		span {
			button {
				background: $white !important;
				border: 1px solid #ced4da;
			}
		}

		.form-control {
			text-align: center;
			width: 80px;
			flex: unset;
		}

		button {
			background-color: transparent;
			border: 0;
			color: $grey;
			cursor: pointer;
			padding-left: 12px;
			font-size: 12px;
			font-weight: 900;
			line-height: 1;

			i {
				font-weight: 900;
				color: $dark-font;
			}
		}

		.icon {
			padding-right: 0;
		}
	}
}

.cart-section,
.wishlist-section {
	.table {
		tbody {
			& + tbody {
				border-top: none;
			}
		}
	}

	.cart-buttons {
		padding-top: 35px;

		> div {
			&:last-child {
				text-align: right;
				padding-right: 59px;
			}
		}
	}

	.wishlist-buttons {
		padding-top: 35px;
		text-align: right;

		&:last-child {
			a {
				margin-left: 15px;
			}
		}
	}

	.cart-table {
		overflow: hidden;
		margin-bottom: 0;

		thead {
			th {
				border-bottom-width: 1px;
				font-weight: 900;
				color: $dark-font;
				text-transform: uppercase;
				font-size: 14px;
				border-top: 0;
				text-align: center;
				padding: 0 0.75rem 0.75rem 0.75rem;
			}
		}
	}

	tbody {
		h2 {
			margin-bottom: 0;
		}

		tr {
			td {
				min-width: 210px;
				vertical-align: middle;
				color: $grey;
				border-top: 0;
				border-bottom: 1px solid #eeeeee;
				text-align: center;

				a,
				p {
					color: $grey;
					font-weight: 400;
					font-size: 14px;
					text-transform: capitalize;
					margin-bottom: 0;

					img {
						height: 90px;
					}
				}

				.td-color {
					color: var(--theme-deafult);
				}

				h2 {
					font-size: 24px;
					color: $dark-font;
					font-weight: 400;
				}

				.mobile-cart-content {
					display: none;
					justify-content: center;
					margin-top: 10px;

					.qty-box {
						border-right: 1px solid $round-border;
						border-radius: 5px;

						.input-group {
							.form-control {
								width: 48px;
								padding: 6px;
							}
						}
					}

					.col-xs-3 {
						align-self: center;
						margin-left: 10px;
						margin-right: 10px;
					}

					h2 {
						font-size: 20px;
					}
				}
			}
		}
	}

	tfoot {
		tr {
			th {
				padding-top: 35px;
				text-align: right;
			}

			td {
				padding-top: 35px;
				padding-bottom: 0;
				text-align: right;
				border: none;
				padding-right: 63px;

				h2 {
					font-size: 24px;
					margin-bottom: 0;
				}

				&:first-child {
					width: 85%;
					font-size: 15px;
					padding-right: 10px;
					text-transform: capitalize;
					font-weight: 700;
				}
			}
		}
	}
}

// blog-details //
.blog-detail-page {
	.blog-detail {
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		img {
			margin-bottom: 40px;
		}

		h3 {
			color: $dark-font;
			font-weight: 700;
			margin-bottom: 20px;
			text-transform: uppercase;
		}

		p {
			color: $grey;
			line-height: 1.8;
		}

		.post-social {
			color: $grey;
			text-align: left;
			padding-bottom: 15px;
			border-bottom: 1px solid $round-border;
			margin-bottom: 15px;

			li {
				& + li {
					padding-left: 15px;
					margin-left: 15px;
					border-left: 1px solid $round-border;
				}
			}
		}
	}

	.blog-advance {
		> div {
			&:last-child {
				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		img {
			margin-bottom: 25px;
			margin-top: 25px;
		}

		ul {
			list-style-type: decimal;
			list-style-position: inside;
			line-height: 2.5;
			font-size: 15px;

			li {
				display: list-item;
				font-weight: 700;
			}
		}

		p {
			line-height: 1.8;
		}
	}

	.comment-section {
		border-bottom: 1px solid $round-border;

		li {
			padding-top: 60px;
			padding-bottom: 60px;
			border-top: 1px solid $round-border;

			img {
				height: 65px;
				width: 65px;
				margin-right: 20px;
				border-radius: 100%;
			}

			h6 {
				color: $grey4;
				text-transform: uppercase;
				font-weight: 700;
				margin-bottom: 5px;

				span {
					font-size: 13px;
					font-weight: 500;
					text-transform: capitalize;
					color: $grey;
					margin-left: 20px;
				}
			}

			p {
				line-height: 1.8;
				margin-bottom: -4px;
			}
		}
	}

	.blog-contact {
		h2 {
			margin-bottom: 30px;
			margin-top: -5px;
		}

		.theme-form {
			label {
				text-transform: capitalize;
				color: $font-color;
				font-size: 16px;
				font-weight: 700;
			}

			input,
			textarea {
				border-color: $round-border;
				font-size: 14px;
				padding: 17px 25px;
				margin-bottom: 30px;
				height: auto;
			}
		}
	}
}

// blog-page //
.blog-page {
	.blog-sidebar {
		h6 {
			margin-bottom: 0;
			line-height: 20px;
			color: $font-color;
			font-weight: 700;
		}

		p {
			line-height: 20px;
			margin-bottom: 0;
			color: $grey2;
		}

		h4 {
			color: $font-color;
			text-transform: uppercase;
			font-weight: 600;
			margin-bottom: 20px;
			line-height: 20px;
			letter-spacing: 1px;
		}

		.theme-card {
			border: 1px solid $round-border;
			padding: 30px;
			background-color: $white;

			.recent-blog {
				li {
					.media {
						img {
							width: 100px;
							margin-right: 10px;
						}
					}

					& + li {
						margin-top: 30px;
					}
				}
			}

			.popular-blog {
				li {
					.blog-date {
						background-color: var(--theme-deafult);
						height: 70px;
						width: 70px;
						margin: 0 15px 0 0;
						padding: 15px;

						span {
							display: block;
							text-align: center;
							color: #ffffff;
							text-transform: uppercase;
						}
					}

					& + li {
						margin-top: 30px;
					}

					p {
						margin-top: 10px;
					}
				}
			}

			& + .theme-card {
				margin-top: 30px;
			}
		}
	}

	.blog-media {
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}

		.blog-right {
			height: 100%;
			align-items: center;
			justify-content: center;
			display: flex;

			h6 {
				color: var(--theme-deafult);
				text-transform: uppercase;
				margin-bottom: 10px;
				margin-top: -3px;
			}

			h4 {
				color: $font-color;
				font-weight: 700;
				line-height: 1.5;
				margin-bottom: 15px;
			}

			p {
				line-height: 1.8;
				margin-bottom: 0;
				letter-spacing: 0.04em;
			}

			ul {
				color: $grey;
				margin-bottom: 15px;

				li {
					font-size: 14px;
					line-height: 1.5;

					i {
						padding-right: 3px;
					}

					& + li {
						padding-left: 15px;
						margin-left: 15px;
						border-left: 1px solid #dddddd;
					}
				}
			}
		}
	}
}

// product page //
.loading-cls {
	width: 70px;
	height: 60px;
	background-repeat: no-repeat;
	margin: 0 auto;
	background-image: url('#{$assetspath}/loader.gif');
}

.seen-cls {
	text-align: center;
	margin-top: 15px;
}

.seen-it-cls {
	margin-top: 50px;
	color: red;
	font-size: 16px;

	b {
		border: 1px solid #ddd;
		padding: 5px;
	}
}

.instock-cls {
	color: var(--theme-deafult);
	font-weight: 700;
}

.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {
	z-index: 0;

	.slick-prev,
	.slick-next {
		opacity: 0;
		transform: scale(1);
		transition: all 0.5s ease;
		margin-top: -10px;

		&:before {
			font: normal normal normal 14px/1 $font-awesome;
			opacity: 1;
			color: black;
			background-color: white;
			border-radius: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.slick-next {
		right: 1px;

		&:before {
			content: '\f105';
		}
	}

	.slick-prev {
		left: 1px;
		z-index: 1;

		&:before {
			content: '\f104';
		}
	}

	.slick-list {
		overflow: hidden;
	}

	.slick-slide {
		> div {
			display: flex;
			margin-bottom: 5px;
		}
	}

	&:hover {
		.slick-prev,
		.slick-next {
			opacity: 1;
			transform: scale(1.05);
			transition: all 0.5s ease;

			&:before {
				opacity: 1;
			}
		}

		.slick-next {
			right: 20px;
		}

		.slick-prev {
			left: 20px;
		}
	}
}

.slider-nav {
	.slick-track {
		height: auto !important;
	}
}

.product-thumbnail {
	.slick-list {
		margin: 0 -15px;
	}
	&.m-0-cls {
		.slick-list {
			margin: 0;
		}
	}
}

.pb-cls-slider {
	.slick-slide {
		padding-bottom: 10px !important;
	}
}

.slider-nav,
.rtl-slider-nav,
.slider-right-nav,
.rtl-slider-right-nav {
	margin-left: -15px;
	margin-right: -15px;
	.slick-slide {
		padding: 0 15px 15px;
		padding-bottom: 0;

		> div {
			display: flex;
			margin: 0;
			border: 1px solid $round-border;
			transition: all 0.5s ease;
		}

		&.slick-current {
			> div {
				border-color: $font-grey;
				transition: all 0.5s ease;
			}

			img {
				border: 1px solid $round-border;
			}
		}

		&.slick-active {
			transition: all 0.5s ease;
		}
	}
}

.slider-right-nav,
.rtl-slider-right-nav {
	.slick-slide {
		&:first-child {
			> div {
				margin-top: 0;
			}
		}
	}
}

.product-thumbnail .slider-nav .slick-list .slick-track {
	margin-top: 10px;
}

.pro_sticky_info {
	border: 1px solid $round-border;
	padding: 20px;
}

.is_stuck {
	margin-top: 80px;
}

.image-scroll {
	div {
		> div {
			padding-bottom: 20px;

			&:last-child {
				padding: 0;
			}
		}
	}
}

.product-right {
	p {
		margin-bottom: 0;
		line-height: 1.5em;
	}

	.product-title {
		color: $dark-font;
		text-transform: capitalize;
		font-weight: 700;
		margin-bottom: 0;
	}

	.border-product {
		padding-top: 15px;
		padding-bottom: 20px;
		border-top: 1px dashed $round-border;
	}

	h2 {
		text-transform: uppercase;
		margin-bottom: 15px;
		font-size: 25px;
		line-height: 1.2em;
	}

	h3 {
		font-size: 26px;
		color: $dark-font;
		margin-bottom: 15px;
	}

	h4 {
		font-size: 16px;
		margin-bottom: 7px;

		del {
			color: $grey;
		}

		span {
			padding-left: 5px;
			color: var(--theme-deafult);
		}
	}

	.color-variant {
		margin-bottom: 10px;

		li {
			height: 30px;
			width: 30px !important;
			cursor: pointer;
		}
	}

	.product-buttons {
		margin-bottom: 20px;
		.btn-solid,
		.btn-outline {
			padding: 7px 25px;
		}
		a,
		button {
			&:last-child {
				margin-left: 10px;
			}
		}
	}

	.product-description {
		h6 {
			span {
				float: right;
			}
		}

		.qty-box {
			display: flex;
			align-items: center;
			margin-top: 10px;

			.input-group {
				justify-content: unset;
				width: unset;

				.form-control {
					border-right: none;
				}
			}
		}
	}

	.size-box {
		margin-top: 10px;
		margin-bottom: 10px;

		ul {
			li {
				height: 35px;
				width: 35px;
				border-radius: 50%;
				margin-right: 10px;
				cursor: pointer;
				border: 1px solid $grey-about;
				text-align: center;

				a {
					color: $dark-font;
					font-size: 18px;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 100%;
				}

				&.active {
					background-color: $grey-about;
				}
			}
		}
	}

	.product-icon {
		display: flex;

		.product-social {
			margin-top: 5px;

			li {
				padding-right: 30px;

				a {
					color: $font-color;
					transition: all 0.3s ease;

					i {
						font-size: 18px;
					}

					&:hover {
						color: var(--theme-deafult);
					}
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}

		.wishlist-btn {
			background-color: transparent;
			border: none;

			i {
				border-left: 1px solid $round-border;
				font-size: 18px;
				padding-left: 10px;
				margin-left: 5px;
				transition: all 0.5s ease;
			}

			span {
				padding-left: 10px;
				font-size: 18px;
			}

			&:hover {
				i {
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}
			}
		}
	}

	.payment-card-bottom {
		margin-top: 10px;

		ul {
			li {
				padding-right: 10px;
			}
		}
	}

	.timer {
		.timer-p {
			color: $dark-font;
		}

		margin-top: 10px;
		background-color: $grey-about;
	}

	&.product-form-box {
		text-align: center;
		border: 1px solid $round-border;
		padding: 20px;

		.product-description {
			.qty-box {
				margin-bottom: 5px;

				.input-group {
					justify-content: center;
					width: 100%;
				}
			}
		}

		.product-buttons {
			margin-bottom: 0;
		}

		.timer {
			margin-bottom: 10px;
			text-align: left;
		}
	}
}

.single-product-tables {
	display: flex;
	margin-top: 15px;

	table {
		width: 20%;

		tr {
			height: 35px;

			td:first-child {
				font-weight: 600;
			}
		}
	}

	&.detail-section {
		margin-top: 0;

		table {
			width: 65%;
		}
	}
}

.product-accordion {
	.btn-link {
		color: $dark-font;

		&:hover {
			text-decoration: none;
		}
	}

	.card {
		border: none;
	}

	.card-body {
		width: 100%;
		height: auto;
	}

	.card-header {
		padding: 5px 8px;
		border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
		background-color: rgba(127, 127, 127, 0.03);
	}
}

.product-related {
	h2 {
		color: $dark-font;
		padding-bottom: 20px;
		border-bottom: 1px solid #dddada;
		margin-bottom: 20px;
		margin-top: -5px;
	}
}

.rating {
	margin-top: 0;

	i {
		padding-right: 5px;

		&:nth-child(-n + 4) {
			color: $star-yellow;
		}

		&:last-child {
			color: $round-border;
		}
	}

	.three-star {
		padding-bottom: 5px;

		i {
			color: #acacac;

			&:nth-child(-n + 3) {
				color: #ffd200;
			}
		}
	}
}

.tab-border {
	border: 1px solid $round-border;
	margin: 0;
	padding: 8px 0;

	.nav-border {
		border-bottom: 0;
		border-right: 1px solid $round-border;
	}
}

.vertical-tab {
	&.tab-product,
	.product-full-tab {
		.nav-material {
			&.nav-tabs {
				.nav-item {
					.nav-link {
						padding: 15px 20px;
						cursor: pointer;
					}

					&:focus {
						border: none;
						outline: none;
						box-shadow: none;
					}
				}
			}
		}
	}
}

.tab-product,
.product-full-tab {
	padding-top: 30px;

	.nav-material {
		&.nav-tabs {
			width: 100%;
			display: flex;
			align-items: center;
			flex-wrap: nowrap;

			.nav-item {
				.nav-link {
					color: #212121;
					text-align: center;
					padding: 0 15px 20px 15px;
					text-transform: uppercase;
					border: 0;
					cursor: pointer;
					border-bottom: 2px solid transparent;

					&.active {
						border-bottom: 2px solid var(--theme-deafult);
						color: var(--theme-deafult);
					}
				}

				.material-border {
					border-bottom: 2px solid var(--theme-deafult);
					opacity: 0;
				}
			}
		}
	}

	.theme-form {
		input {
			border-color: $round-border;
			font-size: 15px;
			padding: 15px 25px;
			margin-bottom: 15px;
			height: inherit;
			text-align: left;
		}

		.btn-solid {
			margin: 0 auto;
		}

		textarea {
			border-color: $round-border;
			font-size: 15px;
			padding: 17px 25px;
			margin-bottom: 15px;
			height: inherit;
		}
	}

	.tab-content.nav-material {
		p {
			padding: 20px;
			margin-bottom: -8px;
			line-height: 2;
			letter-spacing: 0.05em;
		}

		.media {
			margin-top: 20px;
		}
	}

	.title {
		padding-right: 45px;
		color: var(--theme-deafult);
		padding-bottom: 20px;
	}

	.theme-slider {
		.slick-arrow {
			top: -45px;
			height: auto;

			:before {
				color: $black;
				font-size: 18px;
			}
		}
	}

	.product-box {
		position: relative;
		margin: 5px;

		&:hover {
			box-shadow: 0 0 12px 0 #dddddd;

			.lbl-1 {
				opacity: 1;
				transition: all 0.3s ease;
			}

			.lbl-2 {
				opacity: 1;
				animation: flipInY 1000ms ease-in-out;
			}

			.color-variant li {
				opacity: 1 !important;
				animation: fadeInUp 500ms ease-in-out;
			}
		}

		.img-block {
			min-height: unset;
		}

		.cart-info {
			position: absolute;
			padding: 10px 0;
			top: 25%;
			right: 15px;
			width: 40px;
			margin-right: 0;

			i {
				padding-right: 0;
			}

			a,
			button {
				color: $font-color;
				transition: all 0.3s ease;
				background-color: $white;
				height: 35px;
				width: 35px;
				margin: 7px 0;
				border-radius: 100%;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				border: 0;
				box-shadow: 0 0 12px 0 $round-border;

				:hover {
					transition: all 0.3s ease;
					color: var(--theme-deafult);
				}
			}
		}

		.lbl-1 {
			background-color: var(--theme-deafult);
			padding: 2px 20px 2px 10px;
			display: inline-block;
			text-align: center;
			color: #ffffff;
			position: absolute;
			left: 0;
			top: 15px;
			font-size: 14px;
			line-height: 1.5;
			opacity: 0;

			&:before {
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				width: 0;
				height: 0;
				border-top: 12px solid var(--theme-deafult);
				border-bottom: 13px solid var(--theme-deafult);
				border-right: 7px solid $white;
			}
		}

		.lbl-2 {
			font-size: 14px;
			top: 15px;
			position: absolute;
			right: 10px;
			color: #333333;
			font-weight: 600;
			text-transform: capitalize;
			opacity: 0;
		}

		a {
			color: #0072bb;
			font-size: 15px;
			font-weight: 700;
			letter-spacing: 1px;
		}

		.color-variant {
			position: absolute;
			top: -35px;
			width: 100%;
		}

		.slick-slide img {
			display: block;
		}

		.product-details {
			position: relative;

			.color-variant {
				position: absolute;
				top: -35px;
				width: 100%;

				li {
					opacity: 0;
					display: inline-block;
					height: 15px;
					width: 15px;
					border-radius: 100%;
					margin: 0 3px;
					transition: all 0.3s ease;
					cursor: pointer;
				}
			}

			h6 {
				color: #333333;
				font-weight: 600;
				letter-spacing: 1px;
				text-transform: capitalize;
			}

			.price {
				padding-bottom: 10px;
				font-size: 16px;
				color: var(--theme-deafult);
			}
		}
	}
}

.product-full-tab {
	padding-top: 70px;
}

.product-load-more {
	.col-grid-box {
		display: none;
	}
}

.load-more-sec {
	margin-top: 40px;
	text-align: center;

	a {
		font-size: 18px;
		text-transform: uppercase;
		display: block;
		padding: 10px 0;
		border-top: 1px solid $round-border;
		border-bottom: 1px solid $round-border;
		width: 100%;
	}
}

.spinner-border {
	width: 1rem;
	height: 1rem;
	margin-right: 10px;
}

// Quick-view //
.quick-view {
	width: 100%;
	max-width: 1080px;
	max-height: 600px;
	position: relative;
}

// Check-out page //
.checkout_class {
	text-transform: capitalize;
}

.checkout-page {
	.checkout-title {
		margin-bottom: 25px;

		h3 {
			color: $grey4;
			font-weight: 700;
		}
	}

	.checkout-form {
		.checkout-details {
			position: relative;
			background-color: $grey-lighter;
			border: 1px solid $round-border;
			padding: 40px;
		}

		.check-out {
			.form-group {
				&:last-child {
					margin-bottom: -5px;

					label {
						margin-bottom: -5px;
					}
				}
			}
		}

		.form-group {
			position: relative;
			margin-bottom: 25px;

			h3 {
				color: $grey4;
				font-weight: 700;
				margin-bottom: 30px;
				margin-top: 30px;
			}

			.field-label {
				line-height: 24px;
				text-transform: capitalize;
				color: #333333;
				margin-bottom: 10px;
				font-weight: 700;

				span {
					font-size: 16px;
					color: $dark-font;
					font-weight: 600;
				}
			}

			label {
				color: $grey;
			}
		}

		select {
			cursor: pointer;
			-webkit-appearance: none;
			-ms-appearance: none;
			-o-appearance: none;
			-moz-appearance: none;
			background: url('#{$assetspath}/dropdown.png') no-repeat 95%;
		}

		input {
			&[type='text'],
			&[type='email'],
			&[type='password'],
			&[type='tel'],
			&[type='number'],
			&[type='url'] {
				width: 100%;
				padding: 0 22px;
				height: 45px;
				border: 1px solid #dddddd;
			}
		}

		select,
		textarea {
			width: 100%;
			padding: 0 22px;
			height: 45px;
			border: 1px solid #dddddd;
		}
	}

	.check-box {
		line-height: 24px;
		font-size: 14px;
		font-weight: normal;
		padding-top: 5px;

		label {
			position: relative;
			top: -1px;
			font-weight: normal;
			padding: 0;
			font-size: 16px;
			cursor: pointer;
			color: $font-color;
		}
	}

	.lower-content {
		margin-top: 30px;

		.order-column {
			margin-bottom: 40px;
		}
	}
}

.order-box {
	position: relative;
	margin-bottom: 50px;

	.title-box {
		position: relative;
		padding-bottom: 25px;
		color: $grey4;
		font-weight: 600;
		font-size: 22px;
		border-bottom: 1px solid #ededed;
		margin-bottom: 20px;

		span {
			position: relative;
			width: 35%;
			float: right;
			line-height: 1.2em;
		}
	}

	.qty {
		position: relative;
		border-bottom: 1px solid #ededed;
		margin-bottom: 30px;

		li {
			position: relative;
			display: block;
			font-size: 15px;
			color: $grey4;
			line-height: 20px;
			margin-bottom: 20px;

			span {
				float: right;
				font-size: 18px;
				line-height: 20px;
				color: #232323;
				font-weight: 400;
				width: 35%;
			}
		}
	}

	.sub-total {
		position: relative;
		border-bottom: 1px solid $round-border;
		margin-bottom: 30px;

		li {
			position: relative;
			display: inline-block;
			font-size: 16px;
			font-weight: 600;
			color: $font-color;
			line-height: 20px;
			margin-bottom: 20px;
			width: 100%;

			.count {
				position: relative;
				font-size: 18px;
				line-height: 20px;
				color: var(--theme-deafult);
				font-weight: 400;
				width: 35%;
				float: right;
			}
		}

		.shopping-option {
			label {
				position: relative;
				font-size: 16px;
				line-height: 32px;
				padding-left: 10px;
				color: $grey6;
			}
		}

		.shipping {
			width: 35%;
			float: right;
		}
	}

	.total {
		position: relative;
		margin-bottom: 40px;

		li {
			position: relative;
			display: block;
			font-weight: 400;
			color: $font-color;
			line-height: 20px;
			margin-bottom: 10px;
			font-size: 18px;

			.count {
				position: relative;
				font-size: 18px;
				line-height: 20px;
				color: var(--theme-deafult);
				font-weight: 400;
			}

			span {
				float: right;
				font-size: 15px;
				line-height: 20px;
				color: $grey4;
				font-weight: 400;
				width: 35%;
				display: block;
			}
		}
	}
}

.payment-box {
	position: relative;

	.upper-box {
		position: relative;
	}

	.payment-options {
		position: relative;
		margin-top: 20px;
		margin-bottom: 30px;

		li {
			display: flex;
			margin-bottom: 15px;

			.radio-option {
				position: relative;

				label {
					position: relative;
					padding-left: 30px;
					text-transform: capitalize;
					color: $grey6;
					cursor: pointer;
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
					margin-bottom: 0;
				}

				input[type='radio'] {
					position: absolute;
					left: 0;
					top: 5px;
				}

				label {
					.small-text {
						position: relative;
						display: none;
						font-size: 15px;
						line-height: 25px;
						font-weight: 300;
						color: #666666;
						margin-top: 10px;
					}

					img {
						position: relative;
						display: block;
						max-width: 100%;
						margin-left: -30px;
						margin-top: 5px;
					}
				}
			}
		}
	}
}

.stripe-section {
	text-transform: uppercase;
	background-color: #f9f9f9;
	padding: 15px;
	border: 1px solid #dddddd;

	table {
		tbody {
			tr {
				td {
					&:first-child {
						padding-right: 15px;
					}
				}
			}
		}
	}

	h5 {
		font-weight: 700;
	}

	.content {
		h5 {
			color: #4f4f4f;
			font-weight: 400;
		}

		table {
			width: 100%;

			tr {
				border-bottom: 1px solid #f1f1f1;

				td {
					padding: 8px 0;
					color: #9a9a9a;

					&:last-child {
						text-align: right;
					}
				}
			}
		}
	}
}

.stripe-section {
	.content {
		table {
			tr {
				td {
					&:first-child {
						text-align: right;
					}
				}
			}
		}
	}
}

// Dashboard page
.dashboard-left {
	.block-title h2 {
		font-size: 24px;
		padding-bottom: 20px;
	}

	.block-content {
		border: 1px solid #ddd;
		padding: 15px;

		ul li {
			display: flex;
			transition: all 0.5s ease;

			a {
				color: black;
			}

			&.active {
				color: var(--theme-deafult);

				a {
					color: var(--theme-deafult);
				}
			}

			&:before {
				content: '\f105';
				display: inline-block;
				font-family: FontAwesome;
				font-style: normal;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				width: 30px;
				height: 30px;
				text-align: center;
				font-size: 18px;
				font-weight: 700;
				margin-top: 3px;
			}

			&:hover {
				a {
					color: #ff0000;
					transition: all 0.5s ease;
				}

				padding-left: 10px;
				color: #ff0000;
				transition: all 0.5s ease;
			}
		}
	}
}

.padding-cls {
	padding-left: 50px;
	padding-right: 50px;
}

.dashboard-right {
	p {
		color: #5f5f5f;
		line-height: 20px;
	}

	.dashboard {
		border: 1px solid #ddd;
		padding: 30px;

		.page-title h2 {
			font-size: 22px;
			margin-bottom: 15px;
		}

		.welcome-msg {
			p {
				margin-bottom: 0;
			}
		}
	}
}

.dashboard {
	.box-head {
		h2 {
			font-size: 22px;
			margin: 20px 0 0;
			text-transform: capitalize;
			color: #333;
		}
	}

	.box {
		a {
			color: var(--theme-deafult);
		}

		h6 {
			margin-bottom: 0;
		}

		.box-title {
			border-bottom: 1px solid #ddd;
			display: inline-block;
			margin-bottom: 5px;
			padding: 12px 0;
			position: relative;
			width: 100%;

			h3 {
				font-size: 16px;
				margin: 0;
				text-transform: capitalize;
				color: #333;
			}

			> a {
				position: absolute;
				right: 0;
				top: 12px;
				color: var(--theme-deafult);
			}
		}

		address {
			margin-bottom: 0;
		}
	}
}

.box-content {
	h6 {
		font-size: 14px;
		line-height: 25px !important;
		margin-bottom: 0;
	}

	a {
		color: var(--theme-deafult);
	}
}

.account-sidebar {
	display: none;
	cursor: pointer;
	background-color: var(--theme-deafult);
	color: $white;
	width: 30%;
	font-size: 16px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;
	margin-bottom: 20px;
	padding: 5px;

	a {
		color: $white;
	}
}

// Order-success //
.success-text {
	text-align: center;

	i {
		font-size: 50px;
		color: #4ead4e;
	}

	h2 {
		margin-top: 10px;
		margin-bottom: 15px;
	}

	p {
		font-size: 18px;
		text-transform: capitalize;
	}
}

.order-success-sec {
	h4 {
		font-weight: 700;
		text-transform: capitalize;
	}

	.order-detail {
		li {
			display: flex;
			text-transform: capitalize;
			line-height: 24px;
			font-size: 16px;
		}
	}

	.payment-mode {
		p {
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 0;
		}
	}
}

.delivery-sec {
	padding: 30px;
	text-align: center;
	margin-top: 20px;
	background-color: #f9f9f9;

	h2,
	h3 {
		color: $black;
		text-transform: capitalize;
	}
}

.product-order {
	h3 {
		font-weight: 700;
		text-transform: uppercase;
		color: $black;
	}

	.product-order-detail {
		margin-top: 15px;

		img {
			height: 130px;
		}

		.order_detail {
			display: flex;
			align-items: center;

			h4 {
				margin-bottom: 5px;
				text-transform: capitalize;
				font-weight: 700;
			}

			h5 {
				text-transform: capitalize;
				margin-bottom: 0;
			}
		}
	}

	.total-sec {
		border-top: 1px solid $round-border;
		border-bottom: 1px solid $round-border;
		margin: 15px 0;
		padding: 15px 0;

		ul {
			li {
				font-size: 18px;
				display: block;
				text-transform: capitalize;

				span {
					float: right;
				}
			}
		}
	}

	.final-total {
		h3 {
			display: block;
			margin-bottom: 0;

			span {
				float: right;
			}
		}
	}
}

// compare //
.compare-padding {
	padding-bottom: 50px;

	h2 {
		margin-bottom: 20px;
	}
}

.compare-lable {
	position: absolute;
	font-size: 13px;
	font-weight: 700;
	text-transform: uppercase;
	-webkit-writing-mode: vertical-rl;
	-ms-writing-mode: tb-rl;
	writing-mode: vertical-rl;
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
	top: 20px;
	left: 16px;
	letter-spacing: 0.1em;
	z-index: 1;
}

.compare-page {
	.table-wrapper {
		padding-bottom: 0;

		.table {
			border: 1px solid $white-dark;
			text-transform: capitalize;
			color: $dark-font;

			.product-name {
				width: 15%;
			}

			.featured-image {
				width: 185px;
			}

			td {
				border-top: none;
			}

			thead {
				.th-compare {
					td {
						font-size: 14px;
						font-weight: 700;
						background: $top-header;
						border-right: 1px solid #eee;
					}

					th {
						text-align: left;
						border-bottom: 1px solid $white-dark;
						border-right: 1px solid $white-dark;

						.remove-compare {
							float: left;
							border: none;
							background: transparent;
							padding: 0;
							font-size: 14px;
							font-weight: 500;
						}
					}
				}
			}

			tbody {
				tr {
					th {
						background: $top-header;
					}

					td {
						border-right: 1px solid #eee;
						position: relative;
					}

					th,
					td {
						padding: 15px;
						vertical-align: top;
						border-bottom: 1px solid #eee;
						border-top: 1px solid #eee;
						border-right: 1px solid #eee;
					}

					.grid-link__title {
						font-size: 15px;
					}

					.product_price {
						margin: 15px 0;

						span.money {
							padding-left: 5px;
						}
					}

					p {
						color: $dark-font;
						font-size: 13px;
						line-height: 20px;
						margin: 0;
					}

					.add-to-cart,
					.select-option-cls {
						padding: 6px 10px;
					}
				}
			}
		}
	}
}

.compare-section {
	.compare-part {
		position: relative;

		.close-btn {
			background-color: transparent;
			border: none;
			font-size: 25px;
			position: absolute;
			top: 0;
			right: 0;
		}

		.img-secton {
			padding: 0 15px 15px;

			img {
				width: 50%;
				margin: 0 auto;
				padding-top: 25px;
			}

			a {
				h5 {
					margin-bottom: 0;
					text-transform: capitalize;
					margin-top: 10px;
				}
			}

			h5 {
				margin-bottom: 0;
				line-height: 1.2;
			}
		}

		.detail-part {
			.title-detail {
				background-color: #f7f8fa;
				text-align: center;
				padding: 8px 0;
				border-top: 1px solid #dbdbdb;
				border-bottom: 1px solid #dbdbdb;

				h5 {
					margin-bottom: 0;
					text-transform: uppercase;
				}
			}

			.inner-detail {
				padding: 15px;

				p {
					margin-bottom: 0;
					line-height: 1.2;
					letter-spacing: 0.05em;
				}
			}
		}

		.btn-part {
			text-align: center;
			padding: 15px;
			border-top: 1px solid #ddd;

			.btn-solid {
				padding: 5px 10px;
			}
		}
	}

	.slick-slide {
		> div {
			border: 1px solid #ddd;
			border-left: none;
		}

		&:first-child {
			border-left: 1px solid #ddd;
		}
	}
}

/*=====================
    18.Portfoilio CSS start
==========================*/
.portfolio-padding {
	padding-bottom: 40px;
}

.portfolio-section {
	.article-title {
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0;
		color: white;
		transition: all 0.5s ease;
		text-align: center;
		float: left;
		font-size: 18px;
		line-height: 24px;

		a {
			color: white;
		}
	}

	.isotopeSelector {
		float: left;
		width: 100% !important;
		position: relative;

		.overlay {
			position: relative;
			border: 1px solid $round-border;
		}

		img {
			width: 100%;
			height: auto;
		}
	}

	.isotopeSelector {
		.product-box {
			img {
				transition: all 0.4s ease;
			}

			.overlay-background {
				transform: scale(0);
				transition: all 0.4s ease;

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					background-color: white;
					padding: 10px;
					border-radius: 100%;
					transform: translate(-50%, -50%);
					color: var(--theme-deafult);
					font-size: 22px;
					display: none;
					transition: all 0.4s ease;
					width: 40px;
					height: 40px;
					align-items: center;
					justify-content: center;
				}
			}

			&:hover {
				img {
					transform: scale(1.2) rotate(2deg);
					transition: all 0.4s ease;
				}

				.overlay-background {
					transform: scale(1);
					border: 3px solid white;
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background-color: rgba(var(--theme-deafult), 0.2);
					transition: all 0.4s ease;

					i {
						display: flex;
						transition: all 0.4s ease;
					}
				}
			}
		}
	}

	.border-portfolio {
		margin: 3px;
		overflow: hidden;
	}

	#form1 {
		padding-bottom: 20px;
	}

	.filter-button {
		padding: 0 24px;
		font-size: 20px;
		line-height: 35px;
		border: none;
		background-color: white;

		&.active {
			background-color: var(--theme-deafult);
			color: $white;
		}
	}

	&.fullwidth-portfolio {
		.isotopeSelector {
			padding-left: 7px;
			padding-right: 7px;
			margin-bottom: 14px;
		}
	}

	&.metro-section {
		.isotopeSelector {
			&:hover {
				img {
					//transform: scale(1) rotate(0deg);
					//transition: all 0.5s ease;
				}
			}
		}

		.product-box {
			.product-detail {
				opacity: 0;
				position: absolute;
				background-color: $white;
				padding: 10px;
				transition: all 0.5s ease;
				width: 65%;
				left: 0;
				right: 0;
				margin: 0 auto;
				bottom: -10px;
				text-align: center;

				h6 {
					color: #525252;
					padding-top: 0;
					margin-top: -2px;
				}

				h4 {
					font-weight: 400;
					color: $black;
				}
			}

			.cart-wrap {
				top: 7px;
				right: 7px;
				opacity: 1;

				button {
					border: 1px solid $white;
					border-radius: 100%;
					background-color: rgba($white, 0.8);
					opacity: 1;

					i {
						padding: 7px;
						color: $font-color;
					}
				}

				a {
					i {
						border-radius: 100%;
						border: 1px solid $white;
						margin: 5px 0;
						background-color: rgba($white, 0.8);
						padding: 7px;
						color: $font-color;
						opacity: 0;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			&:hover {
				.product-detail {
					opacity: 1;
					transition: all 0.5s ease;
					bottom: 15px;
				}

				.cart-wrap {
					button {
						animation: none;
					}

					a {
						i {
							opacity: 1;
						}

						&:nth-child(2) {
							i {
								animation: fadeInDown 500ms ease-in-out;
							}
						}

						&:nth-child(3) {
							i {
								animation: fadeInDown 700ms ease-in-out;
							}
						}

						&:nth-child(4) {
							i {
								animation: fadeInDown 1000ms ease-in-out;
							}
						}
					}
				}
			}
		}
	}

	&.grid-portfolio {
		.isotopeSelector {
			.overlay {
				margin-bottom: 30px;

				img {
					transition: all 0.4s ease;
				}

				.overlay-background {
					transform: scale(0);
					transition: all 0.4s ease;

					i {
						position: absolute;
						top: 50%;
						left: 50%;
						background-color: white;
						padding: 10px;
						border-radius: 100%;
						transform: translate(-50%, -50%);
						color: var(--theme-deafult);
						font-size: 22px;
						display: none;
						transition: all 0.4s ease;
						width: 40px;
						height: 40px;
						align-items: center;
						justify-content: center;
					}
				}

				&:hover {
					img {
						transform: scale(1.2) rotate(2deg);
						transition: all 0.4s ease;
					}

					.overlay-background {
						transform: scale(1);
						border: 3px solid white;
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						z-index: 1;
						background-color: rgba(var(--theme-deafult), 0.2);
						transition: all 0.4s ease;

						i {
							display: flex;
							transition: all 0.4s ease;
						}
					}
				}
			}
		}
	}
}

.filter-section {
	.filter-container {
		text-align: center;
		padding: 0 0 20px;

		ul {
			&.filter {
				> li {
					padding: 0 25px;

					> a {
						color: var(--theme-deafult);
						font-size: 20px;
						line-height: 40px;

						&:hover,
						&:focus {
							text-decoration: none;
							color: var(--theme-deafult);
							outline: none;
						}
					}

					&.active {
						background-color: var(--theme-deafult);

						a {
							color: $white;
						}
					}

					span {
						color: #ddd;
						font-size: 20px;
						display: inline-block;
						margin: 0 5px;
					}
				}
			}
		}

		.filter {
			> li {
				padding: 0;
				margin: 0;
			}
		}
	}

	.title1 {
		.title-inner1 {
			margin-top: -5px;
		}
	}

	h1 {
		text-align: center;
	}
}

.demo-colored-wrap {
	background: #ecf0f1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
	opacity: 0;
	-webkit-backface-visibility: hidden;
	-webkit-transition: all 0.3s ease-out;
	-moz-transition: all 0.3s ease-out;
	-o-transition: all 0.3s ease-out;
	transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
	opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
	opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
	opacity: 0;
}

.mfp-title {
	display: none;
}

.offer-box {
	top: 50%;
	position: fixed;
	transform: translateY(-50%);
	display: flex;
	left: -415px;
	transition: all 0.5s ease;
	z-index: 9;

	img {
		margin-right: 18px;
	}

	&.toggle-cls {
		left: 0;
		transition: all 0.5s ease;
	}

	.heading-right {
		h3 {
			position: absolute;
			top: 50%;
			right: 0;
			width: 230px;
			height: 36px;
			line-height: 36px;
			letter-spacing: 5px;
			font-size: 18px;
			color: #ffffff;
			font-weight: 700;
			text-transform: uppercase;
			transform: translate(50%, -50%) rotate(-90deg);
			text-align: center;
			background-color: var(--theme-deafult);
			margin-bottom: 0;
			cursor: pointer;
		}
	}
}

.my-masonry-grid {
	display: -webkit-box;
	/* Not needed if autoprefixing */
	display: -ms-flexbox;
	/* Not needed if autoprefixing */
	display: flex;
	margin-left: -30px;
	/* gutter size offset */
	width: 100%;
}

.my-masonry-grid_column {
	padding-left: 30px;
	/* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	/* change div to reference your elements you put in <Masonry> */
	margin-bottom: 30px;
}

// header {
.header-5 {
	position: absolute;
	width: 100%;
	background-color: transparent;

	.top-header {
		background-color: transparent;

		.header-dropdown > li {
			padding-top: 40px;
			padding-right: 0;
		}
	}
}

.layout-20 {
	background-image: url('/assets/images/nursery/bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	padding: 50px;
}

.p-0 {
	.home-slider {
		// background-image: url('/assets/images/home-banner/33.jpg');
		background-size: cover;
		background-position: center center;
		display: block;
	}
}

// }

.effect-cls {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		background: url('/assets/images/game/top.png');
		top: -1px;
		width: 100%;
		height: 85px;
		z-index: 1;
		background-repeat: no-repeat;
	}

	&:before {
		content: '';
		position: absolute;
		background: url('/assets/images/game/back.png');
		bottom: -12px;
		width: 100%;
		height: 110px;
		z-index: 1;
	}

	&.footer-effect {
		&:before {
			display: none;
		}
	}
}

.bg-title {
	.theme-card {
		h5 {
			&.title-border {
				padding: 10px;
				color: white;
				background-color: var(--theme-deafult);
				border-radius: 5px;
			}
		}

		.slick-prev {
			right: 30px;

			&:before {
				color: white;
				opacity: 1;
				font-size: 25px;
			}
		}

		.slick-next {
			right: 6px;

			&:before {
				color: white;
				opacity: 1;
				font-size: 25px;
			}
		}
	}

	.theme-tab {
		.bg-title-part {
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			margin-top: -6px;
			background-color: var(--theme-deafult);
			padding: 10px;
			border-radius: 5px;

			.title-border {
				margin-bottom: 0;
				color: white;
				text-transform: capitalize;
			}

			.tab-title {
				margin-bottom: 0;
				margin-top: 0;
				margin-left: auto;
				text-align: right;

				li {
					color: rgba(white, 0.7);
					font-size: 16px;
					padding-right: 0;

					&:first-child {
						padding-left: 0;
					}
				}

				a {
					color: rgba(white, 0.7);
				}

				.current {
					a {
						color: white;
					}
				}
			}
		}
	}
}

@for $i from 1 through 28 {
	.market-bg#{$i} {
		background-image: url('#{$assetspath}/marketplace/#{$i}.jpg');
		background-size: cover;
		background-position: center;
		height: 250px;
	}
}

.videoClass {
	width: 100%;
	position: relative;
}

.advertise-section {
	.full-banner {
		padding-bottom: 105px;
		padding-top: 105px;
		background-position: top;

		.banner-contain {
			background-color: rgba($white, 0.9);
			padding: 40px 0;
			width: 90%;
			border: 3px solid var(--theme-deafult);

			h2 {
				font-size: 75px;
			}
		}
	}
}

.box-product {
	.full-box {
		.theme-card {
			.offer-slider {
				.product-box2 {
					box-shadow: 0 2px 5px 0 rgba(145, 145, 145, 0.16), 0 2px 10px 0 rgba(145, 145, 145, 0.12);

					.media {
						padding-right: 15px;

						img {
							height: 192px;
							padding: 0 15px 0 0;
						}

						.media-body {
							z-index: 1;

							h4 {
								font-size: 18px;
								margin-top: 7px;
							}
						}
					}

					+ .product-box2 {
						border-top: none;
						margin-top: 30px;
					}

					.cart-bottom {
						border-top: 1px solid #ddd;
						padding-top: 10px;
						margin-top: 12px;

						button {
							border: none;
							background-color: transparent;
							padding: 0;
						}

						i {
							color: $font-grey;
							font-size: 16px;
							padding-right: 7px;
							transition: all 0.5s ease;

							&:hover {
								color: var(--theme-deafult);
								transition: all 0.5s ease;
							}
						}
					}
				}
			}
		}
	}

	.theme-card {
		.offer-slider {
			> div {
				.media {
					&:last-child {
						.media-body {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
}

.blog-left {
	.blog-details {
		text-align: left;
		padding: 0;

		h4 {
			font-weight: 700;
			color: $black;
		}

		p {
			font-size: 14px;
			text-transform: capitalize;
			font-weight: 400;
			margin-top: -3px;
		}

		h6 {
			color: black;
			margin: 12px 0;
		}
	}
}

header {
	&.left-header {
		.sidenav {
			right: 0;

			.left-sidebar_center {
				padding: 40px 0 30px 30px;
				background-color: #ffffff;
			}

			.pixelstrap {
				li {
					ul {
						li {
							&:hover {
								.nav-submenu {
									opacity: 1;
									display: block;
									transition: 0.5s;
								}
							}
						}
					}

					.nav-submenu {
						display: none;
						top: auto;
						left: 0px;
						margin-left: 270px;
						margin-top: -197.4px;
						min-width: 10em;
						max-width: 20em;

						li {
							.nav-sub-childmenu {
								display: none;
								top: auto;
								left: 0px;
								margin-left: 228px;
								margin-top: -25px;
								min-width: 10em;
								max-width: 20em;
							}

							a {
								i {
									float: right;
									margin-top: 5px;
									font-weight: 600;
								}
							}

							&:hover {
								.nav-sub-childmenu {
									opacity: 1;
									display: block;
									transition: 0.5s;
								}
							}
						}
					}

					.mega-menu-container {
						display: none;
						top: auto;
						left: 0px;
						margin-left: 270px;
						margin-top: -43.35px;
						min-width: 10em;
						position: absolute;
						width: 730px;

						.link-section {
							.menu-title {
								padding-left: 33px;
							}
						}
					}

					&:hover {
						.mega-menu-container {
							display: block;
							background-color: white;
							padding: 20px 0;
						}

						.nav-submenu {
							opacity: 1;
							display: block;
							transition: 0.5s;
						}
					}
				}
			}
		}

		// .top-header {
		//   padding-left: 0;
		//   padding-right: 300px;
		// }

		// .search-overlay {
		//   padding-right: 300px;
		//   padding-left: 0;
		// }
	}
}

.full-scroll-menu {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9;
	background-color: transparent;

	.container-fluid {
		padding-left: 60px;
		padding-right: 60px;
	}
}

.full-scroll-footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;

	.sub-footer {
		background-color: transparent;

		.container-fluid {
			padding-left: 60px;
			padding-right: 60px;
		}
	}
}

.home-slider {
	&.fullpage {
		.home {
			height: calc(100vh - 2px);

			.slider-contain {
				height: calc(100vh - 2px);
				padding-left: 25px;

				h1 {
					font-style: italic;
					font-size: 50px;
					margin-top: -12px;
				}

				p {
					max-width: 600px;
					line-height: 1.5;
					margin-top: 15px;
					margin-bottom: 10px;
					font-size: 16px;
				}
			}
		}

		.slick-next,
		.slick-prev {
			display: none !important;
		}

		&:hover {
			.slick-next,
			.slick-prev {
				display: none !important;
			}
		}

		.slick-dots {
			top: 50%;
			bottom: unset;
			width: unset;
			left: 20px;
			transform: translateY(-50%);

			li {
				display: flex;

				button {
					&:before {
						font-size: 15px;
						color: var(--theme-deafult);
						opacity: 0.2;
					}
				}
			}

			li {
				&.slick-active {
					button {
						&:before {
							opacity: 0.8;
						}
					}
				}
			}
		}
	}

	&.instagram-slider {
		.home {
			height: 50vh;

			.slider-contain {
				height: 50vh;
			}
		}
	}

	&.slick-dotted {
		&.slick-slider {
			margin-bottom: 0;
		}
	}
}

.video-layout {
	height: calc(100vh - 5px);
	width: 100vw;
	object-fit: fill;
}

.product-tags {
	li {
		a {
			&.filter_tag {
				color: #777777;
				border: 1px solid #777777;
				padding: 4px 10px 4px 12px;
				text-transform: capitalize;
				border-radius: 15px;
				font-size: 13px;
			}
		}
	}
}

.leftImage {
	.slider-nav {
		.slick-slide {
			> div {
				margin: 0;
				margin-top: 15px;
			}
		}
	}
}

.bundle {
	.bundle_img {
		display: flex;
		align-items: center;

		.plus {
			display: flex;
			align-items: center;
			padding: 0 7px;
			font-size: 22px;
		}

		.img-box {
			img {
				max-width: 115px;
				border: 1px solid #ddd;
				padding: 2px;
			}
		}
	}

	.bundle_detail {
		margin-top: 15px;

		.price_product {
			color: #000000;
			padding-left: 3px;
			font-weight: 700;
		}
	}
}

.theme_checkbox {
	label {
		display: block;
		position: relative;
		padding-left: 30px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 14px;
		text-transform: capitalize;
		color: #333333;
	}

	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;

		&:checked {
			~ .checkmark {
				&::after {
					display: block;
				}
			}
		}
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 20px;
		width: 20px;
		background-color: #ffffff;
		border: 1px solid #dddddd;

		&::after {
			content: '';
			position: absolute;
			display: none;
			left: 7px;
			top: 3px;
			width: 5px;
			height: 10px;
			border: solid black;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
	}
}

.image-swatch {
	margin-bottom: 10px;

	li {
		img {
			width: 33px;
			height: 33px;
			padding: 2px;
			border: 1px solid #dddddd;
			margin-right: 5px;
			transition: all 0.5s ease;
		}

		&.active {
			img {
				border: 1px solid #ff4c3b;
			}
		}
	}
}

.product_image_4 {
	> div:nth-last-child(-n + 2) {
		margin-top: 25px;
	}
}

// .portfolio-section {
//   .filter-button {
//     &.active {
//       background-color: #ff4c3b;
//       color: #ffffff;
//     }
//   }
// }

table.top-0 {
	margin-top: 0;
}

.text-center {
	text-align: center;
}

.main-bg-light {
	background-color: #fafafa;
}

.table {
	width: 100%;
	margin-bottom: 1rem;
	color: #212529;
}

table {
	border-collapse: collapse;
}

// dashboard
.dashboard-section {
	.dashboard-sidebar {
		// box-shadow: 0 2px 4px 0 $box-shadow;
		position: sticky;
		top: 30px;
		z-index: 1;
		padding: 30px 0 15px 0;
		background-color: $top-header;

		.profile-top {
			padding: 0 16px;
			margin-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));

			.profile-image {
				position: relative;

				img {
					width: 130px;
					margin: 0 auto;
					display: block;
					border-radius: 100%;
				}

				.profile-edit {
					position: absolute;
					bottom: 0;
					right: 30%;

					svg {
						stroke: var(--theme-deafult);
					}
				}
			}

			.profile-detail {
				text-align: center;
				margin-top: 15px;

				h5 {
					text-transform: capitalize;
					font-weight: 700;
					margin-bottom: 5px;
				}

				h6 {
					color: #777777;
					margin-bottom: 3px;
				}
			}
		}

		.faq-tab {
			.nav-tabs {
				box-shadow: none;
				padding: 0;
			}
		}
	}

	.counter-section {
		.counter-box {
			background-color: $top-header;
			padding: 30px 30px;
			display: flex;
			align-items: center;
			border-left: 3px solid var(--theme-deafult);
			border-radius: 8px;

			img {
				height: 50px;
				margin-right: 20px;
			}

			h3 {
				font-weight: 700;
				color: var(--theme-deafult);
				margin-bottom: 4px;
				font-size: 20px;
			}

			h5 {
				margin-bottom: 0;
				text-transform: capitalize;
			}
		}
	}

	.faq-content {
		.card {
			border: none;
			background-color: $top-header;
			margin-top: 30px;
		}
	}

	.dashboard-table {
		h3 {
			text-transform: capitalize;
			font-size: 16px;
			color: black;
			margin-bottom: 15px;
		}

		img {
			width: 50px;
		}

		.table {
			thead {
				th {
					border-top: none;
				}
			}

			th,
			td {
				vertical-align: middle;
				text-transform: capitalize;
				text-align: center;
			}
		}
	}

	.top-sec {
		display: flex;
		margin-bottom: 15px;

		h3 {
			text-transform: capitalize;
			color: black;
			font-size: 20px;
		}

		.btn {
			margin-left: auto;
			text-transform: capitalize;
			padding: 7px 14px;
		}
	}

	.dashboard-box {
		.dashboard-title {
			margin-bottom: 20px;
			display: flex;
			align-items: center;

			h4 {
				text-transform: capitalize;
				font-weight: 700;
				margin-bottom: 0;
			}

			span {
				margin-left: 30px;
				padding: 2px 16px;
				border-radius: 2px;
				text-transform: capitalize;
				color: var(--theme-deafult);
				cursor: pointer;
			}
		}

		.dashboard-detail {
			ul {
				li {
					display: block;
					margin-bottom: 10px;

					.details {
						display: flex;

						h6 {
							text-transform: capitalize;
							margin-bottom: 0;
						}

						span {
							margin-left: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
							border-radius: 2px;
							text-transform: capitalize;
							color: var(--theme-deafult);
							cursor: pointer;
						}

						.left {
							width: 150px;
							margin-right: 15px;

							h6 {
								color: #4e4e4e;
							}
						}

						.right {
							display: flex;
							align-items: center;

							h6 {
								font-weight: 500;
							}
						}
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.account-setting {
				h5 {
					text-transform: capitalize;
				}

				.row {
					> div {
						.form-check {
							margin-bottom: 10px;
							padding-left: 0;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}

				.btn {
					margin-top: 15px;
				}

				+ .account-setting {
					margin-top: 15px;
				}
			}
		}
	}

	.radio_animated {
		position: relative;
		margin: 0 1rem 0 0;
		cursor: pointer;

		&:before {
			transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
			transform: scale(0, 0);
			content: '';
			position: absolute;
			top: 0;
			left: 0.125rem;
			z-index: 1;
			width: 0.75rem;
			height: 0.75rem;
			background: var(--theme-deafult);
			border-radius: 50%;
		}

		&:after {
			content: '';
			position: absolute;
			top: -0.25rem;
			left: -0.125rem;
			width: 1.25rem;
			height: 1.25rem;
			background: $white;
			border: 2px solid #d0edff;
			border-radius: 50%;
		}

		&:checked:before {
			transform: scale(1, 1);
		}
	}
}

.faq-tab {
	.nav-tabs {
		display: block;
		border-bottom: none;
		background-color: white;
		padding: 10px 0;

		.nav-item {
			display: block;
			margin-bottom: 0;

			.nav-link {
				text-transform: capitalize;
				color: black;
				font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
				border: none;
				transition: all 0.5s ease;
				border-radius: 0;
				background-color: $top-header;
				cursor: pointer;

				&.active {
					border: none;
					border-right: 2px solid var(--theme-deafult);
					border-radius: 0;
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}

				&:hover {
					border: none;
					border-right: 2px solid var(--theme-deafult);
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}
			}
		}
	}
}

.modal-dialog {
	margin: auto;
}

// vendor pages
.vendor-cover {
	.bg-size {
		height: 350px;
	}
}

.vendor-profile {
	margin-top: -70px;

	.profile-left {
		display: flex;
		padding: 20px;
		background-color: white;
		box-shadow: 0 0 8px 0 rgba(black, 0.07);

		.profile-image {
			text-align: center;
			width: 20%;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 60%;
				margin: 0 auto;
			}

			h3 {
				font-weight: 700;
				margin-bottom: 5px;
				color: black;
			}

			h6 {
				margin-bottom: 0;
				margin-top: 0;
				letter-spacing: 0.05em;
				text-transform: capitalize;
			}
		}

		.profile-detail {
			border-left: 1px solid #efefef;
			padding-left: 20px;
			margin-left: 20px;
			width: 60%;
			display: flex;
			align-items: center;

			h6 {
				margin-bottom: 0;
				margin-top: 0;
				letter-spacing: 0.05em;
				text-transform: capitalize;
			}

			p {
				margin-bottom: 0;
				font-size: 14px;
				line-height: 1.5;

				+ p {
					margin-top: 10px;
				}
			}
		}

		.vendor-contact {
			border-left: 1px solid #efefef;
			padding-left: 20px;
			margin-left: 20px;
			width: 20%;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;

			.footer-social {
				margin-top: 0;
				margin-bottom: 12px;
				justify-content: center;

				i {
					color: #989898;
				}

				li {
					padding: 0 5px;
				}
			}

			h6 {
				text-transform: capitalize;
				color: black;
				font-weight: 700;
				margin-bottom: 8px;
			}

			a {
				text-transform: capitalize;
			}
		}

		+ .profile-left {
			margin-top: 40px;
		}
	}

	.page-link {
		border-color: #efefef;
		background-color: $grey-lighter;

		&:hover {
			background-color: #f1f1f1;
		}
	}
}

.become-vendor {
	background-color: $grey-about;
	overflow: hidden;

	h4 {
		margin-bottom: 40px;
		text-transform: capitalize;
		font-weight: 600;
		line-height: 1.3;
	}

	.step-bg {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			left: -0;
			right: -0;
			height: 100%;
			display: block;
			background: url('/assets/images/vendor/step-arrow.png') no-repeat center center;
		}

		.row {
			margin-left: -50px;
			margin-right: -50px;

			> div {
				padding-left: 50px;
				padding-right: 50px;

				&:nth-child(1),
				&:nth-child(3) {
					margin-bottom: 30px;
				}

				&:nth-child(2) {
					margin-top: 30px;
				}
			}
		}

		h4 {
			margin-bottom: 10px;
		}

		.step-box {
			position: relative;
			padding: 50px 35px 33px;
			box-shadow: 0 15px 39px 0 rgba(8, 18, 109, 0.08);
			transition: all 0.3s ease;
			background-color: #ffffff;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;

			.steps {
				position: relative;
				width: 80px;
				height: 80px;
				background: $grey-about;
				margin: 0 auto 25px auto;
				color: var(--theme-deafult);
				text-align: center;
				font-weight: 500;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: calc(28px + (40 - 28) * ((100vw - 320px) / (1920 - 320)));
			}

			p {
				line-height: 1.5;
				margin-bottom: 0;
			}
		}
	}
}

.start-selling {
	h4 {
		margin-bottom: 10px;
		text-transform: capitalize;
		font-weight: 600;
		line-height: 1.3;
	}

	p {
		line-height: 2;
		letter-spacing: 0.03em;
	}

	.btn {
		margin-top: 20px;
	}
}

// typography page //
.typography_section {
	.typography-box {
		margin-bottom: 30px;

		p {
			line-height: 22px;
		}

		.headings {
			border-bottom: 1px solid #f1f1f1;
			margin-bottom: 20px;
			background-color: rgba($grey-about, 0.5);
			padding: 8px 12px;

			h3 {
				text-transform: capitalize;
				color: #2d2d2d;
				margin-bottom: 0;
				font-weight: 400;
				font-size: 20px;
			}

			span {
				color: $grey;

				code {
					color: $grey;
				}
			}
		}

		.typo-content {
			&.heading_content {
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					font-weight: 400;
					color: black;
					text-transform: uppercase;
					line-height: 1;
					margin-bottom: 10px;
				}
			}

			.sub-title {
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding-bottom: 5px;
				margin-bottom: 8px;
				font-size: 18px;
				color: $dark-font;
				text-transform: capitalize;
			}

			&.product-pagination {
				border: none;
				margin: 0;

				.pagination {
					.page-item {
						border-top: 1px solid $round-border;
						border-bottom: 1px solid $round-border;

						a {
							padding: 10px 14px;
						}
					}
				}
			}

			&.input_button {
				input {
					margin-bottom: 10px;
				}

				label {
					font-size: 16px;
					text-transform: capitalize;
					padding-left: 5px;
				}
			}

			&.loader-typo {
				width: 100%;
				justify-content: center;
				display: flex;

				.pre-loader {
					position: relative;
					width: 50px;
					height: 50px;
					background-color: var(--theme-deafult);
					border-radius: 50%;

					&:after {
						content: '';
						position: absolute;
						border-radius: 50%;
						top: 50%;
						left: 50%;
						border: 0 solid white;
						transform: translate(-50%, -50%);
						animation: loading 1000ms ease-out forwards infinite;
						transition: all 0.3s ease;
					}
				}
			}

			.footer-social {
				margin-top: 0;
				justify-content: center;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	code {
		background-color: #f7f7f7;
		padding: 3px;
		margin: 0 3px;
		border-radius: 2px;
	}
}

// blog-details //
.blog-detail-page {
	.blog-detail {
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		img {
			margin-bottom: 40px;
		}

		h3 {
			color: $dark-font;
			font-weight: 700;
			margin-bottom: 20px;
			text-transform: uppercase;
		}

		p {
			color: $grey;
			line-height: 1.8;
		}

		.post-social {
			color: $grey;
			text-align: left;
			padding-bottom: 15px;
			border-bottom: 1px solid $round-border;
			margin-bottom: 15px;

			li {
				& + li {
					padding-left: 15px;
					margin-left: 15px;
					border-left: 1px solid $round-border;
				}
			}
		}
	}

	.blog-advance {
		> div {
			&:last-child {
				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

		img,
		.bg-size {
			margin-bottom: 25px;
			margin-top: 25px;
		}

		ul {
			list-style-type: decimal;
			list-style-position: inside;
			line-height: 2.5;
			font-size: 15px;

			li {
				display: list-item;
				font-weight: 700;
			}
		}

		p {
			line-height: 1.8;
		}
	}

	.comment-section {
		border-bottom: 1px solid $round-border;

		li {
			padding-top: 60px;
			padding-bottom: 60px;
			border-top: 1px solid $round-border;

			img {
				height: 65px;
				width: 65px;
				margin-right: 20px;
				border-radius: 100%;
			}

			h6 {
				color: $grey4;
				text-transform: uppercase;
				font-weight: 700;
				margin-bottom: 5px;

				span {
					font-size: 13px;
					font-weight: 500;
					text-transform: capitalize;
					color: $grey;
					margin-left: 20px;
				}
			}

			p {
				line-height: 1.8;
				margin-bottom: 0;
			}
		}
	}

	.blog-contact {
		h2 {
			margin-bottom: 30px;
			margin-top: -5px;
		}

		.theme-form {
			label {
				text-transform: capitalize;
				color: $font-color;
				font-size: 16px;
				font-weight: 700;
			}

			input,
			textarea {
				border-color: $round-border;
				font-size: 14px;
				padding: 17px 25px;
				margin-bottom: 30px;
			}
		}
	}
}

.review-page {
	.comment-section {
		border-bottom: none !important;

		li {
			&:first-child {
				border-top: none;
				padding-top: 0;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	.comnt-sec {
		margin-top: 10px;

		li {
			margin-right: 15px;
			padding-bottom: 0 !important;
			padding-top: 0 !important;
			border-top: none !important;

			a {
				color: #777777;
				font-size: 16px;

				i {
					margin-right: 5px;
				}
			}
		}
	}
}

// sitemap page //
.sitemap_page {
	.title {
		color: var(--theme-deafult);
		text-transform: capitalize;
		font-weight: 700;
	}

	ul {
		padding-left: 10px;

		li {
			display: block;
			margin-bottom: 10px;

			a {
				color: $dark-font;
				font-weight: 700;
				transition: all 0.5s ease;
				text-transform: capitalize;

				i {
					font-size: 10px;
				}

				&:hover {
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}
			}
		}

		ul {
			padding-left: 15px;
			margin-top: 5px;

			li {
				margin-bottom: 0;

				a {
					font-weight: 400;
					color: $font-color;
					font-size: 14px;
				}
			}
		}
	}
}

// coming soon //
.template-password {
	background: url('/assets/images/coming-soon.jpg');
	background-size: cover;
	height: 100vh;

	#container {
		height: 100vh;
		max-width: 600px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;

		> div {
			width: 100%;
		}

		#login {
			width: 100%;
			margin-bottom: 120px;

			h2 {
				color: var(--theme-deafult);
				font-size: 30px;
				font-weight: 400;
			}

			.theme-form {
				h3 {
					color: $header-font;
					font-size: 20px;
				}

				input {
					border-color: $round-border;
					font-size: 12px;
					padding: 17px 25px;
					margin-bottom: 15px;
					height: inherit;
				}

				input {
					&:focus {
						box-shadow: none;
					}
				}
			}
		}

		#powered {
			position: absolute;
			bottom: 5px;
			left: 0;
			right: 0;
		}

		#owner {
			a {
				color: var(--theme-deafult);
			}
		}
	}
}

.flower-bg {
	background-image: url('/assets/images/flower/flower-bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	height: 100%;
	width: 100%;
}

.bg-block {
	background-image: url('/assets/images/flower/bg.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	height: 100%;
	width: 100%;
	padding: 70px 50px 60px 50px;

	.title4 {
		.line {
			margin-bottom: 25px;
		}
	}

	.theme-tab {
		.tab-title {
			margin-bottom: 30px;
		}
	}
}

.game-banner {
	.banner-timer {
		background-image: url(/assets/images/banner-game.jpg);
		background-blend-mode: overlay;
		background-color: rgba($black, 0.4);
		margin-left: 0;
		margin-right: 0;

		.banner-text {
			h2 {
				color: $white;
			}
		}
	}

	.timer {
		background-color: rgba(#2d2a25, 0.4);
	}
}

.basic-effect {
	position: relative;
	overflow: hidden;

	> div {
		transition: all 0.5s ease;
	}

	&:after {
		position: absolute;
		content: '';
		background: linear-gradient(-180deg, var(--gradient1) 0%, var(--gradient2) 99%);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		transition: all 0.5s ease;
	}

	&:hover {
		&:after {
			opacity: 0.7;
			transition: all 0.5s ease;
		}

		> div {
			transform: scale(1.2) rotate(5deg);
			transition: all 0.5s ease;
		}
	}
}

.compare-modal {
	.media {
		img {
			width: 150px;
		}
	}
}

/*=====================
    19.Add to cart popup
==========================*/

.add_to_cart {
	position: fixed;
	background-color: $white;
	z-index: 9999;
	transition: all 0.5s ease;

	::-webkit-scrollbar {
		width: 3px;
		height: 7px;
	}

	::-webkit-scrollbar-track {
		background: $round-border;
	}

	::-webkit-scrollbar-thumb {
		background: $grey5;
	}

	::-webkit-scrollbar-thumb:hover {
		background: $grey2;
	}

	.cart-inner {
		background-color: #ffffff;
		z-index: 9;
		position: relative;
		top: 0;

		.cart_media {
			padding: 0 20px;

			li {
				position: relative;
				padding-bottom: 10px;
				width: 100%;

				.media {
					align-items: center;
					height: 100%;

					img {
						height: 90px;
					}

					.media-body {
						align-self: center;

						h4 {
							color: $font-color;

							span {
								color: $grey;
								font-size: 16px;
							}
						}
					}
				}

				.total {
					border-top: 1px solid #f1f5f4;
					border-bottom: 1px solid #f1f5f4;
					padding-top: 10px;
					padding-bottom: 10px;

					h5 {
						text-transform: capitalize;
						margin-bottom: 0;
						color: #999999;

						span {
							float: right;
						}
					}
				}

				.buttons {
					a {
						font-size: 16px;
						color: $white;
						text-transform: capitalize;
						font-weight: 700;
						transition: all 0.5s ease;

						&:hover {
							color: var(--theme-deafult);
							transition: all 0.5s ease;
						}
					}

					.checkout {
						float: right;
					}
				}
			}

			.close-circle {
				position: absolute;
				top: 0;
				right: 0;

				i {
					color: $grey-light;
					font-size: 16px;
					transition: all 0.5s ease;

					&:hover {
						color: $black;
						transition: all 0.5s ease;
					}
				}
			}
		}

		.cart_top {
			display: flex;
			padding: 20px;
			border-bottom: 1px solid #efefef;
			margin-bottom: 20px;
			align-items: center;

			h3 {
				font-size: 18px;
				color: #222222;
				font-weight: 700;
				text-transform: uppercase;
				margin-bottom: 0;
			}

			.close-cart {
				margin-left: auto;

				i {
					color: black;
					font-size: 18px;
				}
			}
		}
	}

	.overlay {
		visibility: hidden;
		opacity: 0;
		background-color: #212331;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		transition: all 0.5s ease;
	}

	&.open-side {
		transition: all 0.5s ease;

		.overlay {
			opacity: 0.8;
			visibility: visible;
			transition: all 0.5s ease;
		}
	}

	&.left {
		left: -300px;
		top: 0;
		height: 100vh;
		width: 300px;

		.cart-inner {
			height: 100vh;
			overflow: auto;
		}

		&.open-side {
			left: 0;
		}
	}

	&.right {
		right: -300px;
		height: 100vh;
		width: 300px;
		top: 0;

		.cart-inner {
			height: 100vh;
			overflow: auto;
		}

		&.open-side {
			right: 0;
		}
	}

	&.top,
	&.bottom {
		height: 218px;
		width: 100%;

		.cart_media {
			display: flex;
			width: 100%;

			.cart_product {
				display: flex;
				width: 75%;
				overflow-y: auto;

				li {
					min-width: 245px;
					max-width: 225px;
					margin-right: 18px;
					padding-bottom: 0;
				}
			}

			.cart_total {
				width: 25%;
			}
		}

		.cart-inner {
			height: 218px;

			.cart_top {
				margin-bottom: 0;
				background-color: #fbfbfb;
				padding: 15px 44px;
				border-bottom: none;
			}

			.cart_media {
				padding: 0;

				.cart_product,
				.cart_total {
					padding: 30px 44px;
				}
			}
		}
	}

	&.top {
		top: -300px;
		left: 0;

		&.open-side {
			top: 0;
		}
	}

	&.bottom {
		bottom: -300px;
		left: 0;
		&.open-side {
			bottom: 0;
		}
	}
}

/*=====================
    20.Flying Cart CSS start
==========================*/

.addcart_btm_popup {
	position: fixed;
	right: 0;
	width: 42px;
	height: 41px;
	color: #000000;
	transition: all ease-in 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	text-decoration: none;
	background: #ffffff;
	top: 33%;
	border: 1px solid white;
	-webkit-box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
	box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);

	.fixed_cart {
		position: relative;
		z-index: 99;
		display: block;
		float: right;
		font-size: 18px;
		text-decoration: none;
		padding-left: 8px;
		white-space: nowrap;

		i {
			color: black;
		}

		.cart_qty_cls {
			font-weight: 400;
		}
	}
}

.theme-modal {
	.modal-dialog {
		.modal-content {
			.modal-body {
				background-image: linear-gradient(
					135deg,
					var(--theme-deafult) 5.77%,
					$white 5.77%,
					$white 25%,
					$grey01 25%,
					$grey01 30.77%,
					$white 30.77%,
					$white 50%,
					var(--theme-deafult) 50%,
					var(--theme-deafult) 55.77%,
					$white 55.77%,
					$white 75%,
					$grey01 75%,
					$grey01 80.77%,
					$white 80.77%,
					$white 100%
				);
				background-size: 36.77px 36.77px;
				background-color: white;

				.modal-bg {
					background-color: $white;
					padding: 45px;

					.close {
						padding-right: 10px;
						padding-top: 5px;
						position: absolute;
						right: 17px;
						top: 0;

						span {
							color: $black;
						}
					}

					.age-content {
						h2 {
							border-bottom: 1px dashed $grey;
							text-align: center;
							margin-bottom: 30px;
							padding-bottom: 30px;
							font-weight: 700;
						}

						h4 {
							margin-bottom: 30px;
							text-align: center;
							color: #272727;
							font-size: 18px;
							font-weight: 400;
							text-transform: capitalize;
						}

						form {
							margin: 0 auto;
							text-align: center;

							> div {
								display: flex;
								margin-bottom: 10px;
							}

							.form-control {
								padding-top: 15px;
								padding-bottom: 15px;
								text-align: center;
								font-size: 12px;
								height: 50px;
								border-radius: 0;
								width: 100%;
								letter-spacing: 0.05em;
							}
						}
					}

					.offer-content {
						img {
							margin-bottom: 40px;
						}

						h2 {
							margin-bottom: 30px;
							text-align: center;
							color: $dark-font;
						}

						form {
							.form-group {
								margin: 0 auto;
								text-align: center;

								.form-control {
									padding-top: 15px;
									padding-bottom: 15px;
									text-align: center;
									font-size: 12px;
									max-width: 640px;
									left: 0;
									right: 0;
									margin: 0 auto;
									letter-spacing: 0.05em;
								}

								.btn-solid,
								.btn-outline {
									margin-top: 10px;
									text-align: center;
								}
							}
						}
					}
				}
			}
		}

		.quick-view-modal,
		.demo_modal {
			button {
				&.close {
					position: absolute;
					z-index: 1;
					right: 10px;
					top: 5px;
				}
			}

			.view-detail {
				font-size: 14px;
				font-weight: 700;
				text-transform: uppercase;
			}
		}
	}

	&.demo-modal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					height: 75vh;
					overflow-y: scroll;
					padding: 25px;
					background-image: none !important;

					&::-webkit-scrollbar {
						width: 5px;
					}

					/* Track */
					&::-webkit-scrollbar-track {
						background: #f1f1f1;
					}

					/* Handle */
					&::-webkit-scrollbar-thumb {
						background: var(--theme-deafult);
					}

					/* Handle on hover */
					&::-webkit-scrollbar-thumb:hover {
						background: #555;
					}
				}
			}
		}
	}

	&.cart-modal {
		.modal-dialog {
			.modal-content {
				.modal-body {
					.modal-bg {
						padding: 25px;

						&.addtocart {
							.media {
								a {
									img {
										max-width: 150px;
									}
								}

								.media-body {
									padding: 0 15px;

									a {
										h6 {
											font-size: 16px;

											i {
												margin-right: 8px;
												color: #fff;
												background: #19a340;
												font-size: 14px;
												width: 18px;
												height: 18px;
												line-height: 18px;
												border-radius: 50%;
											}
										}
									}

									.buttons {
										a {
											display: inline-block;
											font-size: 16px;
											padding: 6px 10px;
											margin: 0 6px;
										}
									}
								}
							}

							#upsell_product {
								.product-box {
									padding-left: 10px;
									padding-right: 10px;

									.product-detail {
										text-align: center;

										h6 {
											font-weight: 700;
											text-transform: capitalize;

											a {
												color: var(--theme-deafult);
											}
										}
									}
								}
							}
						}
					}

					.upsell_payment {
						padding-top: 15px;
					}

					.product-upsell {
						margin: 20px 0 10px;

						h4 {
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	&.exit-modal {
		.media {
			.media-body {
				h4 {
					font-size: 20px;
					line-height: 1.2;
					color: var(--theme-deafult);
					font-weight: 500;
				}

				h5 {
					font-size: 18px;
					color: $grey;
					margin-bottom: 0;
				}
			}
		}
	}
}

.snow-slider {
	position: relative;

	.slick-slide {
		position: relative;
	}
}

/*=====================
    17.14. Christmas demo CSS start
==========================*/

.product-christmas {
	.product-box {
		position: relative;
		transition: all 0.5s ease;

		.cart-info,
		.cart-wrap {
			position: absolute;
			bottom: -26px;
			text-align: center;
			margin: 0 auto;
			right: 0;
			left: 0;
			justify-content: center;
			opacity: 0;
			transition: all 0.5s ease;
			display: flex;

			a {
				[class^='icon-'],
				[class*=' icon-'] {
					display: inline-block;
				}
			}

			button {
				background: none;
				box-shadow: none;
				border: none;
				padding: 0;
				color: $white;
			}

			i {
				color: $white;
				font-size: 18px;
				padding-right: 10px;
				padding-left: 10px;
				cursor: pointer;
			}
		}

		.cart-wrap {
			bottom: -26px;
			text-align: right;
			left: 0;

			i {
				display: block;
				padding-bottom: 10px;
				padding-top: 10px;
			}

			&.cart-effect-left {
				left: 0;
				right: unset;
			}
		}

		.product-detail,
		.product-info {
			padding-left: 0;
			text-align: center;

			.rating {
				i {
					&:nth-child(-n + 4) {
						color: var(--theme-deafult);
					}

					&:last-child {
						color: #878787;
					}
				}
			}

			h6 {
				line-height: 1;
				margin-bottom: 3px;
				padding-top: 7px;
				padding-bottom: 5px;
				transition: all 0.5s ease;
				font-size: 16px;
				color: $grey4;
				font-weight: 700;
			}

			h4 {
				font-size: 18px;
				color: var(--theme-deafult);
				font-weight: 700;
				margin-bottom: 0;
				transition: all 0.5s ease;
			}
		}

		&:hover {
			.cart-info {
				bottom: 0;
				background-color: var(--theme-deafult);
				opacity: 1;
				transition: all 0.5s ease;

				button {
					animation: none !important;
				}

				a {
					i {
						animation: none !important;
					}
				}
			}

			.product-info {
				.add-btn {
					opacity: 1;
					transition: all 0.2s ease;
					animation: fadeInUp 500ms ease-in-out;
				}
			}

			.img-wrapper {
				.cart-box {
					opacity: 1;
					transition: all 0.2s ease;
					animation: fadeInUp 400ms ease-in-out;
				}
			}
		}
	}
}

.overflow-cls {
	overflow: visible !important;
}

.margin-add {
	margin-top: 60px;
}

.grey-bg {
	background-color: #f5f5f5;
}

.blog-section {
	.blog-details {
		h4 {
			color: var(--theme-deafult);
			margin-top: 15px;
			line-height: 1;
			margin-bottom: 0;
		}

		p {
			color: $dark-font;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 700;
			line-height: 1.2;
			letter-spacing: 0.03em;
			margin-top: 6px;
			margin-bottom: 6px;
			-webkit-transition: all 0.5s ease;
			transition: all 0.5s ease;
		}

		h6 {
			line-height: 1;
			letter-spacing: 0.1em;
			margin-bottom: 0;
		}
	}

	.blog-wrap {
		.blog-image {
			overflow: hidden;

			img {
				transition: all 0.5s ease;
			}
		}

		&:hover {
			.blog-details {
				p {
					color: var(--theme-deafult);
					transition: all 0.5s ease;
				}
			}

			.blog-image {
				img {
					transform: scale(1.2);
					transition: all 0.5s ease;
				}
			}
		}
	}

	.slick-slider {
		.slick-list {
			margin: 0 -15px;

			.slick-slide {
				> div {
					margin: 0 15px;
					position: relative;
				}
			}
		}
	}

	.review-box {
		background-color: $white;
		z-index: 1;
		text-align: center;
		padding: 30px;
		position: relative;

		&:after {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border: 3px solid var(--theme-deafult);
			opacity: 0.5;
			z-index: -1;
		}

		.santa-img {
			position: absolute;
			top: 0;
			right: -116px;
		}

		.slick-prev {
			z-index: 1;
			top: unset;
			bottom: 0;
			left: -30px;
			right: 0;
			margin: 0 auto;

			&:before {
				font: normal normal normal 14px/1 FontAwesome;
				content: '\f053';
				color: #717171;
			}

			&:after {
				position: absolute;
				content: '';
				height: 13px;
				width: 2px;
				background-color: $white-dark;
				left: 24px;
			}
		}

		.slick-next {
			top: unset;
			left: 30px;
			right: 0;
			margin: 0 auto;
			bottom: 0;

			&:before {
				font: normal normal normal 14px/1 FontAwesome;
				content: '\f054';
				color: #717171;
			}
		}

		.slick-prev {
			left: -30px;
			right: 0;
			margin: 0 auto;
		}

		.review-content {
			.avtar {
				border: 1px solid $white-dark;
				padding: 5px;
				border-radius: 100%;
				display: inline-block;
				margin-top: 10px;

				img {
					border-radius: 100%;
					height: 60px;
					width: auto;
					margin: 0 auto;
					border: 1px solid $white-dark;
				}
			}

			h5 {
				margin-bottom: 0;
				text-transform: uppercase;
				font-weight: 700;
				color: var(--theme-deafult);
				margin-top: 15px;
			}

			h6 {
				margin-bottom: 0;
				margin-top: 10px;
				text-transform: capitalize;
				color: $grey2;
				font-size: 16px;
			}

			p {
				margin-top: 20px;
				margin-bottom: 80px;
				line-height: 24px;
			}
		}
	}
}

.instagram {
	position: relative;

	.insta-decor {
		position: absolute;
		top: 0;
		right: 0;
	}
}

.logo-section {
	position: relative;

	.logo-decor {
		position: absolute;
		bottom: 0;
		left: 0;
	}
}

#preloader {
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 9999;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: #fff url('/assets/images/christmas/loading.gif') no-repeat center center;
}

/*=====================
    17.4.Fashion-3 Demo CSS
==========================*/

.box-layout-body {
	background-color: $grey-lighter;
	.box-layout-header {
		background-color: $white;
	}
	.box-layout {
		margin-top: -70px;
		margin-bottom: 5px;
		box-shadow: 0 0 8px 0 #ddd;
		position: relative;
		background-color: white;
		.full-banner {
			margin-left: -15px;
			margin-right: -15px;
			padding-top: 200px;
			padding-bottom: 200px;
		}
		&.bg-image {
			background-image: url('/assets/images/bg-image.jpg');
		}
	}
}

video#background-video {
	position: absolute;
	z-index: 0;
	background: url(/assets/video/2.mp4) no-repeat;
	background-size: 100% 100%;
	top: 0px;
	left: 0px;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
}

.contact-section .info {
	border-top: 3px solid #20963d;
	border-bottom: 3px solid #20963d;
	padding: 30px;
	background: #fff;
	width: 100%;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}

.contact-section .email-form {
	width: 100%;
	border-top: 3px solid #20963d;
	border-bottom: 3px solid #20963d;
	padding: 30px;
	background: #fff;
	box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}

.contact-section .info i {
	font-size: 20px;
	color: #20963d;
	float: left;
	width: 44px;
	height: 44px;
	background: #e7f5fb;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
}

.contact-section .info h4 {
	padding: 0 0 0 60px;
	font-size: 20px;
	font-weight: 600;
	margin-bottom: 25px;
	color: #37517e;
}

.contact-section .info p {
	padding: 0 0 0 60px;
	font-size: 15px;
	margin-bottom: 25px;
	color: #37517e;
}

.contact-section .info .email:hover i,
.contact-section .info .address:hover i,
.contact-section .info .phone:hover i {
	background: #20963d;
	color: #fff;
}

.email-form .form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #20963d;
	outline: 0;
	-webkit-box-shadow: 0 0 0 0.1rem #20963d;
	box-shadow: 0 0 0 0.1rem #20963d;
}

.btn-post {
	border-radius: 5px;
	text-transform: unset !important;
}

.fs-15 {
	font-size: 15px;
}

.membership-modal .modal-role {
	position: absolute;
	top: 110px;
	height: 150px;
	text-align: center;
}

.plan-modal .modal-plan {
	position: absolute;
	top: 80px;
}

.subscription-modal .modal-subscription {
	position: absolute;
	top: 50px;
}

.signup-modal .modal-signup {
	position: absolute;
	top: 80px;
}

.modal-full {
	max-width: 85%;
}

.modalGoldSubscription,
.modalPlatinumSubscription {
	background: #777;
}

.modal-backdrop.show {
}
.modal-subscription-title {
	font-size: 30px;
	margin-top: 10px;
	margin-bottom: 30px;
	color: #6f42c1;
}

.subscriptionModalBody {
	padding-bottom: 50px !important;
}

.subscriptionHeader {
	padding-left: 25px;
	border-bottom: 0;
}
.modalGoldSubscription .link_back,
.modalPlatinumSubscription .link_back {
	font-size: 20px;
	color: #6c757d;
	cursor: pointer;
}

.link_back i {
	padding-right: 10px;
}

.link_back {
	cursor: pointer;
}
.modal-role-title {
	margin-top: 15px;
	font-size: 20px;
}
.modal-role-description {
	margin-top: 25px;
}

.modal-plan-title {
	text-align: center;
	font-size: 20px;
	margin-top: 5px;
	margin-bottom: 30px;
}

.show-grid .row {
	margin: 0 50px 0 50px;
}

.custom-grid .row {
	margin: 0 10px 0 10px;
}

.show-grid .row > .col,
.show-grid .row > [class^='col-'] {
	padding-top: 0.3rem;
	padding-bottom: 0.3rem;
	background-color: #fff;
	border: 1px solid #dee2e6;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
}

.show-reportgrapical .row {
	padding-bottom: 5px;
}

.show-reportgrapical select,
.show-reportgrapical input {
	color: #6c757d;
	border-radius: 4px;
}
.modal-plan-footer .row > .col,
.modal-plan-footer .row > [class^='col-'] {
	border: 0 !important;
}
.modal .header {
	border-bottom: 0px !important;
}

.btn-blue-plan {
	// background-image: linear-gradient(30deg, #007bff 50%, transparent 50%);
	background-color: #007bff;
	border: 2px solid #007bff;
	letter-spacing: 1px;
	&:hover {
		color: #007bff !important;
	}
}

.btn-red-plan {
	// background-image: linear-gradient(30deg, #dc3545 50%, transparent 50%);
	border: 2px solid #dc3545;
}

.btn-green-plan {
	// background-image: linear-gradient(30deg, #20963d 50%, transparent 50%);
	background-color: #20963d;
	border: 2px solid #20963d;
	&:hover {
		color: #20963d !important;
	}
}

.btn-gold-plan {
	// background-image: linear-gradient(30deg, #8e5d34 50%, transparent 80%);
	background-color: #8e5d34;
	border: 2px solid #8e5d34;
}

.btn-platinum-plan {
	// background-image: linear-gradient(30deg, #6c757d 50%, transparent 80%);
	background-color: #6c757d;
	border: 2px solid #6c757d;
}

.btn-default-plan {
	background: #20963d;
	border: 2px solid #20963d;
	letter-spacing: 1px;
	&:hover {
		color: #20963d !important;
	}
}

.btn-default-plan-outline {
	background: #fff;
	border: 2px solid #20963d;
	letter-spacing: 1px;
}

.btn-gray-plan {
	// background-image: linear-gradient(30deg, #87918a 50%, transparent 50%);
	background: #87918a;
	border: 2px solid #87918a;
	letter-spacing: 1px;
	&:hover {
		color: #87918a !important;
	}
}

.btn-signup {
	background-color: #20963d;
	border-color: #20963d;
	box-shadow: unset;
	width: 100%;
	text-transform: unset;
	font-weight: bold;

	i {
		padding-right: 10px;
	}
}

.btn-gray-signup {
	background-color: #021a49;
	border-color: #021a49;
	box-shadow: unset;
	width: 100%;
	text-transform: unset;

	i {
		padding-right: 10px;
	}
}

.btn-purple {
	background-color: #766df4;
	border-color: #766df4;
	box-shadow: unset;
	text-transform: unset;
}

.btn-opacity {
	opacity: 0.5;
}

.btn-blue-border {
	background-position: 100%;
	color: #007bff !important;
	background-color: #ffffff;
}

.btn-default-plan i {
	padding-right: 5px;
}

.btn-gray-plan i {
	padding-right: 5px;
}

// .btn-blue-plan i {
//   color: #007bff;
// }
.btn-gold-plan i {
	color: #8e5d34;
}

.btn-platinum-plan i {
	color: #6c757d;
}

.btn-red-border {
	background-position: 100%;
	color: #dc3545 !important;
	background-color: #ffffff;
}

.btn-red-plan i {
	color: #dc3545;
}

.btn-green-plan i {
	color: #20963d;
}

// .btn-blue-plan i,
.btn-gold-plan i,
.btn-red-plan i,
.btn-green-plan i,
.btn-platinum-plan i {
	font-size: 15px;
	float: left;
	width: 20px;
	height: 20px;
	background: #fff;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-align-items: center;
	align-items: center;
	border-radius: 50px;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	margin-right: 10px;
}

.btn-solid.btn-subscription {
	text-transform: unset;
	font-size: 14px;
}

a.linkTerms {
	color: #333333 !important;
}

.login-page .row > .col,
.login-page .row > [class^='col-'] {
	padding: 0 30% 0 30%;
}

.bg-dark {
	background-color: rgba(55, 56, 78, 1) !important;
}

.signFormHeader h5,
.graphicalHeader h5 {
	color: #ffffff !important;
	font-size: 1.375rem;
}
mb-3 {
	margin-bottom: 1rem !important;
}

a.linkCursor {
	cursor: pointer;
}

.modal-signin a.signLink {
	color: #766df4 !important;
	cursor: pointer;
}

.modal div.show,
.modal label.show {
	display: block;
}

.modal div.hide,
.modal label.hide {
	display: none;
}

.input-group {
	position: relative;
}

.input-group .position-absolute {
	z-index: 5;
}

.input-group .position-absolute + .form-control,
.input-group .position-absolute + .password-toggle .form-control {
	padding-left: 2rem;
}

.top-50 {
	top: 55% !important;
	left: 2%;
}

.translate-middle-y {
	transform: translateY(-50%) !important;
}

.input-group {
	.fa {
		color: #6c757d;
	}

	.fa-lock {
		font-size: 20px;
	}

	.fa-envelope-o {
		font-size: 15px;
	}
}

.form-check {
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5em;
	margin-bottom: 0.125rem;
}

.nav-link-style {
	display: inline-block;
	transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, box-shadow 0.25s ease-in-out,
		border-color 0.25s ease-in-out;
	color: #5a5b75;
	font-weight: 500;
	text-decoration: none;
}

.form-check-label {
	color: #737491;
}

div#graph .lineGraph,
div#graph .pieGraph {
	margin-left: 30px;
}

.floatLeft {
	float: left;
}

.floatRight {
	float: right;
}

.mb-4-5 {
	margin-bottom: 2.1rem !important;
}
.mt-4-5 {
	margin-top: 2.1rem !important;
}

.subcategory-banner-info {
	top: 30px !important;
	width: 95%;
	.mb-8 {
		margin-bottom: 8rem !important;
	}

	ul li label {
		color: #ffffff;
		font-size: 16px;
		border: 1px solid #17a2b8;
		padding: 5px 10px 5px 10px;
		background-color: #17a2b8;
		opacity: 0.9;
	}
}

.inputFrom,
.inputTo {
	width: 30%;
}

.graphical-filter {
	width: 40%;
	.form-check {
		padding-left: 0;
	}
}

.map-filter {
	width: 30%;
	margin-left: 30px;
	.form-check {
		padding-left: 0;
	}
}

.map-filters {
	width: 80%;
	margin-left: 30px;
	.form-check {
		padding-left: 0;
	}
}

.subproduce .thumbnail {
	width: 400px;
	height: 400px;
	overflow: hidden;
}

.produce .thumbnail {
	width: 280px;
	height: 280px;
	overflow: hidden;
}

.thumbnail img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.modal .modal-header .modal-title {
	width: 100%;
	text-align: center;
	font-size: 20px;
	color: black;
}

.modal-header .close {
	padding: 0 !important;
	margin: 0 !important;
}

@media (max-width: 577px) {
	.top-header .header-dropdown li:first-child {
		padding-right: 50px;
	}
}

.Select.is-focused:not(.is-open) > .Select-control {
	border-color: #dc3545 !important;
	box-shadow: unset !important;
	background: #fff;
}

.modalBuyerPlan .table th,
.modalBuyerPlan .table td,
.modalSellerPlan .table th,
.modalSellerPlan .table td {
	padding: 0.45rem !important;
	text-align: center;
}

.plan-modal {
	.plan-heading {
		text-align: left;
	}

	.plan-name {
		font-weight: 600;
		font-size: 1.5rem;
	}

	.plan-price {
		line-height: 1.1;
		font-weight: 600;
		font-size: 2.5rem;
	}
}

.membership-column {
	width: 16%;
	text-align: center;
}

.produce-sidebar-button {
	margin-bottom: '1rem';
	font-size: 20px;
	background-color: white;
	background-repeat: no-repeat;
	cursor: pointer;
	overflow: hidden;
	outline: none;
	border-top: 1px solid gray;
	border-left: 1px solid gray;
	border-right: 1px solid gray;
	width: 85%;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
}

div.flatpickr > input {
	background-color: white !important;
}

// div.flatpickr-calendar.animate.open {
//   top: 260px !important;
// }

button.icon-btn-primary {
	// background-color: #0072bb;
	border-radius: 3px;
	// color: white;
	border: 1px solid #ccc;
	padding: 3px 8px 3px 8px;
}

button.icon-btn-primary:hover {
	background-color: #0072bb;
	border-radius: 3px;
	color: white;
	border: 1px solid #141111;
	padding: 3px 8px 3px 8px;
}

button.icon-btn-danger {
	// background-color: #cc2121;
	border-radius: 3px;
	// color: white;
	border: 1px solid #ccc;
	padding: 3px 8px 3px 8px;
}

button.icon-btn-danger:hover {
	background-color: #cc2121;
	border-radius: 3px;
	color: white;
	border: 1px solid #141111;
	padding: 3px 8px 3px 8px;
}

.user-avatar {
	width: 40px;
	height: 40px;
	border-radius: 20px;
}

@media only screen and (min-width: 600px) {
	.text-area {
		padding-left: 45px;
	}
}

.report-select-category {
	float: right;
	margin-right: 40px;
	font-family: fontAwesome;
	min-width: 20%;

	select {
		font-size: 22px;
		cursor: pointer;
	}
}

.produce-select-category {
	float: right;
	margin-right: 40px;
	font-family: fontAwesome;
	min-width: 80%;

	select {
		font-size: 22px;
		cursor: pointer;
	}
}

@media only screen and (max-width: 600px) {
	.report-select-category,
	.produce-select-category {
		font-family: fontAwesome;
		width: 60%;
		margin-right: 20px;

		select {
			font-size: 16px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.search-overlay > div .overlay-content {
		width: 95%;
		position: fixed;
		top: 30px;
	}
}

hr.suggestion-divider {
	border: 0;
	height: 1px;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.media-share-dropdown {
	background-color: Transparent;
	background-repeat: no-repeat;
	border: none;
	color: #20963d;
	margin-top: 0px !important;
}

.media-sharing.dropdown-menu {
	padding: 0px 0px !important;
	border: none !important;
	background-color: Transparent;
}
